export const state = () => ({
    isPlaying: '',
});

export const mutations = {
    setIsPlaying(state, payload) {
        state.isPlaying = payload;
    },
};

export const actions = {
};

export const getters = {
    isPlaying: state => state.isPlaying,
};
