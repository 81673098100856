
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'SearchResults',
    computed: {
        ...mapGetters({
            searchLoading: 'search/loading',
            searchOffset: 'search/offset',
            searchPage: 'search/pageSize',
            searchTotal: 'search/total',
            searchTerm: 'search/searchTerm',
            products: 'search/products',
            results: 'search/results',
        }),
    },
    watch: {
        results(to, from) {
            if (this.$refs['search-results'] && to.length > 0) {
                this.$refs['search-results'].querySelector(':first-child')?.focus();
            }
        },
    },
    methods: {
        ...mapActions({
            search: 'search/submit',
        }),
        ...mapMutations({
            commitOffset: 'search/setOffset',
        }),
        setOffset(value) {
            this.commitOffset(value);
            this.$store.dispatch('search/submit');
        },
    },
};
