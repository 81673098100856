
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'DateRange',
    directives: {
        clickOutside,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Array,
            required: false,
            default: () => ([
                new Date(),
                new Date((new Date() + 60*60*24*1000)),
            ]),
        },
    },
    data: () => ({
        open: false,
    }),
    computed: {
        selectedDates: {
            get() {
                return this.value[0];
            },
            set(value) {
                const today = new Date();

                if (value) {
                    if (this.$util.isSameDay(value, today)) {
                        value.setHours(today.getHours(), today.getMinutes(), today.getSeconds(), 0);
                    } else {
                        value.setHours(0, 0, 0, 0);
                    }
                    this.$emit('input', [
                        new Date(value),
                        new Date(value.setHours(23, 59, 59, 999)),
                    ]);
                    this.toggleOpen();
                }
            },
        },
    },
    methods: {
        handleClickOutside() {
            if (this.open) {
                this.open = !this.open;
            };
        },
        toggleOpen() {
            if (this.disabled) {
                this.open = false;
            } else {
                this.open = !this.open;
            }
        },
    },
};
