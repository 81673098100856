
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    props: [],
    data() {
        return {
            defaultPersonsAmount: {
                numberOfAdults: 0,
                numberOfChildrenOlder: 0,
                numberOfChildrenYounger: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            persons: 'product/getPersons',
            selectedFilters: 'category/getSelectedFilters',
            getFilterBySelected: 'category/getFilterBySelected',
            getOptionBySelected: 'category/getOptionBySelected',
            getPriceFilterTag: 'category/getPriceFilterTag',
            categoryHasPersons: 'category/categoryHasPersons',
        }),
        getPersonTotal() {
            const selectedPersonsTotal = this.persons.numberOfAdults + this.persons?.numberOfChildrenOlder + this.persons?.numberOfChildrenYounger;

            return selectedPersonsTotal;
        },
        layoutClass() {
            if (this.product.item_layout && this.product.item_layout.product === 'product') {
                return 'category-product page-grid without-margin grid-full-width';
            }

            return 'page-grid without-margin grid-full-width';
        },
        hasFilters() {
            return (this.selectedFilters && this.selectedFilters.length) || this.getPriceFilterTag;
        },
    },
    methods: {
        ...mapMutations({
            resetPriceFilters: 'category/resetPriceFilters',
            setPersons: 'product/setPersons',
        }),
        ...mapActions({
            resetFilters: 'category/resetFilters',
            toggleFilters: 'category/toggleFilters',
        }),
        resetPersonsAmount() {

            this.setPersons(this.defaultPersonsAmount);
        },

        removeFilter(key) {
            const filter = this.getFilterBySelected(key);
            const option = this.getOptionBySelected(key);
            if (filter && option) {
                this.toggleFilters({ filter, option });
            }
        },
        removeAllFilters() {
            this.resetFilters();
            this.setPersons(this.defaultPersonsAmount);
            if (this.getPriceFilterTag) {
                this.resetPriceFilters();
            }
        },
    },
};
