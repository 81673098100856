
import { mapGetters } from 'vuex';

export default {
    name: 'ProductIllustration',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
        isAccommodation() {
            return this.product.classification === 'Accommodation';
        },
        imageClass() {
            return `images-${this.product.images.length}`;
        },
        images() {
            const { images, marketing_name: name } = this.product;
            const imageUrlFormat = ({ url }, key) => ({
                index: key,
                name,
                url,
            });

            return images
                .filter(item => item)
                .map(imageUrlFormat);
        },
        tags() {
            return ['product_label1', 'product_label2', 'product_label3'].map(v => ({
                title: this.product[v],
            })).filter(v => v.title !== null);
        },
    },
};
