
import { mapGetters } from 'vuex';

export default {
    name: 'ProductDetails',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
        productText() {
            let productText = this.product?.product_description_long;

            // target _blank on external links
            const links = [...productText.matchAll(/<a.*?<\/a>/gim)];
            links.forEach(link => {
                if (link[0].match(/href=\"https:\/\/vierumaki.fi/) === null) {
                    productText = productText.replace(link[0], link[0].replace('<a ', '<a target="_blank" '));
                }
            });

            return productText;
        },
        amenities() {
            const results = [];
            const icons = {
                room_type_labels: 'bed',
                amenity_labels: 'screen',
                bathroom_labels: 'bathroom',
                kitchen_labels: 'kitchen',
            };

            ['room_type_labels', 'amenity_labels', 'bathroom_labels', 'kitchen_labels'].forEach(v => {
                if (this.product?.[v]) {
                    results.push({
                        icon: icons[v],
                        title: this.$t(`productDetails.${v}`),
                        values: this.product?.[v],
                    });
                }
            });

            return results;
        },
    },
};
