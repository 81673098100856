export const state = () => ({
    product: {},
    buffer: {},
    availability: [],
    persons: {
        numberOfAdults: 1,
        numberOfChildrenOlder: 0,
        numberOfChildrenYounger: 0,
        // pets: false,
    },
    filter: {},
    fromDate: null,
    toDate: null,
    availabilityLoading: false,
    previousClassification: null,
    previousSchema: null,
    activities: null,
});

export const mutations = {
    present(state) {
        if (Object.keys(state.buffer).length) {
            state.product = state.buffer;
            this.commit('product/setBuffer', {});
        }
    },
    setDates(state, dates) {
        state.fromDate = dates.start;
        state.toDate = dates.end;
    },
    setPersons(state, persons) {
        state.persons = persons;
    },
    setFilter(state, payload) {
        // wallhax
        const filters = state.filter;
        state.filter = {};

        switch (payload.type) {
            case 'checkbox':
                payload.value.forEach(key => {
                    if (filters[payload.name].includes(key)) {
                        state.filter = filters;
                        state.filter[payload.name].splice(state.filter[payload.name].indexOf(key), 1);
                    } else {
                        state.filter = Object.assign(filters, {
                            [payload.name]: filters[payload.name].push(key),
                        });
                    }
                });
                break;

            default:
                state.filter = Object.assign(filters, {
                    [payload.name]: payload.value,
                });
                break;
        }
    },
    setBuffer(state, payload) {
        if (typeof (payload) === 'object' && payload.attributes) {
            // If previous product was category, set classification
            // before buffering current product
            state.previousSchema = state.product.schema;
            state.previousClassification = state.product.classification;

            let images = [];
            const imageUrls = [];
            imageUrls.push(payload.mainImage);

            let i = 1;
            while (!!payload.attributes?.[`secondary_image${i}`]) {
                imageUrls[i] = payload.attributes?.[`secondary_image${i}`],
                i++;
            }

            imageUrls.forEach(v => {
                images.push(this.$util.parseImage(v));
            });
            images = images.filter(v => v !== null);


            for (let i = 0; i <= imageUrls.length; i++) {
                if (payload.attributes?.[`secondary_image${i}`]) {
                    delete payload.attributes?.[`secondary_image${i}`];
                }
            }

            state.filters = {};

            if (payload.type === 'product' || payload.schema === 'Active hours') {
                if (payload.schema === 'Active hours') {
                    payload.filters = [
                        ...Object.values(payload.filters.primary),
                        ...Object.values(payload.filters.secondary),
                    ];
                }

                this.commit('product/resetFilters', payload.filters);
            }

            state.buffer = Object.assign(
                {
                    classification: payload.classification,
                    type: payload.type,
                    code: payload.code,
                    filters: payload.filters,
                },
                payload.attributes,
                { images },
            );
        } else {
            state.buffer = {};
        }
    },
    resetFilters(state, filters) {
        if (Object.keys(filters).length > 0) {
            filters.forEach(filter => {
                switch (filter.type) {
                    case 'amount':
                        this.commit('product/setFilter', {
                            name: filter.name,
                            value: 1,
                        });
                        break;

                    case 'checkbox':
                        this.commit('product/setFilter', {
                            name: filter.name,
                            value: [],
                        });
                        break;

                    default:
                        this.commit('product/setFilter', {
                            name: filter.name,
                            value: '',
                        });
                        break;
                }
            });
        }
    },
    setAvailabilityLoading(state, payload) {
        state.availabilityLoading = payload;
    },
    clearAvailability(state) {
        state.availability = [];
    },
    setAvailability(state, payload) {
        if (typeof (payload) === 'object') {
            Object.keys(payload).forEach(key => {
                payload[key].id = this.$util.uuidv4();
            });
            state.availability = payload;
        }
    },
    setLoading(state, payload) {
        state.availabilityLoading = payload;
    },
    setActivities(state, payload) {
        state.activities = payload;
    },
};

export const actions = {
    async loadActivities(ctx) {
        const response = await this.$axios.request({
            method: 'post',
            url: this.$paths.getEndpoint('articles'),
            params: {
                locale: this.$i18n.locale,
            },
            data: {
                categoryCode: 'AKTIVITEETTIKAUPPA',
            },
        });
        this.commit('product/setActivities', response.data);
    },
    async loadAvailability(ctx) {
        const { classification, schema } = ctx.getters.getProduct;

        if (ctx.state.availabilityLoading
            || ['giftcard', 'Education'].includes(classification)
        ) {
            return false;
        }

        this.commit('product/setAvailabilityLoading', true);
        this.commit('product/setAvailability', []);
        this.commit('category/setSelectingFilters', false);
        try {
            const productCodesInCart = [];

            if (this.getters['cart/getProducts']) {
                this.getters['cart/getProducts'].forEach(product => {
                    productCodesInCart.push(product.productCode);
                });
            };

            if (ctx.state.product.type === 'product') {
                this.commit('product/setLoading', true);

                const data = {
                    productCode: ctx.state.product.code,
                    productCodesInCart,
                };

                if (ctx.rootGetters['cart/getDiscountSubmitted']) {
                    data.promotionCode = ctx.rootGetters['cart/getDiscountCode'];
                }

                switch (classification) {
                    case 'Active_hours':
                    case 'Activity':
                        let currentFilters = Object(ctx.state.filter);
                        const productFilters = ctx.state.product.filters.map(v => v.name);

                        Object.keys(currentFilters).forEach((v, k) => {
                            if (productFilters.indexOf(v) === -1) {
                                delete currentFilters[v];
                            }
                        });

                        if (schema === 'Active hours') {
                            currentFilters = Object.assign(currentFilters, {
                                ...ctx.state.persons,
                            });
                        }

                        data.filter = {
                            dates: [
                                ctx.state.fromDate.toISO(),
                            ],
                            ...currentFilters,
                        };
                        break;

                    case 'Accommodation':
                    case 'Course':
                        data.filter = {
                            dates: [
                                ctx.state.fromDate.toFormat('yyyy-MM-dd'),
                                ctx.state.toDate.toFormat('yyyy-MM-dd'),
                            ],
                            ...ctx.state.persons,
                            // pets: ctx.state.persons.pets,
                        };
                        break;
                };

                const response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-view/v2/product/availability',
                    data,
                });

                this.commit('product/setAvailability', response.data);
                this.commit('product/setLoading', false);
            }

            if (ctx.state.product?.type === 'category') {
                // Init filters in category store
                if (ctx.state.product.schema !== 'Active hours') {
                    this.commit('category/initFilters', ctx.state.product?.filters);
                } else {
                    this.commit('product/setPersons', {
                        numberOfAdults: ctx.state.filter.amount,
                        numberOfChildrenOlder: 0,
                        numberOfChildrenYounger: 0,
                    });
                }

                const data = {
                    categoryCode: ctx.state.product.code,
                    filter: {
                        dates: [
                            ctx.state.fromDate?.toFormat('yyyy-MM-dd'),
                            ctx.state.toDate?.toFormat('yyyy-MM-dd'),
                        ],
                        ...ctx.state.persons,
                        ...ctx.state.filter,
                    },
                };

                if (ctx.rootGetters['cart/getDiscountSubmitted']) {
                    data.promotionCode = ctx.rootGetters['cart/getDiscountCode'];
                }

                const timeout = 60000;
                const response = await this.$axios.request({
                    method: 'post',
                    url: this.$paths.getEndpoint('articles'),
                    timeout,
                    params: {
                        locale: this.$i18n.locale,
                    },
                    data,
                });

                if (schema === 'Active hours') {
                    ctx.commit('setAvailability', response.data);
                } else {
                    this.commit('category/setCategoryItems', response.data);
                }
                this.commit('category/setSelectingFilters', false);
            }

        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e.response);
            }
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('product.message.availabilityFailed'),
            });
        }
        this.commit('product/setAvailabilityLoading', false);
    },
};

export const getters = {
    getProduct: state => state.product,
    getFilter: state => state.filter,
    getProductImage: state => key => state.product[key]?.conversions[1]?.url,
    getAvailability: state => state.availability,
    getFromDate: state => state.fromDate,
    getToDate: state => state.toDate,
    getPersons: state => state.persons,
    getAvailabilityLoading: state => state.availabilityLoading,
    getPersonCount: state => {
        let count = 0;
        Object.keys(state.persons).forEach(key => {
            if (key !== 'pets') {
                count = count + state.persons[key];
            }
        });

        return count;
    },
    getPreviousSchema: state => state.previousSchema,
    getPreviousClassification: state => state.previousClassification,
    getActivities: state => state.activities,
};
