import { DateTime } from 'luxon';

const util = require('util');

export const state = () => ({
    language: false,
    navOne: [],
    navTwo: [],
    footer: [],
    activePage: null,
    navTwoActive: [],
    searchActive: false,
    activePortal: 'mega-menu-placeholder',
    loginOpen: false,
    cartOpen: false,
    menuOpen: false,
    localizedPagePaths: [],
    errorPage: false,
    cookieConsent: false,
    alert: null,
    dismissedAlerts: [],
    previousLocale: null,
    ready: false,
});

export const mutations = {
    setPayload (state, payload) {
        Object.keys(payload).forEach(key => {
            if (typeof(state[key]) === typeof(payload[key])) {
                if (typeof(payload[key]) === 'object') {
                    state[key] = Object.assign(state[key], payload[key]);
                } else {
                    state[key] = payload[key];
                }
            } else {
                throw new Error('Type error in navigation/setPayload');
            }
        });
    },
    toggleLanguage(state) {
        state.loginOpen = false;
        state.language = !state.language;
    },
    toggleMenuOpen(state) {
        state.menuOpen = !state.menuOpen;
    },
    toggleLoginOpen(state) {
        state.language = false;
        state.loginOpen = !state.loginOpen;
    },
    toggleCartOpen(state, value) {
        state.cartOpen = !state.cartOpen;
    },
    setLocalizedPaths(state, paths) {
        state.localizedPagePaths = paths;
    },
    setActivePage(state, payload) {
        state.activePage = payload;
    },
    setNav(state, payload) {
        const { 0: navs } = payload;

        if (navs.primaryLinks) state.navOne = navs.primaryLinks;
        if (navs.secondaryLinks) state.navTwo = navs.secondaryLinks;
    },
    setFooter(state, payload) {
        state.footer = payload;
    },
    setNavTwoActive(state, payload) {
        if (payload === null || (typeof (payload) === 'object' && payload.length === 0)) {
            state.navTwoActive = [];
        } else {
            state.navTwoActive = payload;
        }
    },
    setActivePortal(state, id) {
        state.activePortal = id;
    },
    setErrorPage(state, value) {
        state.errorPage = value;
    },
    setCookieConsentActive(state, value) {
        if (value) {
            state.cookieConsent = value;
        } else {
            state.cookieConsent = !state.cookieConsent;
        }
    },
    assignDismissedAlerts(state, payload) {
        state.dismissedAlerts = payload;
    },
    setAlert(state, payload) {
        if (payload[0]?.id) {
            const { 0: alert } = payload;
            state.alert = alert;
        }
    },
    dismissAlert(state) {
        const alerts = this.$storage.getUniversal('dismissedAlerts');
        if (alerts) {
            const dismissedAlerts = alerts.map(alert => alert.id);
            if (!dismissedAlerts.includes(state.alert.id)) {
                alerts.push({ id: state.alert?.id, ts: DateTime.now().toMillis() });
            }
            this.$storage.setUniversal('dismissedAlerts', alerts);
            state.dismissedAlerts.push(state.alert.id);
        } else {
            this.$storage.setUniversal('dismissedAlerts', [{ id: state.alert.id, ts: DateTime.now().toMillis() }]);
            state.dismissedAlerts.push(state.alert.id);
        }
    },
    setPreviousLocale(state, value) {
        state.previousLocale = value;
    },
};

export const actions = {
    async load(ctx, route) {
        const { previousLocale } = ctx.state;
        const { locale } = this.app.i18n;

        if (ctx.state.navOne.length && previousLocale && previousLocale === locale) {
            return;
        }

        try {
            const params = {
                locale,
            };

            if (route.path.match(/^\/preview/)) {
                params.preview = 1;
            }

            let navOneResponse;

            const { data } = await this.$axios.request({
                method: 'get',
                url: this.$paths.getEndpoint('layout'),
                params,
            });

            this.commit('navigation/setNav', this.app.$contentParser.parseContent([{
                contentType: 'header',
                fields: {
                    primaryLinks: data?.fields?.primaryLinks ?? [],
                    secondaryLinks: data?.fields?.secondaryLinks ?? [],
                },
            }]));
            this.commit('navigation/setFooter', this.app.$contentParser.parseContent([{
                contentType: 'footer',
                fields: {
                    column1: data?.fields?.column1,
                    column2: data?.fields?.column2,
                    column3: data?.fields?.column3,
                    column4: data?.fields?.column4,
                    copyright: data?.fields?.copyright,
                    legalLinks: data?.fields?.legalLinks,
                },
            }]));
            if (data?.fields?.alert) {
                this.commit('navigation/setAlert', this.app.$contentParser.parseContent([data?.fields?.alert]));
            }
            this.commit('navigation/setPreviousLocale', locale);
        } catch (e) {
            console.error(e);
            console.error(e.response?.data);
        }
    },
};

export const getters = {
    isReady: state => state.ready,
    getLanguageState: state => state.language,
    getNavOne: state => state.navOne,
    getNavTwo: state => state.navTwo,
    getFooter: state => state.footer[0],
    getActivePage: state => state.activePage,
    getNavTwoActive: state => state.navTwoActive,
    getSearchActive: state => state.searchActive,
    getActivePortal: state => state.activePortal,
    getSearchOpen: state => state.searchOpen,
    getLoginOpen: state => state.loginOpen,
    getSearchPanelOpen: state => state.searchPanelOpen,
    getSearchResults: state => state.searchResults,
    getLocalizedPaths: state => state.localizedPagePaths,
    getCartOpen: state => state.cartOpen,
    getMenuOpen: state => state.menuOpen,
    getErrorPage: state => state.errorPage,
    getCookieConsent: state => state.cookieConsent,
    getAlert: state => state.alert,
    getShowAlert: state => state.alert && state.ready && !state.dismissedAlerts?.includes(state.alert?.id),
};
