
export default {
    name: 'CartDetails',
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        size: {
            type: String,
            required: false,
            default: 'small',
        },
    },
    computed: {
        data() {
            const data = [];
            const { product } = this;

            switch (product.type) {
                case 'Accommodation':
                case 'Course':
                case 'variation':
                    if (product.from && product.to) {
                        data.push({
                            label: this.$util.isSameDay(product.from, product.to) ? this.$t('cart.text.day') : this.$t('cart.text.days'),
                            value: this.$util.getDateString(product.from, product.to),
                        });
                    }
                    if (this.product.persons && typeof(this.product.persons) === 'object') {
                        data.push({
                            label: this.$t('cart.text.persons'),
                            value: this.$util.personsToCount(product.persons),
                        });
                    } else if (this.product.persons && typeof(this.product.persons) === 'number') {
                        data.push({
                            label: this.$t('cart.text.persons'),
                            value: product.persons,
                        });
                    }
                break;

                case 'Active_hours':
                case 'active-hours-product':
                case 'Activity':
                    if (product.from && product.to) {
                        data.push({
                            label: this.$t('cart.text.day'),
                            value: this.$util.getDateString(product.from, product.to),
                        });
                        data.push({
                            label: this.$t('cart.text.time'),
                            value: this.$util.formatTime(product.from),
                        });
                        data.push({
                            label: this.$t('cart.text.duration'),
                            value: this.$util.duration(product.from, product.to),
                        });
                    }
                    if (product.location) {
                        data.push({
                            label: this.$t('cart.text.location'),
                            value: product.location,
                        });
                    }
                break;

                case 'giftcard':
                case 'gift_card_open_value':
                    if (product.from && product.to) {
                        data.push({
                            label: this.$t('cart.text.effect'),
                            value: this.$util.getDateString(product.from, product.to),
                        });
                    }
                break;
            }

            return data;
        },
    },
};
