function getPrice(item) {
    if (item?.erpStartingPrice) {
        return item?.erpStartingPrice;
    } else if (item?.attributes?.price) {
        return item.attributes.price;
    }

    return 0;
}

export const state = () => ({
    categoryItems: [],
    categoryFilters: [],
    availabilityFilters: [],
    priceInitMax: null,
    priceInitMin: null,
    priceFilterMax: null,
    priceFilterMin: null,
    selectingFilters: false,
});

export const mutations = {
    initFilters(state, payload) {
        if (typeof (payload) === 'object') {
            // Add primary and secondary filters to filterlist
            const primaryFilters = payload?.primary.map(item => ({
                ...item,
                primary: true,
                secondary: false,
            }));
            const secondaryFilters = payload?.secondary.map(item => ({
                ...item,
                primary: false,
                secondary: true,
            }));

            let filters = [...primaryFilters, ...secondaryFilters];
            // Show only choice filters with options and price filter
            filters = filters.filter(item => (item.dataType === 'choice' && item.options.length) || item.name === 'price');
            const amenities = state.categoryFilters.find(filter => filter.name === 'equipment')?.selectedOptions ?? [];
            state.categoryFilters = filters.map(item => ({
                ...item,
                selectedOptions: item.name === 'equipment' ? amenities : [],
            }));
        }
    },
    setAvailabilityFilters(state, payload) {
        if (Array.isArray(payload)) {
            state.availabilityFilters = payload.map(item => ({
                title: item?.fields?.title,
                filter: item?.fields?.filter,
            }));
        }
        ;
    },
    setCategoryFilters(state, payload) {
        if (Array.isArray(payload)) {
            state.categoryFilters = payload;
        }
    },
    setCategoryItems(state, payload) {
        if (Array.isArray(payload)) {
            state.categoryItems = payload;
            state.categoryFilters = state.categoryFilters.map(item => ({
                ...item,
                previousSelectedOptions: state.categoryFilters[0]?.selectedOptions ?? [],
            }));

            // Initialize price filters according to categoryProduct prices
            if (state.categoryFilters.find(item => item.name === 'price' && item.dataType === 'number')) {
                if (!state.categoryItems.length) {
                    return;
                }
                let max = getPrice(state.categoryItems[0]);
                let min = getPrice(state.categoryItems[0]);
                for (const item of state.categoryItems) {
                    if (getPrice(item) < min) {
                        min = getPrice(item);
                    }
                    if (getPrice(item) > max) {
                        max = getPrice(item);
                    }
                }

                state.priceInitMax = max;
                state.priceInitMin = min;
                state.priceFilterMax = max;
                state.priceFilterMin = min;
            }
        }
    },
    setMinFilter(state, payload) {
        if (!isNaN(Number(payload))) {
            state.priceFilterMin = Number(payload);
        }
    },
    setMaxFilter(state, payload) {
        if (!isNaN(Number(payload))) {
            state.priceFilterMax = Number(payload);
        }
    },
    setPriceFilters(state, { min, max }) {
        if (!isNaN(Number(min))) {
            state.priceFilterMin = Number(min);
        }
        if (!isNaN(Number(max))) {
            state.priceFilterMax = Number(max);
        }
    },
    setSelectingFilters(state, payload) {
        state.selectingFilters = payload;
    },
    resetPriceFilters(state) {
        state.priceFilterMax = state.priceInitMax;
        state.priceFilterMin = state.priceInitMin;
    },
};

export const actions = {
    toggleFilters({ commit, state }, { filter, option }) {
        const filters = [...state.categoryFilters];
        const found = filters.find(item => item.name === filter.name);
        const selected = { ...found };
        const index = filters.findIndex(item => item.name === filter.name);
        if (filter && index || (index === 0)) {
            if (filter.dataType === 'choice') {
                if (filter.selectedOptions?.includes(option.key)) {
                    selected.selectedOptions = filter.selectedOptions.filter(item => item !== option.key);
                    filters[index] = selected;
                    this.commit('category/setCategoryFilters', filters);
                } else {
                    selected.selectedOptions = [...filter.selectedOptions, option.key];
                    filters[index] = selected;
                    this.commit('category/setCategoryFilters', filters);
                }
            } else if (!filter.selectedOptions.includes(option.key)) {
                selected.selectedOptions = [...selected.selectedOptions, option.key];
                filters[index] = selected;
                this.commit('category/setCategoryFilters', filters);
            }
        }
        this.commit('category/setSelectingFilters', false);
    },
    resetFilters({ state }) {
        this.commit('category/setCategoryFilters', state.categoryFilters.map(item => ({
            ...item,
            selectedOptions: [],
        })));
    },
};

export const getters = {
    categoryHasDateSelect: state =>
        (state.availabilityFilters.filter(item => item?.filter === 'arrival').length > 0
            && state.availabilityFilters.filter(item => item?.filter === 'departure').length > 0),
    categoryHasPersons: state => !!(state.availabilityFilters.filter(item => item?.filter === 'persons').length),
    getCategoryItems: state => state.categoryItems,
    getCategoryFilters: state => state.categoryFilters,
    getSelectedFilters: state => state.categoryFilters?.map(item => item.selectedOptions && item.selectedOptions.length
        ? item.selectedOptions.map(option => ({ filterName: item?.name, optionKey: option }))
        : []).flat(),

    getSelectedEquipmentsFilter: state => state.categoryFilters?.map(item => item.previousSelectedOptions && item.previousSelectedOptions.length
        ? item.previousSelectedOptions.map(option => ({ filterName: 'equipment', optionKey: option, type: item.name }))
        : []).flat(),
    getOptionBySelected(state) {
        return selected => state.categoryFilters.find(item => item.name === selected.filterName)?.options?.find(option => option.key === selected.optionKey);
    },
    getFilterBySelected(state) {
        return selected => state.categoryFilters.find(item => item.name === selected.filterName);
    },
    getFilteredCategoryItems(state, getters) {
        // Get filterName from product.attributes and then filterName.optionKey, if it has one. Return true if optionKey is found
        function checkProduct(product, filter) {
            if ('attributes' in product.product && product.product.attributes) {
                if (filter.filterName in product.product.attributes && product.product.attributes[filter.filterName]) {
                    const filterAttr = product.product.attributes[filter.filterName];
                    if (filter.optionKey in filterAttr && filterAttr[filter.optionKey]) {
                        return true;
                    }
                }
            }

            return false;
        }

        let items = Object.freeze(state.categoryItems);

        // Apply price filters
        if (state.categoryFilters.find(item => item.name === 'price' && item.dataType === 'number')) {
            if (state.priceFilterMin >= 0) {
                items = items.filter(item => getPrice(item) >= parseInt(state.priceFilterMin));
            }

            if (state.priceFilterMax >= 0) {
                items = items.filter(item => getPrice(item) <= parseInt(state.priceFilterMax));
            }
        }

        if (items[0]?.product?.classification === 'Accommodation') {

            return items.filter(function (product) {
                // If AND filter, use .every, if OR filter, use .find
                return !!getters.getSelectedEquipmentsFilter.every(filter => checkProduct(product, filter));
            });
        }


            // Filter by selected filters
            return items.filter(function (product) {
                return !!getters.getSelectedFilters.every(filter => checkProduct(product, filter));
            });


    },
    getPrimaryFilters: state => state.categoryFilters.filter(item => item.primary),
    getSecondaryFilters: state => state.categoryFilters.filter(item => item.secondary),
    getAvailabilityFilters: state => state.availabilityFilters,
    getPriceFilterMax: state => state.priceFilterMax,
    getPriceFilterMin: state => state.priceFilterMin,
    getPriceInitMax: state => state.priceInitMax,
    getPriceInitMin: state => state.priceInitMin,
    getPriceReset: state => state.priceFilterMax === state.priceInitMax && state.priceFilterMin === state.priceInitMin,
    getPriceFilterTag(state) {
        // Don't show tag if data is not filtered:
        if (state.priceFilterMax === state.priceInitMax && state.priceFilterMin === state.priceInitMin) {
            return false;
        }

        return `${state.priceFilterMin} - ${state.priceFilterMax} €`;
    },
    getSelectingFilters: state => state.selectingFilters,
};
