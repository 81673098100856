
export default {
    name: 'HeroBanner',
    props: {
        contentType: {
            type: String,
            required: false,
            default: 'heroBanner',
        },
        displayOverlay: {
            type: Boolean,
            required: false,
            default: false,
        },
        contents: {
            type: Array,
            required: false,
            default: () => [],
        },
        overlayBackgroundColor: {
            type: String,
            required: false,
            default: 'black',
        },
        overlayTextColor: {
            type: String,
            required: false,
            default: 'white',
        },
        overlayTitle: {
            type: String,
            required: false,
            default: '',
        },
        overlayDescription: {
            type: String,
            required: false,
            default: '',
        },
        links: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        type() {
            return this.displayOverlay
                ? 'overlay'
                : 'banner';
        },
        overlayStyles() {
            return {
                [`background-${this.overlayBackgroundColor}`]: this.overlayBackgroundColor,
                [`text-${this.overlayTextColor}`]: this.overlayTextColor,
            };
        },
        darkClass() {
            return this.overlayBackgroundColor === 'white' || this.overlayBackgroundColor === 'lightgray'
                ? 'dark'
                : false;
        },
    },
};
