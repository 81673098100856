import debounce from 'lodash/debounce';

const resizeDebounce = 100;

export default {
    data() {
        return {
            isSmallMobile: false,
            isMobile: false,
            isLargeMobile: false,
            isSmallDesktop: false,
            isDesktop: true,
            isLargeDesktop: false,
            atMostMobile: false,
            atMostTablet: false,
            atMostSmallDesktop: false,
        };
    },
    mounted() {
        if (process.client) { // eslint-disable-line nuxt/no-env-in-hooks
            this.calculateBreakpoint();

            window.addEventListener(
                'resize',
                debounce(this.calculateBreakpoint),
                resizeDebounce,
            );
        }
    },
    methods: {
        calculateBreakpoint() {
            this.isSmallMobile = this.$breakpoints.isScreenMinWidth(this.$breakpoints.smallMobile);
            this.isMobile = this.$breakpoints.isScreenMinWidth(this.$breakpoints.mobile);
            this.isLargeMobile = this.$breakpoints.isScreenMinWidth(this.$breakpoints.largeMobile);
            this.isSmallDesktop = this.$breakpoints.isScreenMinWidth(this.$breakpoints.smallDesktop);
            this.isDesktop = this.$breakpoints.isScreenMinWidth(this.$breakpoints.desktop);
            this.isLargeDesktop = this.$breakpoints.isScreenMinWidth(this.$breakpoints.largeDesktop);
            this.atMostMobile = this.isSmallMobile && !this.isLargeMobile;
            this.atMostTablet = this.isLargeMobile && !this.isSmallDesktop;
            this.atMostSmallDesktop = this.$breakpoints.isScreenMaxWidth(this.$breakpoints.desktop);
        },
    },
};
