
import { mapGetters } from 'vuex';

export default {
    name: 'ProductHeader',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
    },
    methods: {
        formatPrice(price) {
            return `${price.toFixed(0)} €`;
        },
    },
};
