
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'AmountFilter',
    directives: {
        clickOutside,
    },
    props: {
        name: {
            type: String,
            required: true,
            default: '',
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        scope: {
            type: String,
            required: true,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        location: {
            type: String,
            required: false,
            default: 'primary',
        },
        type: {
            type: String,
            required: true,
            default: '',
        },
        title: {
            type: String,
            required: true,
            default: '',
        },
        unit: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: [Number, String],
            required: true,
            default: '',
        },
    },
    data: () => ({
        internalValue: 0,
        open: false,
        timeout: null,
    }),
    watch: {
        value(to, from) {
            this.internalValue = to;
        },
    },
    methods: {
        setValue(value) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }

            this.internalValue = value;

            this.timeout = window.setTimeout(this.handleClickOutside, 1500);
        },
        mounted() {
            this.internalValue = this.value;
        },
        setOpen(value) {
            if (this.disabled) {
                return false;
            }

            if (value === true) {
                this.internalValue = this.value;
            }

            this.open = value;
        },
        handleClickOutside() {
            if (this.open) {
                if (this.value !== this.internalValue) {
                    this.$emit('input', {
                        name: this.name,
                        scope: this.scope,
                        value: Number(this.internalValue),
                    });
                }
                this.open = false;
            }
        },
    },
};
