import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=da6e82fc&scoped=true"
import script from "./Cart.vue?vue&type=script&lang=js"
export * from "./Cart.vue?vue&type=script&lang=js"
import style0 from "./Cart.vue?vue&type=style&index=0&id=da6e82fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6e82fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default,CartDetails: require('/build/src/components/layout/Cart/CartDetails.vue').default,Upsell: require('/build/src/components/elements/Upsell.vue').default,Button: require('/build/src/components/elements/Button.vue').default,ModalLogin: require('/build/src/components/elements/ModalLogin.vue').default})
