
import fieldMixin from '~/mixins/widget';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'HeroContent',
    mixins: [breakpoints, fieldMixin],
    props: {
        contentType: {
            type: String,
            required: false,
            default: 'heroContent',
        },
        entryName: {
            type: String,
            required: false,
            default: 'Overlay',
        },
        title: {
            type: String,
            required: false,
            default: 'Title',
        },
        titleType: {
            type: String,
            required: false,
            default: 'H2',
        },
        buttons: {
            type: Array,
            required: false,
            default: () => [],
        },
        image: {
            type: [Object, Boolean],
            required: false,
            default: false,
            src: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                required: false,
                default: 'Image',
            },
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'white',
        },
        textColor: {
            type: String,
            required: false,
            default: 'black',
        },
        textBackgroundColor: {
            type: String,
            required: false,
            default: 'lightgray',
        },
        shortDescription: {
            type: String,
            required: false,
            default: '',
        },
        hasGradient: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['slider', 'banner', 'overlay', 'slide'].includes(value);
            },
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        hasBackgroundImage() {
            return !!this.image;
        },
    },
};
