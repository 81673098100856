
import { mapGetters } from 'vuex';

export default {
    name: 'CategoryProductList',
    data() {
        return {
            show: 1,
        };
    },
    computed: {
        ...mapGetters({
            categoryItems: 'category/getFilteredCategoryItems',
            isLoading: 'product/getAvailabilityLoading',
            selectingFilters: 'category/getSelectingFilters',
        }),
        cardMax() {
            if (this.computedItems.length) {
              return this.computedItems.length;
            }

             return 12;
        },
        progressValue() {
            if (this.show * this.cardMax > this.categoryItems.length) {
                return this.categoryItems.length;
            }

            return this.show * this.cardMax;
        },
        computedItems() {
            const { categoryItems } = this;
            const offerCategoryItem = categoryItems.filter(categoryItem => categoryItem.available ? categoryItem.product?.attributes.has_offer && categoryItem.product?.classification === 'Accommodation' : null);
            const normalCategoryItem = categoryItems.filter(categoryItem => categoryItem.available ? !categoryItem.product?.attributes.has_offer && categoryItem.product?.classification === 'Accommodation' : null);
            const noOptionsCategoryItem = categoryItems.filter(categoryItem => !categoryItem.available && categoryItem.product?.classification === 'Accommodation');
            const sortedOffers = offerCategoryItem.sort((a, b) => a.product.attributes.offer_order - b.product.attributes.offer_order );
            const sortedNormalCategoryItem = normalCategoryItem.sort((a, b) => a.product.attributes.offer_order - b.product.attributes.offer_order );
            const sortedNoOptionsCategoryItem = noOptionsCategoryItem.sort((a, b) => a.product.attributes.offer_order - b.product.attributes.offer_order );

            return [...sortedOffers, ...sortedNormalCategoryItem, ...sortedNoOptionsCategoryItem];
        },
    },
    methods: {
        scrollToElement() {
            const el = document.getElementById('category-title')
                ?? this.$refs.productlist;

            if (el) {
                el.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
            }
        },
    },
};
