import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();
const httpStatus = require('http-status');

export const state = () => ({
    fields: {
        name: '',
        email: '',
        message: '',
        captcha: '',
    },
    redirect: null,
});

export const mutations = {
    ...storeForm.mutations,
    clear(state) {
        state.fields = {
            name: '',
            email: '',
            message: '',
            captcha: '',
        };
    },
};

export const actions = {
    async submit(ctx) {
        try {
            await this.$axios.request({
                url: this.$paths.getEndpoint('accessibility'),
                method: 'post',
                headers: {
                    captcha: ctx.state.fields.captcha,
                },
                data: {
                    ...ctx.state.fields,
                },
            });
            this.$gtm.push({
                event: 'accessibilityFeedback',
            });
            this.dispatch('messages/setMessage', {
                type: 'success',
                message: this.app.i18n.t('accessibility.message.sent'),
            });
            ctx.commit('clear');
            this.$router.push(this.$paths.getPath('landingPage'));
        } catch (e) {
            switch (e.response?.status) {
                case httpStatus.PRECONDITION_FAILED:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('accessibility.message.captchaFailed'),
                    });
                break;

                default:
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('accessibility.message.failed'),
                });
            }
        }
    },
};

export const getters = {
    ...storeForm.getters,
    getRedirect: state => state.redirect,
};
