
    import { mapGetters, mapActions } from 'vuex';
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'ProductFilters',
        mixins: [breakpoints],
        props: {
            showTitle: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        data() {
            return {
                popup: false,
            };
        },
        computed: {
            ...mapGetters({
                filters: 'category/getSecondaryFilters',
            }),
            mobileShow() {
                return !this.isSmallDesktop;
            },
            firstTitle() {
                return this.filters.length ? this.filters[0]?.title : '';
            },
        },
        methods: {
            ...mapActions({
                toggleFilters: 'category/toggleFilters',
            }),
            isSelected(filter, option) {
                return filter.selectedOptions?.includes(option?.key);
            },
        },
    };
