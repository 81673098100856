import { DateTime } from 'luxon';
import StoreForm from '~/util/storeFormExtend';

const storeForm = new StoreForm();

const defaultState = () => ({
    fields: {
        id: '',
        apartmentId: '',
        start: DateTime.now(),
        end: DateTime.now().plus({ days: 1 }),
        numberOfGuests: 1,
        sendCopy: [],
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        additionalInfo: '',
    },
    step: 1,
    loading: false,
});

export default {
    state: () => defaultState(),
    mutations: {
        setPayloadValue(state, payload) {
            Object.keys(payload).forEach(key => {
                if (typeof(payload[key]) === typeof(state[key])) {
                    if (typeof(payload[key]) === 'object') {
                        Object.assign(state[key], payload[key]);
                    } else {
                        state[key] = payload[key];
                    }
                } else {
                    throw new Error('Could not set value on homeowner/ownReservation');
                }
            });
        },
        reset (state) {
            Object.assign(state, defaultState());
        },
        ...storeForm.mutations,
    },
    actions: {
        async load (ctx) {
            this.commit('homeowner/ownReservation/loading', true);
            try {
                const { id, apartmentId } = ctx.state.fields;

                const { data } = await this.$axios.request({
                    method: 'get',
                    url: `/proxy/vm-homeowner/v1/apartments/${apartmentId}/reservations/${id}`,
                });
                const { 0: guest } = data.guests;

                this.commit('homeowner/ownReservation/setPayloadValue', {
                    fields: {
                        id: data.id,
                        start: DateTime.fromFormat(data.dateStart, 'yyyy-MM-dd'),
                        end: DateTime.fromFormat(data.dateEnd, 'yyyy-MM-dd'),
                        numberOfGuests: data.numberOfGuests,
                        firstName: guest.firstName,
                        lastName: guest.lastName,
                        email: guest.email,
                        phone: guest.phone,
                        additionalInfo: data.additionalInfo,
                    },
                });
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('homeowner.message.loadFailed'),
                });
            }
            this.commit('homeowner/ownReservation/loading', false);
        },
        async save (ctx) {
            this.commit('homeowner/ownReservation/loading', true);
            try {
                let method, endpoint;
                const {
                    id,
                    apartmentId,
                    start,
                    end,
                    numberOfGuests,
                    firstName,
                    lastName,
                    email,
                    phone,
                    additionalInfo,
                    sendCopy,
                } = ctx.state.fields;

                if (id !== '') {
                    method = 'put';
                    endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/reservations/${id}`;
                } else {
                    method = 'post';
                    endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/reservations`;
                }

                const response = await this.$axios.request({
                    method,
                    url: endpoint,
                    data: {
                        dateStart: start.toFormat('yyyy-MM-dd'),
                        dateEnd: end.toFormat('yyyy-MM-dd'),
                        numberOfGuests,
                        guest: {
                            firstName,
                            lastName,
                            email,
                            phone,
                        },
                        additionalInfo,
                        receiveConfirmationEmailCopy: sendCopy.includes(true),
                    },
                });

                await this.dispatch('homeowner/apartment/load', apartmentId);
                this.commit('homeowner/ownReservation/setPayloadValue', {
                    step: 2,
                });
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('homeowner.message.reservationFailed'),
                });
                this.commit('homeowner/ownReservation/loading', false);
            };
        },

    },
    getters: {
        getStep: state => state.step,
        ...storeForm.getters,
    },
};
