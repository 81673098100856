
    import { mapMutations } from 'vuex';
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'ProductModal',
        mixins: [breakpoints],
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            imageUrl: {
                type: [String, Boolean],
                required: false,
                default: false,
            },
            id: {
                type: String,
                required: false,
                default: '',
            },
        },
        computed: {
            bgStyle() {
                if (this.imageUrl?.length) {
                    return { 'background-image': `linear-gradient(to bottom, rgba(10, 10, 10, 0.2), rgba(10, 10, 10, 0.2)), url('${this.imageUrl}')` };
                }

                return '';
            },
        },
        methods: {
            ...mapMutations({
                setId: 'modal/setId',
                openModal: 'modal/setOpen',
            }),
            show() {
                this.setId(this.id);
                this.openModal(true);
            },
            hide() {
                this.openModal(false);
            },
        },
    };
