
import { mapGetters, mapMutations, mapActions } from 'vuex';

const loginId = '3YiYKMLe7Rl2ukt7CtdJ0I';

export default {
    name: 'Header',
    computed: {
        ...mapGetters({
            navOne: 'navigation/getNavOne',
            navTwo: 'navigation/getNavTwo',
            navTwoActive: 'navigation/getNavTwoActive',
            langState: 'navigation/getLanguageState',
            searchOpen: 'navigation/getSearchOpen',
            loginOpen: 'navigation/getLoginOpen',
            localizedPaths: 'navigation/getLocalizedPaths',
            isLoggedIn: 'user/getLoggedIn',
            userData: 'user/getFields',
            apartments: 'homeowner/apartments/getApartments',
        }),
        languages() {
            return [
                { code: 'fi', label: this.$t('lang.fi') },
                { code: 'en', label: this.$t('lang.en') },
                // { code: 'sv', label: this.$t('lang.sv') },
            ];
        },
        getNavOne() {
            return this.navOne.filter(v => v.id !== loginId);
        },
        loginLink() {
            return this.navOne.filter(v => v.id === loginId);
        },
        isCheckout() {
            return this.$route.path === this.$paths.getPath('checkout');
        },
        userName() {
            const [{ title }] = this.loginLink;

            if (!this.isLoggedIn) {
                return title;
            } else if (this.userData.firstName) {
                return this.userData.firstName;
            }

            return this.$t('navigation.user');
        },
    },
    methods: {
        ...mapActions({
            logout: 'login/logout',
        }),
        ...mapMutations({
            openLang: 'navigation/toggleLanguage',
            openLogin: 'navigation/toggleLoginOpen',
        }),
        switchLocale(langCode) {
            if (langCode === 'sv' || langCode === 'en') {
                if (this.localizedPaths[langCode] === '/') {
                    this.$router.push(`/${langCode}`);
                } else if (this.localizedPaths[langCode]) {
                    this.$router.push(`/${langCode}/${this.localizedPaths[langCode]}`);
                } else {
                    this.$router.push(`/${langCode}`);
                }
            } else if (this.localizedPaths[langCode] === '/') {
                    this.$router.push('/');
                } else {
                    this.$router.push(`/${this.localizedPaths[langCode]}`);
                }
            this.openLang();
        },
        loginClick() {
            if (this.isLoggedIn) {
                this.openLogin();
            } else {
                this.$router.push(this.$paths.getPath('login'));
            }
        },
    },
};
