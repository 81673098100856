import { Storage } from '~storage'

export default function nuxtUniversalStorage(ctx, inject) {
  let options = {
  "vuex": false,
  "cookie": false,
  "localStorage": {
    "prefix": ""
  },
  "ignoreExceptions": false
}
  const cookie = false

  options = {
    ...options,
    cookie
  }

  // Create new instance of Storage class
  const storage = new Storage(ctx, options)

  // Inject into context as $storage
  ctx.$storage = storage
  inject('storage', storage)
}
