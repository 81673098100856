
    import breakpoints from '~/mixins/breakpoints';

    export default {
        mixins: [breakpoints],
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            tags: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        data() {
            return {
                show: 1,
                cardMax: 12,
                articles: [],
            };
        },
        async fetch() {
            try {
                const response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-view/v1/article/search',
                    params: {
                        locale: this.$i18n.locale,
                    },
                    data: {
                        tagIds: this.tags,
                    },
                });

                if (response?.data) {
                    this.articles = response.data.sort(this.sortDates);
                }

            } catch (e) {
                console.error(e);
            }
        },
        computed: {
            progressValue() {
                if (this.show * this.cardMax > this.articles?.length) {
                    return this.articles?.length;
                }

                return this.show * this.cardMax;
            },
        },
        methods: {
            getLinkElement(article) {
                return { path: article.pagePath };
            },
            hasPagePath(article) {
                return article?.pagePath?.length;
            },
            articleHas(article, property) {
                return property in article && article[property];
            },
            sortDates(a, b) {
                if (!a.createdAt || !b.createdAt) {
                    return 0;
                }

                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);

                return dateB - dateA;
            },
            isLastRow(index) {
                let perRow = 3;

                if (this.atMostTablet) {
                    perRow = 2;
                }
                const rowTotal = parseInt((this.progressValue - 1) / perRow);

                return index === this.progressValue || (parseInt(index / perRow) === rowTotal);
            },
        },
    };
