
export default {
    name: 'FrontifyMedia',
    props: {
        // frontify data
        id: {
            type: Number,
            required: false,
            default: 0,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
        ext: {
            type: String,
            required: false,
            default: '',
        },
        width: {
            type: Number,
            required: false,
            default: 0,
        },
        height: {
            type: Number,
            required: false,
            default: 0,
        },
        created: {
            type: String,
            required: false,
            default: '',
        },
        generic_url: { // eslint-disable-line vue/prop-name-casing
            type: String,
            required: false,
            default: '',
        },
        preview_url: { // eslint-disable-line vue/prop-name-casing
            type: String,
            required: false,
            default: '',
        },
        src: {
            type: String,
            required: false,
            default: '',
        },
        // actual props
        computeWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
        fill: {
            type: String,
            required: false,
            validator: v => ['cover', 'contain'].indexOf(v) !== -1,
            default: 'contain',
        },
        scaling: {
            type: String,
            required: false,
            validator: v => ['width', 'height'].indexOf(v) !== -1,
            default: 'width',
        },
        keepAspectRatio: {
            type: Boolean,
            required: false,
            default: false,
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            debounce: false,
            isMounted: false,
        };
    },
    computed: {
        isServer() {
            return process.server;
        },
        isVideo() {
            return this.ext === 'mp4';
        },
        videoSource() {
            // Add #t to the URL so that iOS Safari shows the video preview
            return `${this.generic_url.replace('width={width}', 'width=1920')}#t=0.001`;
        },
        computedStyle() {
            if (!this.isMounted) {
                return false;
            }

            if (this.$refs.container) {
                const height = Math.round(this.$refs.container.getBoundingClientRect().height);

                if (this.scaling === 'height' && this.computeWidth) {
                    return `width: ${Math.round(this.width/this.height * height)}px`;
                }
            }

            return '';
        },
        source() {
            if (!this.isMounted || !this.$refs.container) {
                return false;
            }

            const ratio = window?.devicePixelRatio || 1;

            switch (this.scaling) {
                case 'height':
                    const height = Math.round(this.$refs.container.getBoundingClientRect().height);

                    return this.generic_url.replace('?width={width}', `?height=${Math.round(height*ratio)}`);

                default:
                case 'width':
                    const width = Math.round(this.$refs.container.getBoundingClientRect().width);

                    return this.generic_url.replace('?width={width}', `?width=${Math.round(width*ratio)}`);
            }
        },
        webpSource() {
            return this.source ? `${this.source}&format=webp` : false;
        },
        jpegSource() {
            return this.source ? `${this.source}&format=jpeg` : false;
        },
    },
    mounted() {
        if (window) {
            window.addEventListener('resize', this.resize);
        };
        this.$nextTick(() => {
            if (this.isMounted === false) {
                this.isMounted = new Date().getTime();
            }
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        resize() {
            window.clearTimeout(this.debounce);

            this.debounce = window.setTimeout(() => {
                this.isMounted = new Date().getTime();
            }, 400);
        },
    },
};
