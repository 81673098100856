import { render, staticRenderFns } from "./CategoryList.vue?vue&type=template&id=698e1a2a&scoped=true"
import script from "./CategoryList.vue?vue&type=script&lang=js"
export * from "./CategoryList.vue?vue&type=script&lang=js"
import style0 from "./CategoryList.vue?vue&type=style&index=0&id=698e1a2a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698e1a2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActivityIndicator: require('/build/src/components/elements/ActivityIndicator.vue').default,AccommodationProductCard: require('/build/src/components/elements/Cards/AccommodationProductCard.vue').default,ProductCard: require('/build/src/components/elements/Cards/ProductCard.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
