
    import FullCalendar from '@fullcalendar/vue';
    import interactionPlugin from '@fullcalendar/interaction';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import fiLocale from '@fullcalendar/core/locales/fi';
    // import svLocale from '@fullcalendar/core/locales/sv';
    // import enLocale from '@fullcalendar/core/locales/en-gb';
    import { mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'WeeklyCalendar',
        components: {
            FullCalendar,
        },
        props: {
            mobile: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                modalName: 'eventModal',
            };
        },
        computed: {
            ...mapGetters({
                events: 'calendar/getEventData',
                storeSelectedDate: 'calendar/getSelectedDate',
            }),
            calendarOptions() {
                return {
                    timeZone: 'local',
                    plugins: [timeGridPlugin, interactionPlugin],
                    locales: [fiLocale],
                    initialView: 'timeGridWeek',
                    initialDate: this.storeSelectedDate,
                    locale: this.$i18n.locale,
                    slotMinTime: '06:00:00',
                    events: this.events,
                    select: this.handleDateSelect,
                    eventClick: this.handleEventClick,
                    dayCellClassNames: o => {
                        if (this.$util.isSameDay(o.date, new Date(this.selectedDate))) {
                            return 'highlight-day';
                        }

                        return '';
                    },
                    dayHeaderClassNames: o => {
                        if (this.$util.isSameDay(o.date, new Date(this.selectedDate))) {
                            return 'highlight-header-day';
                        }

                        return '';
                    },
                    eventClassNames: o => {
                        // Add class for events under 30 mins
                        if (o?.event?.end?.getTime() - o?.event?.start?.getTime() < 30 * 60 * 1000) {
                            return 'short-event';
                        }

                        return '';
                    },
                    headerToolbar: {
                        left: 'prev',
                        center: 'title',
                        right: 'next',
                    },
                    titleFormat: { month: 'long', year: 'numeric' },
                    dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
                    slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                    },
                    allDaySlot: false,
                };
            },
            mobileOptions() {
                return {
                    ...this.calendarOptions,
                    initialView: 'timeGridDay',
                    headerToolbar: false,
                    dayCellClassNames: false,
                };
            },
            calendarApi() {
                return this.$refs.fullCalendar.getApi();
            },
            selectedDate: {
                get() {
                    return this.storeSelectedDate;
                },
                set(value) {
                    this.setSelectedDate(value.getTime());
                },
            },
        },
        watch: {
            storeSelectedDate: {
                handler(newVal) {
                    this.goToDate(newVal);
                },
            },
        },
        methods: {
            ...mapMutations({
                setSelectedDate: 'calendar/setSelectedDate',
                setSelectedEvent: 'calendar/setSelectedEvent',
                setId: 'modal/setId',
                openModal: 'modal/setOpen',
                setReturnElement: 'modal/setReturnElement',
            }),
            isSameDay(dateToCheck, actualDate) {
                return (dateToCheck.getDate() === actualDate.getDate()
                    && dateToCheck.getMonth() === actualDate.getMonth()
                    && dateToCheck.getFullYear() === actualDate.getFullYear());
            },
            handleDateSelect() {
                // console.log('selectDate');
            },
            async handleEventClick(event) {
                await this.setSelectedEvent(event.event.id);
                this.setId(this.modalName);
                this.openModal(true);
                if (event?.el?.className?.length) {
                    const returnElement = event?.el?.className.split(' ').map(item => item.length ? `.${item}` : '')
                        .join('');
                    this.setReturnElement(returnElement);
                }
            },
            goToDate(date) {
                this.calendarApi.gotoDate(date);
            },
        },
    };
