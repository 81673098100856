
    import { mapActions, mapGetters } from 'vuex';
    import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

    export default {
        name: 'VMFilterSelect',
        directives: {
            clickOutside,
        },
        props: {
            index: {
                type: Number,
                required: false,
                default: 0,
            },
        },
        data() {
            return {
                open: false,
            };
        },
        computed: {
            ...mapGetters({
                categoryFilters: 'category/getCategoryFilters',
                selectedFilters: 'category/getSelectedFilters',
                getOptionBySelected: 'category/getOptionBySelected',
            }),
            filter() {
                if (this.categoryFilters && this.categoryFilters.length >= this.index) {
                    return this.categoryFilters[this.index];
                }

                return false;
            },
            getSelectedName() {
                if (this.hasSelectedOptions) {
                    const option = this.getOptionBySelected(this.selectedFilters?.find(item => item.filterName === this.filter.name));
                    if (option && 'name' in option) {
                        return option.name;
                    }
                }

                return '';
            },
            hasSelectedOptions() {
                return !!this.filter?.selectedOptions.length;
            },
            isOpen() {
                return this.open;
            },
            getIcon() {
                switch (this.filter?.name) {
                    case 'application_method':
                        return 'list-add';
                    break;

                    case 'method_of_education':
                        return 'list';
                    break;

                    case 'education_theme':
                        return 'globe';
                    break;

                    default:
                        return 'info';
                    break;
                }

                return 'info';
            },
        },
        methods: {
            ...mapActions({
                toggleFilters: 'category/toggleFilters',
            }),
            doOpen() {
                this.open = !this.open;
            },
            handleClickOutside() {
                if (this.open) {
                    this.open = !this.open;
                }
            },
            isSelected(filter, option) {
                return filter.selectedOptions?.includes(option?.key);
            },
            updateFilters(e, filter, option) {
                this.toggleFilters({ filter, option });
            },

        },
    };
