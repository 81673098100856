
export default {
    props: {
        image: {
            src: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                required: false,
                default: 'Image',
            },
            type: [Object, Boolean],
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
        button: {
            type: [Object, Boolean],
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: 'card',
            validator(value) {
                return ['card', 'slider-card', 'product'].includes(value);
            },
        },
        cardSize: {
            type: String,
            required: false,
            default: 'small',
            validator(value) {
                return ['small', 'large'].includes(value);
            },
        },
    },
    computed: {
        imageAlt() {
            return this.image?.title?.length ? this.image.title : this.title;
        },
    },
};
