
import fieldMixin from '~/mixins/widget';

export default {
    name: 'HalfContentGroup',
    mixins: fieldMixin,
    props: {
        isBoxed: {
            type: Boolean,
            required: false,
            default: false,
        },
        priorityBackground: {
            type: String,
            required: false,
            default: '',
        },
        usePriorityBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        contents: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    computed: {
        priorityBackgroundClass() {
            if (this.usePriorityBackground) {
                return `group-background-${this.priorityBackground}`;
            }

            return '';
        },
    },
};
