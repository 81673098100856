import { render, staticRenderFns } from "./LinkElement.vue?vue&type=template&id=686aa045&scoped=true"
import script from "./LinkElement.vue?vue&type=script&lang=js"
export * from "./LinkElement.vue?vue&type=script&lang=js"
import style0 from "./LinkElement.vue?vue&type=style&index=0&id=686aa045&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686aa045",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulModal: require('/build/src/components/blocks/ContentfulModal.vue').default})
