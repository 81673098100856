
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'DiscountCode',
    props: {
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            discountCode: 'cart/getDiscountCode',
            discountSubmitted: 'cart/getDiscountSubmitted',
            totalsLoading: 'cart/getTotalsLoading',
        }),
    },
    methods: {
        ...mapActions({
            getTotals: 'cart/getTotals',
        }),
        ...mapMutations({
            setDiscountCode: 'cart/setDiscountCode',
            setDiscountSubmitted: 'cart/setDiscountSubmitted',
        }),
        handleSubmit() {
            if (this.discountSubmitted) {
                this.setDiscountCode('');
                this.setDiscountSubmitted(false);
                this.$emit('submit');
                this.getTotals();
            } else {
                this.setDiscountSubmitted(true);
                this.$emit('submit');
                this.getTotals();
            }
        },
    },
};
