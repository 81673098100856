
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import HCaptchaConfig from '~config/hcaptcha.json';

const emailRegExp = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
const phoneRegExp = /^[0-9\+\s]+$/;

export default {
    name: 'VMForm',
    components: {
        VueHcaptcha,
    },
    props: {
        content: {
            type: Object,
            required: false,
            default: () => {},
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        focusSearchInput: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        verificationPassed: false,
        sitekey: HCaptchaConfig?.sitekey,
        translated: false,
        errors: {},
    }),
    computed: {
        hasVerification() {
            const field = this.content.fields.find(v => v.type === 'hCaptcha') ?? null;

            if (field) {
                return true;
            }

            return false;
        },
        verified() {
            if (this.hasVerification && !this.verificationPassed) {
                return false;
            }

            return true;
        },
        fields() {
            const target = this.content.target ? this.content.target : 'fields';

            return this.content.fields.map((field, fkey) => {
                // fix type if not set
                if (!field.key) {
                    field.key = `field-${fkey}`;
                }
                // fix type if not set
                if (!field.type) {
                    field.type = 'text';
                }

                // set error state
                if (this.errors[field.key]) {
                    field.error = this.errors[field.key];
                } else if (typeof(field.validator) === 'object' && field.validator.length) {
                    field.error = '';
                }

                // connect store
                field.value = this.$store.getters[`${this.content.store}/getField`](field.dataKey, target);

                return field;
            });
        },
        loading() {
            return this.$store.getters[`${this.content.store}/loading`];
        },
    },
    watch: {
        loading(to, from) {
            this.validateAll(this.content.fields, true);
        },
    },
    mounted() {
        this.translated = true;
        this.validateAll(this.content.fields, true);
    },
    methods: {
        getTr(key) {
            if (this.content.init) {
                return key;
            } else if (this.content.translation) {
                if (this.translated) {
                    return key;
                }

                return this.$t(`${this.content.translation}.${key.replace(`${this.content.translation}.`, '')}`);
            }

            return this.$t(key);
        },
        commitValue(payload) {
            const field = this.content.fields.find(v => v.dataKey === payload.dataKey);
            const target = this.content.target ? this.content.target : 'fields';

            if (field.timeout) {
                window.clearTimeout(field.timeout);
            }

            field.timeout = window.setTimeout(() => {
                this.validateAll(this.content.fields, true);
                this.validate(field);
            }, 2000);

            this.$store.commit(`${this.content.store}/setValue`, {
                target,
                field: payload.dataKey,
                value: payload.value,
            });
            this.$emit('change', {
                target,
                field: payload.dataKey,
                value: payload.value,
            });
            if (field) {
                field.value = this.$store.getters[`${this.content.store}/getField`](field.dataKey, target);
            }
        },
        validateAll(fields, silent = false) {
            const hasErrors = [];

            if (typeof(fields) === 'object') {
                Object.keys(fields).forEach(key => {
                    hasErrors.push(this.validate(fields[key], silent));
                });
            }
            const result = hasErrors.filter(v => v !== true).length > 0;

            this.$emit('isValid', !result);

            return result;
        },
        validate(field, silent = false) {
            const target = this.content.target ? this.content.target : 'fields';
            const Exception = {};

            if (typeof(field.validator) === 'object') {
                try {
                    field.validator.forEach(validator => {
                        if (validator === 'notEmpty' && (!field.value || (field.type === 'checkbox' && field.value.length === 0))) {
                            Exception.message = this.$t('form.error.empty');
                            throw Exception;
                        } else if (validator === 'email' && !field.value.match(emailRegExp)) {
                            Exception.message = this.$t('form.error.email');
                            throw Exception;
                        } else if (validator === 'phone' && !field.value.match(phoneRegExp)) {
                            Exception.message = this.$t('form.error.phone');
                            throw Exception;
                        } else if (typeof(validator) === 'object') {
                            switch (validator.type) {
                                case 'regExp':
                                    const matcher = new RegExp(validator.value);
                                    if (field.value.match(matcher) === null) {
                                        Exception.message = this.$t('form.error.pattern');
                                        throw Exception;
                                    }
                                break;
                                case 'minLength':
                                    if (field.value.length < validator.value) {
                                        Exception.message = this.$t('form.error.minlength', [validator.value]);
                                        throw Exception;
                                    }
                                break;
                                case 'maxLength':
                                    if (field.value.length > validator.value) {
                                        Exception.message = this.$t('form.error.maxlength', [validator.value]);
                                        throw Exception;
                                    }
                                break;
                                case 'equals':
                                    if (field.value !== this.$store.getters[`${validator.field[0]}/getField`](validator.field[1], target)) {
                                        Exception.message = this.$t('form.error.equals');
                                        throw Exception;
                                    }
                                break;
                            }
                        }
                    });
                } catch (e) {
                    if (!silent) {
                        this.$set(this.errors, field.key, e.message);
                    }
                    if (e.message) {
                        return false;
                    }
                };
                this.$delete(this.errors, field.key);

                return true;
            }

            field.error = '';

            return true;
        },
        handleButtonClick(action) {
            if (typeof(action) === 'string' && action === 'submit') {
                if (this.validateAll(this.content.fields)) {
                    this.$store.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.$t('form.error.invalid'),
                    });
                } else {
                    this.$store.dispatch(this.content.action);
                }
            } else if (typeof(action) === 'string') {
                if (this.validateAll(this.content.fields)) {
                    this.$store.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.$t('form.error.invalid'),
                    });
                } else {
                    this.$store.dispatch(action);
                }
            } else if (typeof(action) === 'function') {
                action();
            }
        },
        handleSubmitClick(action) {
            if ((this.hasVerification && this.verificationPassed)
                || !this.hasVerification) {
                this.handleButtonClick(action);
            }
        },
        onVerify(response, payload) {
            this.commitValue({
                dataKey: payload.dataKey,
                value: response,
            });
            this.verificationPassed = true;
        },
        submit() {
            if ((this.hasVerification && this.verificationPassed) || !this.hasVerification) {
                if (typeof(this.content.action) === 'string') {
                    if (this.validateAll(this.content.fields)) {
                        this.$store.dispatch('messages/setMessage', {
                            type: 'error',
                            message: this.$t('form.error.invalid'),
                        });
                    } else {
                        this.$store.dispatch(this.content.action);
                    }
                }
            }
        },
    },
};
