
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'RadioFilter',
    directives: {
        clickOutside,
    },
    props: {
        name: {
            type: String,
            required: true,
            default: '',
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        scope: {
            type: String,
            required: true,
            default: '',
        },
        location: {
            type: String,
            required: false,
            default: 'primary',
        },
        type: {
            type: String,
            required: true,
            default: '',
        },
        title: {
            type: String,
            required: true,
            default: '',
        },
        options: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        value: {
            type: String,
            required: true,
            default: '',
        },
    },
    data: () => ({
        open: false,
    }),
    computed: {
        valueText() {
            if (this.value) {
                const current = Object.values(this.options).find(v => v.value === this.value);

                return current.title;
            };

            return this.value;
        },
    },
    methods: {
        select(value) {
            this.$emit('input', {
                name: this.name,
                scope: this.scope,
                value,
            });
            this.toggleOpen();
        },
        toggleOpen() {
            if (this.disabled) {
                this.open = false;
            } else {
                this.open = !this.open;
            }
        },
        handleClickOutside() {
            this.open = false;
        },
    },
};
