
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'ProductData',
    mixins: [breakpoints],
    props: {
        contentType: {
            type: String,
            required: false,
            default: 'productData',
        },
        fields: {
            type: Object,
            required: false,
            default: () => ({
                title: '',
                displayType: 'Upcoming Sales',
            }),
        },
    },
    data() {
        return {
            active: 0,
        };
    },
    computed: {
        ...mapGetters({
            availabilityLoading: 'product/getAvailabilityLoading',
            totalsLoading: 'cart/getTotalsLoading',
            loading: 'product/upcoming/getLoading',
            availability: 'product/upcoming/getAvailability',
            product: 'product/getProduct',
            bpsLimits: 'breakpoints/getLimits',
        }),
        bps() {
            return {
                [this.bpsLimits.desktop]: { perView: 2 },
                [this.bpsLimits.largeMobile]: { perView: 1 },
            };
        },
        parsedContent() {
            return this.availability.map(product => {
                const startingTime = new Date(product.product?.attributes?.active_hour_date);
                const hoursMinutes = product.product?.attributes?.active_hour_start_time.split(':');
                startingTime.setHours(hoursMinutes[0]);
                startingTime.setMinutes(hoursMinutes[1]);

                return {
                    type: product.itemType,
                    code: product.product?.code,
                    image: this.$util.parseImage(product.product?.mainImage, 2).url,
                    name: product.product?.attributes?.marketing_name,
                    location: product.product?.attributes?.active_hour_location,
                    description: product.product?.attributes?.product_description_short,
                    available: product.availableQuantity,
                    from: startingTime,
                    duration: product.product?.attributes?.active_hour_duration,
                    price: product.price,
                    discountAmount: 0,
                };
            });
        },
        isRenderable() {
            return this.product.type === 'category' && this.product.schema === 'Active hours';
        },
        iconProperties() {
            return {
                size: this.isDesktop ? 'xxxl' : 'xxl',
                icon: 'slider-arrow',
            };
        },
        showLastButton() {
            if (this.atMostMobile) {
                return !(this.active >= this.parsedContent.length - 1);
            } else if (this.atMostSmallDesktop) {
                return !(this.active >= this.parsedContent.length - 2);
            }

            return !(this.active >= this.parsedContent.length - 3);
        },
    },
    mounted() {
        this.loadAvailability();
    },
    created() {
        this.loadAvailability();
    },
    destroyed() {
        this.setUpcoming([]);
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/upcoming/loadAvailability',
        }),
        ...mapMutations({
            addProduct: 'cart/addProduct',
            setUpcoming: 'product/upcoming/setAvailability',
        }),
        addProductToCart(item) {
            const additionalData = {
                metadata: {
                    comment: '',
                    amount: item.amount,
                },
                pagePath: this.product.product_pagePath ?? this.product.category_pagePath,
                classification: item?.product?.classification,
            };

            this.addProduct({
                type: item.type,
                productCode: item.code,
                from: DateTime.fromJSDate((new Date(item.from))),
                to: DateTime.fromJSDate(new Date((new Date(item.from)).getTime() + (item.duration * 60 * 1000))),
                image: item.image,
                title: item.name,
                price: item.price,
                location: item.location,
                persons: {
                    ...this.$store.getters['product/getPersons'],
                },
                discountAmount: item.discountAmount,
                priceWithoutTax: 0,
                ...additionalData,
            });

            this.$store.dispatch('cart/getTotals');

            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(item, 'addToCart', additionalData.pagePath)],
                },
            });
        },
    },
};
