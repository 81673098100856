import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

function getDefaultState() {
    return {
        loggedIn: false,
        emailStatus: null,
        userData: {},
        editing: false,
        loading: false,
        fields: {
            hlxId: -1,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            zip: '',
            city: '',
            isEmailVerified: false,
            marketingStatus: false,
        },
    };
};

export const state = () => getDefaultState();

export const mutations = {
    setValueFromStorage(state, payload) {
        state[payload.key] = payload.value;
    },
    setEmailStatus(state, value) {
        state.emailStatus = value;
    },
    setLoggedIn(state, payload) {
        if (typeof(payload) === 'boolean') {
            state.loggedIn = payload;
        } else {
            throw new Error('Invalid payload to user/setLoggedIn');
        }
    },
    assignUserData(state, payload) {
        Object.keys(payload).forEach(index => {
            if (typeof(state.fields[index]) === typeof(payload[index])) {
                state.fields[index] = payload[index];
            }
        });
        this.$storage.setUniversal('user', {
            ...state.fields,
        });
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    },
    setLoading(state, value) {
        state.loading = value;
    },
    ...storeForm.mutations,
};

export const actions = {
    async loadUserData() {
        try {
            const { data } = await this.$axios.request({
                method: 'get',
                url: '/proxy/vm-customer/v2/user',
            });
            this.commit('user/assignUserData', data);
        } catch (e) {
            if (process.env.NODE_ENV) {
                console.error(e);
            }
        }
    },
    async submit(ctx) {
        try {
            const values = ctx.state.fields;

            const response = await this.$axios.request({
                method: 'put',
                url: '/proxy/vm-customer/v2/user',
                data: values,
            });
            this.commit('user/assignUserData', values);
            this.dispatch('messages/setMessage', {
                type: 'success',
                message: this.app.i18n.t('user.messages.updateSuccessBut'),
            });
        } catch (e) {
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('user.messages.updateFail'),
            });
        };
    },
};

export const getters = {
    getLoggedIn: state => state.loggedIn,
    getEmailStatus: state => state.emailStatus,
    getEditing: state => state.editing,
    completedRegistration: state => {
        let status = 0;
        const notCompleteState = {
            firstName: '',
            lastName: '',
            phone: '',
            address: '',
            city: '',
            zip: '',
        };

        Object.keys(notCompleteState).forEach(key => {
            if (notCompleteState[key] === state.fields[key]) {
                status = status+1;
            }
        });

        return status === 0;
    },
    loading: state => state.loading,
    ...storeForm.getters,
};
