
import { mapGetters } from 'vuex';

function getObjects(obj, key, val) {
    let objects = [];
    for (const i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] === 'object') {
            objects = objects.concat(getObjects(obj[i], key, val));
        } else if (i === key && obj[i] === val || i === key && val === '') {
            objects.push(obj);
        } else if (obj[i] === val && key === ''){
            if (objects.lastIndexOf(obj) === -1){
                objects.push(obj);
            }
        }
    }

    return objects;
}

export default {
    name: 'BreadCrumbs',
    computed: {
        ...mapGetters({
            getNavTwo: 'navigation/getNavTwo',
            getActivePage: 'navigation/getActivePage',
        }),
    },
    methods: {
        idToTitle(id) {
            const found = getObjects(this.getNavTwo, 'id', id);

            if (found) {
                return found[0].title;
            }

                return id;

        },
    },
};
