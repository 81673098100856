import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    submitted: false,
    fields: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        streetAddress: '',
        zipCode: '',
        city: '',
        terms: [],
        marketingEmail: [],
        register: [],
    },
});

export const mutations = {
    ...storeForm.mutations,
    setSubmitted(state, payload) {
        state.submitted = payload;
    },
};

export const actions = {
    submit(ctx) {
        ctx.commit('setSubmitted', true);
        this.commit('modal/setOpen', false);
    },
};

export const getters = {
    ...storeForm.getters,
    getSubmitted: state => state.submitted,
    getUserData: state => state.fields,
};
