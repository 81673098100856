import dsModalModule from './modules/dsmodal';

const options = JSON.parse('{"namespace":"dsmodal"}');
const { namespace } = options;

export default ({ store }) => {
    store.registerModule(namespace, dsModalModule, {
        preserveState: Boolean(store.state[namespace]),
    });
};
