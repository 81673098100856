const paths = {
    url: {
        landingPage: {
            fi: '/',
            en: '/en',
        },
        search: {
            fi: '/haku',
            en: '/en/search',
        },
        login: {
            fi: '/kirjaudu',
            en: '/en/login',
        },
        resetPassword: {
            fi: '/vaihda-salasana',
            en: '/en/reset-password',
        },
        registration: {
            fi: '/rekisteroidy',
            en: '/en/registration',
        },
        profile: {
            fi: '/profile',
            en: '/en/profile',
        },
        homeowner: {
            fi: '/homeowner',
            en: '/en/homeowner',
        },
        checkout: {
            fi: '/checkout',
            en: '/en/checkout',
        },
        cancellationPolicy: {
            fi: '/peruutusehdot',
            en: '/en/cancellation-policy',
        },
        accommodationSearch: {
            fi: '/majoitushaku',
            en: '/en/accommodation',
        },
        courseSearch: {
            fi: '/kurssihaku',
            en: '/kurssihaku',
        },
        giftCard: {
            fi: '/lahjakortti',
            en: '/lahjakortti',
        },
    },
    endpoint: {
        layout: '/proxy/vm-view/v1/content/layout/main',
        content: '/proxy/vm-view/v1/content/page',
        articles: '/proxy/vm-view/v1/category/items',
        accessibility: '/proxy/vm-communication/v1/email/send-accessibility-feedback',
        registration: '/proxy/vm-customer/v2/register',
        verify: '/proxy/vm-customer/v2/email/verify',
        verifyEmail: '/proxy/vm-customer/v2/account/exists',
        logout: '/api/v2/logout',
        homeownerApartments: '/proxy/vm-homeowner/v1/apartments',
    },
};

export { paths };

export default ({ app }, inject) => {
    inject('paths', {
        getPath(key) {
            if (paths.url[key]?.[app.i18n.locale]) {
                return paths.url[key][app.i18n.locale];
            }

            return paths.url.landingPage[app.i18n.locale] ?? '/';
        },
        getEndpoint(key) {
            try {
                if (paths.endpoint[key]) {
                    return paths.endpoint[key];
                }
            } catch (e) {
                throw new Error('Endpoint not found');
            }
        },
    });
};
