
import { mapGetters } from 'vuex';

export default {
    name: 'RegisterForm',
    props: {
        fields: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            currentStep: 'registration/getStep',
        }),
        formFields() {
            return {
                title: this.fields?.title,
                description: this.fields?.description,
                store: 'registration',
                action: 'registration/submit',
                fields: [{
                        dataKey: 'email',
                        label: this.$t('registration.fields.email'),
                        validator: ['notEmpty', 'email'],
                    }, {
                        dataKey: 'password',
                        label: this.$t('registration.fields.password'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            { type: 'minLength', value: 6 },
                            { type: 'maxLength', value: 36 },
                        ],
                    }, {
                        dataKey: 'confirmPassword',
                        label: this.$t('registration.fields.confirmPassword'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            {
                                type: 'equals',
                                field: ['registration', 'password'],
                            },
                            { type: 'maxLength', value: 36 },
                        ],
                    }, {
                        dataKey: 'optInNewsletter',
                        type: 'checkbox',
                        options: [
                            { value: true, label: this.$t('registration.fields.newsLetter') },
                        ],
                    }, {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'registerButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('registration.fields.registerButton'),
                    }, {
                        type: 'link',
                        contentType: 'link',
                        title: this.$t('registration.fields.backLink'),
                        cssClass: {
                            registerLink: true,
                        },
                        link: {
                            url: this.$paths.getPath('login'),
                        },
                    },
                ],
            };
        },
    },
};
