import { render, staticRenderFns } from "./CategoryCards.vue?vue&type=template&id=6f21ec53&scoped=true"
import script from "./CategoryCards.vue?vue&type=script&lang=js"
export * from "./CategoryCards.vue?vue&type=script&lang=js"
import style0 from "./CategoryCards.vue?vue&type=style&index=0&id=6f21ec53&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f21ec53",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default,CategoryFilters: require('/build/src/components/blocks/CategoryFilters.vue').default,CategoryProduct: require('/build/src/components/blocks/ProductPage/ProductItems/CategoryTable/CategoryProduct.vue').default,CategoryList: require('/build/src/components/blocks/ProductPage/ProductItems/CategoryTable/CategoryList.vue').default})
