
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
    name: 'CategoryTable',
    data: () => ({
        selected: [],
    }),
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            filter: 'product/getFilter',
            content: 'product/getAvailability',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            personCount: 'product/getPersonCount',
            persons: 'product/getPersons',
            isLoading: 'product/getAvailabilityLoading',
            cartContent: 'cart/getProducts',
            totalsLoading: 'cart/getTotalsLoading',
        }),
        tableHead() {
            return [
                this.$i18n.t('product.classification.activity.date'),
                this.$i18n.t('product.classification.activity.time'),
                this.$i18n.t('product.classification.activity.duration'),
                this.$i18n.t('product.classification.activity.product'),
                this.$i18n.t('product.classification.activity.price'),
                this.$i18n.t('product.classification.activity.free'),
                '',
            ];
        },
        parsedContent() {
            return Object.values(this.content).map(item => {
                switch (item.itemType) {
                    case 'active-hours-product':
                        const time = item.product?.attributes?.active_hour_start_time ? item.product?.attributes?.active_hour_start_time.split(':') : [0, 0];
                        const date = new Date(item.product.attributes.active_hour_date);
                        date.setHours(Number(time[0]));
                        date.setMinutes(Number(time[1]));

                        return {
                            id: this.$util.uuidv4(),
                            type: item.itemType,
                            code: item.product?.code,
                            from: date,
                            image: item.product?.mainImage ? this.$util.parseImage(item.product?.mainImage, 2).url : null,
                            duration: item.product?.attributes?.active_hour_duration,
                            name: item.product?.attributes?.marketing_name,
                            description: item.product?.attributes?.product_description_short,
                            location: item.product?.attributes?.active_hour_location,
                            price: item.price ?? 0,
                            discountAmount: 0,
                            amount: item.amount ?? 1,
                            available: item.availableQuantity,
                            location: item.product?.attributes?.active_hour_location,
                            // original: item,
                        };
                    break;

                    default:
                        return {};
                    break;
                }
            });
        },
    },
    watch: {
        content() {
            this.selected = [];
        },
    },
    methods: {
        ...mapMutations({
            addProduct: 'cart/addProduct',
        }),
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        addProductToCart(item) {
            const additionalData = {
                metadata: {
                    comment: '',
                    amount: item.amount,
                },
                pagePath: this.product.product_pagePath ?? this.product.category_pagePath,
                classification: item?.product?.classification,
            };

            this.addProduct({
                type: item.type,
                productCode: item.code,
                from: DateTime.fromJSDate((new Date(item.from))),
                to: DateTime.fromJSDate(new Date((new Date(item.from)).getTime() + (item.duration * 60 * 1000))),
                image: item.image,
                title: item.name,
                price: item.price,
                location: item.location,
                persons: {
                    ...this.$store.getters['product/getPersons'],
                },
                discountAmount: item.discountAmount,
                priceWithoutTax: 0,
                ...additionalData,
            });

            this.$store.dispatch('cart/getTotals');

            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(item, 'addToCart', additionalData.pagePath)],
                },
            });
        },
        select(id) {
            if (this.selected.indexOf(id) !== -1) {
                this.selected.splice(this.selected.indexOf(id), 1);
            } else {
                this.selected.push(id);
            }
        },
        updateFilter(filter) {
            this.$emit('input', filter);
        },
        getDuration(product) {
            return this.$util.duration(
                DateTime.fromJSDate(new Date(product.from)),
                DateTime.fromJSDate(new Date(product.from)).plus({ minutes: product.duration }),
            );
        },
        isBundle(product) {
            return product.type === 'bundle';
        },
        addBundleProducts(item) {
            if (this.isBundle(item.product) && item.bundledProducts) {
                return item.bundledProducts.map( product => product.product.code);
            }

            return [];
        },
    },
};
