import { render, staticRenderFns } from "./DesktopCalendar.vue?vue&type=template&id=4bee1073&scoped=true"
import script from "./DesktopCalendar.vue?vue&type=script&lang=js"
export * from "./DesktopCalendar.vue?vue&type=script&lang=js"
import style0 from "./DesktopCalendar.vue?vue&type=style&index=0&id=4bee1073&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bee1073",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/build/src/components/elements/Button.vue').default,EventFilters: require('/build/src/components/blocks/EventCalendar/EventFilters.vue').default})
