import { DateTime } from 'luxon';
import { orderBy } from 'lodash';

export const state = () => ({
    loading: false,
    upcomingAvailability: [],
});

export const mutations = {
    setLoading(state, payload) {
        if (!typeof(payload) === 'boolean') {
            throw new Error('setLoading payload is not boolean');
        }
        state.loading = payload;
    },
    setAvailability(state, payload) {
        let data = orderBy(payload, [product => {
            const ts = new Date(product.product?.attributes?.active_hour_date);
            const time = product.product?.attributes?.active_hour_start_time.split(':');

            ts.setHours(time[0]);
            ts.setMinutes(time[1]);

            return ts.getTime();
        }, 'asc']);

        data = data.splice(0, 6);

        state.upcomingAvailability = data;
    },
};

export const actions = {
    async loadAvailability(ctx) {
        ctx.commit('setLoading', true);
        const { code, classification, schema } = ctx.rootGetters['product/getProduct'];

        if (classification !== 'category' && schema !== 'Active hours') {
            return false;
        }

        try {
            const query = {
                categoryCode: code,
                availabilityFilter: {
                    dates: [],
                    numberOfAdults: 1,
                    numberOfChildrenOlder: 0,
                    numberOfChildrenYounger: 0,
                    amount: 1,
                },
            };

            for (let i = 0; i <= 6; i++) {
                const date = DateTime
                    .now()
                    .plus({ day: i })
                    .set(i > 0 ? { hour: 0, minute: 0, second: 0 } : {});

                query.availabilityFilter.dates.push(date.toUTC().toISO());
            }
            const response = await this.$axios.request({
                url: '/proxy/vm-view/v1/product/search',
                method: 'post',
                params: {
                    locale: this.$i18n.locale,
                },
                data: query,
            });

            ctx.commit('setAvailability', response.data);
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e.response);
            }
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('product.message.availabilityFailed'),
            });
        }

        ctx.commit('setLoading', false);
    },
};

export const getters = {
    getLoading: state => state.loading,
    getAvailability: state => state.upcomingAvailability,
};
