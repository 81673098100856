
const maxLengthDefault = 524288;

export default {
    name: 'VMInput',
    props: {
        dataKey: {
            type: [String],
            required: false,
            default: '',
        },
        header: {
            type: [String],
            required: false,
            default: undefined,
        },
        component: {
            type: [String],
            required: false,
            default: 'input',
        },
        type: {
            type: [String],
            required: false,
            default: 'text',
        },
        cssClass: {
            type: [String, Array, Object],
            required: false,
            default: undefined,
        },
        validator: {
            type: [Array],
            required: false,
            default() {
                return [];
            },
        },
        label: {
            type: [String],
            required: false,
            default: 'Label',
        },
        labelPosition: {
            type: [String],
            required: false,
            default: 'inside',
            validator: value => ['inside', 'top'].indexOf(value) !== -1,
        },
        theme: {
            type: [String],
            required: false,
            default: null,
            validator: value => ['white'].indexOf(value) !== -1,
        },
        placeholder: {
            type: [Number, String],
            required: false,
            default: '',
        },
        maxlength: {
            type: [Number, String],
            required: false,
            default: maxLengthDefault,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
        value: {
            type: [Number, String, Boolean],
            required: false,
            default: null,
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        focusSearchInput: {
            type: Boolean,
            required: false,
            default: false,
        },
        commit: {
            type: [Function],
            required: false,
            default: null,
        },
        helpText: {
            type: [String],
            required: false,
            default: null,
        },
        error: {
            type: [String],
            required: false,
            default: null,
        },
        // internal
        timeout: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data: () => ({
        active: false,
    }),
    watch: {
        value(to, from) {
            if (to === '') {
                this.active = false;
            } else {
                this.active = true;
            }
        },
    },
    mounted() {
        if (this.value) {
            this.active = true;
        };

        this.focusInput();
    },
    methods: {
        handleKeyDown(event) {
            switch (event.key) {
                case 'Enter':
                    event.preventDefault();
                    this.$emit('submit');
                break;
            }
        },
        setFocus(event, state) {
            if (this.value) {
                this.active = true;
            } else {
                this.active = state;
            }
        },
        setValue(event) {
            if (event.target.value.length > 0) {
                this.active = true;
            }
            this.$emit('commit', {
                dataKey: this.dataKey,
                value: event.target.value,
            });
        },
        focusInput() {
            if (this.focusSearchInput) {
                this.$refs.input.focus();
            };
        },
    },
};
