import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    step: 1,
    registered: false,
    loading: false,
    fields: {
        /*
        firstName: '',
        lastName: '',
        mobileNumber: '',
        */
        email: '',
        password: '',
        confirmPassword: '',
        optInNewsletter: [],
    },
});

export const mutations = {
    setValues(state, payload) {
        if (typeof(payload) === 'object') {
            Object.keys(payload).forEach(value => {
                if (typeof(payload[value]) === typeof(state[value])) {
                    state[value] = payload[value];
                } else {
                    throw new Error(`Could not set value on store login state ${value}`);
                }
            });
        }
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setRegistrationStatus(state, value) {
        state.registered = value;
    },
    ...storeForm.mutations,
};

export const actions = {
    async passwordRegistration(ctx) {
        if (ctx.state.loading) return;

        /* User store loading is for disabling the login modal form during this
         * API call. */
        this.commit('user/setLoading', true);

        /* Registration store loading is for this API call, user cannot call
         * this api while one call is waiting for a response. */
        this.commit('registration/setLoading', true);

        /* Reset registration status to default on each call, so the login
         * modal form can react to approved registration status change. */
        this.commit('registration/setRegistrationStatus', false);

        let registrationResult = false;

        try {
            const { email, password } = ctx.state.fields;

            await this.$axios.request({
                method: 'post',
                url: '/api/v2/register',
                data: { email, password },
            });

            registrationResult = true;
        } catch (error) {
            const simpleMessage = `status: ${error.response?.status}, message: ${error.response?.data?.message}`;
            const consoleMessage = process.env.NODE_ENV === 'development' ? error : simpleMessage;
            console.error(consoleMessage);

            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('registration.message.failed'),
            });
        } finally {
            this.commit('user/setLoading', false);
            this.commit('registration/setLoading', false);
            this.commit('registration/setRegistrationStatus', registrationResult);
        }
    },

    async submit(ctx) {
        try {
            const values = JSON.parse(JSON.stringify(ctx.state.fields));

            values.optInNewsletter = !!ctx.state.fields.optInNewsletter[0];

            await this.$axios.request({
                method: 'post',
                url: this.$paths.getEndpoint('registration'),
                data: {
                    ...values,
                },
            });
            this.$gtm.push({
                event: 'sign_up',
            });
            this.commit('registration/setValues', {
                step: 2,
            });
            this.dispatch('messages/setMessage', {
                type: 'success',
                message: this.app.i18n.t('registration.message.success'),
            });

            return true;
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            } else {
                console.error(`status: ${e.response?.status}, message: ${e.response?.data?.message}`);
            }

            switch (e.response?.data?.extendedErrorCode) {
                // email exists
                case 5000:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('registration.message.emailFailed'),
                        link: {
                            path: this.app.$paths.getPath('resetPassword'),
                            title: this.app.i18n.t('registration.message.here'),
                        },
                    });
                break;

                // phone exists
                case 5001:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('registration.message.phoneFailed'),
                        link: {
                            path: this.app.$paths.getPath('resetPassword'),
                            title: this.app.i18n.t('registration.message.here'),
                        },
                    });
                break;

                // email & phone exists
                case 5002:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('registration.message.fieldsFailed'),
                        link: {
                            path: this.app.$paths.getPath('resetPassword'),
                            title: this.app.i18n.t('registration.message.here'),
                        },
                    });
                break;

                // all other errors
                default:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('registration.message.failed'),
                    });
                break;
            }

            return false;
        }
    },
    async verifyEmail({ state }) {
        try {
            const { email } = state.fields;

            this.commit('login/setValue', {
                field: 'email',
                value: email,
            });

            const { data } = await this.$axios.request({
                method: 'post',
                url: this.$paths.getEndpoint('verifyEmail'),
                data: {
                    email,
                },
            });
            const { exists } = data.data.account;

            if (exists) {
                this.commit('login/setRedirect', '/checkout');
            }

            return exists;
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }

            return false;
        }
    },
};

export const getters = {
    ...storeForm.getters,
    getRegistrationStatus: state => state.registered,
    getStep: state => state.step,
};
