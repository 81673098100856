
import VSimplebar from 'simplebar-vue';

import { isScreenMinWidth } from '@lamiaoy/breakpoints';
import breakpoints from '@/mixins/breakpoints';
export default {
    components: {
        VSimplebar,
    },
    mixins: [breakpoints],
    props: {
        mainImage: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        images: {
            type: Array,
            required: false,
            default: () => [],
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            slideIndex: 0,
            thumbsPosition: 0,
            translatePath: '',
            isDesktop: true,
            rightArrowPressed: false,
            leftArrowPressed: false,

        };
    },
    computed: {
        computedImages() {
            return this.images.filter(image => image.index === this.slideIndex);
        },
        iconProperties() {
            if (this.isDesktop) {
                return {
                    size: 'xxxl',
                    icon: 'slider-arrow',
                };
            }

            return {
                size: 'xxl',
                icon: 'slider-arrow',
            };
        },
    },
    watch: {
        slideIndex(newVal, oldVal) {
            if (this.isDesktop ? this.images.length > 5 : this.images.length > 6) {
                if (newVal <= 2) {
                    this.thumbsPosition = 0;
                } else if (oldVal < newVal) {
                    this.thumbsPosition += -75;
                } else if (oldVal > newVal) {
                    this.thumbsPosition += 75;
                }
            }
            if (isScreenMinWidth(1200)) {
                this.$refs.thumbnailsSlide.style.transform = `translate(0,${this.thumbsPosition}px)`;
                this.isDesktop = true;
            } else if (this.atMostSmallDesktop) {
                this.$refs.thumbnailsSlide.style.transform = `translate(${this.thumbsPosition}px,0)`;
                this.isDesktop = false;
            }
        },
    },
    mounted() {
        document.addEventListener('click', evt => {
            this.rightArrowPressed = false;
            this.leftArrowPressed = false;
        });

        document.addEventListener('keyup', evt => {
            if (evt.key === 'ArrowRight') {
                this.rightArrowPressed = true;
                this.leftArrowPressed = false;
                this.addSlides(1);
            } else if (evt.key === 'ArrowLeft') {
                this.leftArrowPressed = true;
                this.rightArrowPressed = false;
                this.addSlides(-1);
            }
        });
    },
    methods: {
        handleScroll() {
            this.thumbsPosition = 0;
            if (this.isDesktop) {
                this.$refs.thumbnailsSlide.style.transform = `translateY(${this.thumbsPosition}px)`;
            } else {
                this.$refs.thumbnailsSlide.style.transform = `translateX(${this.thumbsPosition}px)`;
            }
        },
        addSlides(slideNumber) {
            const { images } = this;
            if (this.slideIndex + slideNumber >= images.length) {
                this.slideIndex = 0;
                this.thumbsPosition = 0;

                return;
            }

            if (this.slideIndex + 1 < 1) {
                this.slideIndex = 1;

                return;
            }

            if ((this.slideIndex === 0 && slideNumber === -1) || (this.slideIndex === images.length && slideNumber === 1)) {
                this.slideIndex = images.length - 1;

                return;
            }

            this.slideIndex += slideNumber;
        },
        currentSlide(slideNumber) {
            this.slideIndex = slideNumber;
        },
        isActive(index) {
            return this.slideIndex === index;
        },
    },
};
