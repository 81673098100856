import { DateTime, Duration } from 'luxon';
import StoreForm from '~/util/storeFormExtend';
const netAxeptConfig = require('~/../configuration/netaxept.json');
const storeForm = new StoreForm();

export const state = () => ({
    products: [],
    upgrade: {},
    upsell: [],
    userPristine: true,
    totalsLoading: false,
    finished: false,
    discountCode: '',
    discountSubmitted: false,
    submitLoading: false,
});

export const mutations = {
    ...storeForm.mutations,
    setPayloadValue(state, payload) {
        Object.keys(payload).forEach(key => {
            if (typeof(payload[key]) === typeof(state[key])) {
                if (typeof(payload[key]) === 'object') {
                    Object.assign(state[key], payload[key]);
                } else {
                    state[key] = payload[key];
                }
            } else {
                throw new Error('Could not set value on cart');
            }
        });
    },
    setValueFromStorage(state, payload) {
        switch (payload.key) {
            case 'products':
                // Reload dates
                payload.value.forEach((product, key) => {
                    if (product.productCode !== 'gift_card_open_value') {
                        payload.value[key].from = DateTime.fromISO(payload.value[key].from);
                        payload.value[key].to = DateTime.fromISO(payload.value[key].to);
                    }
                });
                state[payload.key] = payload.value;
            break;
            default:
                state[payload.key] = payload.value;
            break;
        }
    },
    setDiscountSubmitted(state, payload) {
        state.discountSubmitted = payload;
        this.commit('cart/storeCart');
    },
    setDiscountCode(state, payload) {
        state.discountCode = payload;
        this.commit('cart/storeCart');
    },
    setPersonName(state, payload) {
        const valid = [];
        state.products[payload.key].personNames[payload.value.itemKey][payload.value.dataKey] = payload.value.value;
        state.products[payload.key].personNames.forEach(values => {
            if (values.firstName && values.lastName) {
                valid.push(true);
            } else {
                valid.push(false);
            }
        });
        if (valid.reduce((a, b) => a + b, 0) === state.products[payload.key].personNames.length) {
            state.products[payload.key].valid = true;
        } else {
            state.products[payload.key].valid = false;
        }
        this.commit('cart/storeCart');
    },
    setOther(state, payload) {
        if (!state.products[payload.key].metadata.comment) {
            state.products[payload.key].metadata.comment = '';
        }
        state.products[payload.key].metadata.comment = payload.value;
    },
    setTotalsLoading(state, payload) {
        state.totalsLoading = payload;
    },
    addProduct(state, payload) {
        const amount = payload.metadata?.amount ?? 1;

        const prices = {
            total: 0,
            noTax: 0,
            tax: 0,
        };
        payload.valid = ['giftcard', 'Activity', 'active-hours-product', 'Education_purchase'].indexOf(payload.type) !== -1;
        payload.personNames = [];

        if (payload.persons) {
            this.$util.personsToCount(payload.persons);

            for (let i = 0; i < this.$util.personsToCount(payload.persons); i++) {
                payload.personNames.push({
                    firstName: '',
                    lastName: '',
                });
            }
        }

        payload.metadata = Object.assign(payload.metadata ?? {}, {
            amount: 1,
            comment: '',
        });

        for (let i = 0; i < amount; i++) {
            state.products.push({
                dataKey: this.$util.uuidv4(),
                ...payload,
            });
        }
        this.commit('cart/storeCart');
        this.dispatch('messages/setMessage', {
            type: 'success',
            message: this.app.i18n.t('cart.message.productAdded'),
        });
    },
    async removeProduct(state, key) {
        const item = state.products.find(v => v.dataKey === key);
        state.products.splice(state.products.indexOf(item), 1);
        this.commit('cart/storeCart');
        await this.dispatch('cart/getTotals');
        await this.dispatch('product/loadAvailability');
    },
    updateProducts(state, payload) {
        const idPool = payload.map(product => product.id);
        // Remove loop
        state.products.forEach((product, key) => {
            if (idPool.indexOf(product.dataKey) === -1) {
                state.products[key].unavailable = true;
            } else if (state.products[key].unavailable) {
                delete state.products[key].unavailable;
            }

            return product;
        });
        // Update loop
        state.products.forEach((product, key) => {
            if (product.productCode !== 'gift_card_open_value') {
                const current = payload.find(v => v.id === product.dataKey);
                if (current) {
                    state.products[key].price = current.price.withTax;
                    state.products[key].priceWithoutTax = current.price.withoutTax;
                    state.products[key].discountAmount = current.price.discountAmount;
                }
            }
        });
    },
    upgradeProduct(state, payload) {
        const original = state.products.find(v => v.dataKey === payload.id);
        const item = payload.product;

        state.products.splice(state.products.indexOf(original), 1);
        this.commit('cart/addProduct', {
            type: item.classification,
            productCode: item.code,
            image: original.image,
            icon: item.attributes.right_of_cancellation ? 'GREEN_TICK' : 'RED-TICK',
            title: item.name,
            from: original.from,
            to: original.to,
            persons: original.persons,
            price: payload.price.withTax,
            discountAmount: payload.price.discountAmount,
            priceWithoutTax: payload.price.withoutTax,
        });
        this.dispatch('cart/getTotals');
    },
    storeCart(state) {
        const oneDay = Duration.fromObject({ days: 1 }).toMillis();
        this.$storage.setUniversal('cart', {
            products: state.products,
            discountCode: state.discountCode,
            discountSubmitted: state.discountSubmitted,
            submittedDate: DateTime.now(),
        });

        setInterval(function () {
            if (state.discountSubmitted) {
                state.discountCode = '';
                state.discountSubmitted = false;
                this.$storage.setUniversal('cart', {
                    products: state.products,
                    discountCode: state.discountCode,
                    discountSubmitted: state.discountSubmitted,
                });
            }
        }, oneDay);
    },
    setUpsell(state, payload) {
        if (typeof(payload) === 'object') {
            const items = [];
            Object.values(payload).forEach(item => {
                items.push({
                    discount: item.discountPercentage,
                    url: item.product?.pagePath,
                    image: this.$util.parseImage(item.product?.mainImage, 1).url,
                    title: item.product?.name,
                    source: item.sourceProductCode,
                    description: item.product?.attributes?.product_description_minicart,
                });
            });
            state.upsell = items;
        } else {
            state.upsell = [];
        }
    },
    setUpgrade(state, payload) {
        state.upgrade = {};
        payload.forEach(v => {
            if (v.upgradeSuggestions) {
                state.upgrade[v.id] = v.upgradeSuggestions;
            }
        });
    },
    setFinished(state, value) {
        state.finished = value;
    },
    clear(state) {
        state.products = [];
        state.discountCode = '';
        state.discountSubmitted = false;
        this.$storage.removeUniversal('cart');
    },
    setSubmitLoading(state, value) {
        state.submitLoading = value;
    },
};

export const actions = {
    async getTotals(ctx) {
        this.commit('cart/setTotalsLoading', true);
        try {
            if (ctx.state.products.length) {
                const query = {
                    items: [],
                };
                ctx.state.products.forEach(product => {
                    if (product.productCode === 'gift_card_open_value') {
                        query.items.push({
                            id: product.dataKey,
                            productCode: product.productCode,
                            metadata: product.metadata,
                        });
                    } else if (product.type === 'Education_purchase') {
                        query.items.push({
                            id: product.dataKey,
                            productCode: product.productCode,
                            metadata: {
                                dates: [product.from.toISO(), product.to.toISO()],
                            },
                        });
                    } else if (product.type !== 'Activity' && product.type !== 'active-hours-product') {
                        query.items.push({
                            id: product.dataKey,
                            productCode: product.productCode,
                            bundledProductCodes: product.bundledProductCodes ?? [],
                            metadata: {
                                dates: [product.from.toISO(), product.to.toISO()],
                                guests: this.$util.expandPersonsToGuests(product.persons),
                            },
                        });
                    } else if (product.type === 'Activity' || product.type === 'active-hours-product') {
                        query.items.push({
                            id: product.dataKey,
                            productCode: product.productCode,
                            bundledProductCodes: product.bundledProductCodes ?? [],
                            metadata: {
                                dateTime: product.from.toISO(),
                                ...Object.assign({
                                    guests: product.persons ? this.$util.expandPersonsToGuests(product.persons) : [],
                                    amount: 1,
                                }, product.metadata),
                            },
                        });
                    }
                });

                if (ctx.state.discountSubmitted) {
                    query.promotionCode = ctx.state.discountCode;
                }

                const response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-view/v2/product/totals',
                    data: query,
                });
                this.commit('cart/updateProducts', response.data?.items);
                this.commit('cart/setUpsell', response.data?.upsellSuggestions);
                this.commit('cart/setUpgrade', response.data?.items);
            } else {
                this.commit('cart/setUpsell', []);
            }
        } catch (e) {
            console.error(e);
            console.error(e.response);
        }
        this.commit('cart/setTotalsLoading', false);
    },
    async submit(ctx) {
        try {
            this.commit('cart/setSubmitLoading', true);

            const query = {
                redirectBaseUrl: `${netAxeptConfig.host}${this.app.$paths.getPath('checkout')}`,
                paymentTerminalLanguage: this.app.i18n.locale,
                items: [],
            };
            ctx.state.products.forEach(v => {
                const current = {
                    productCode: v.productCode,
                };

                // Set bundles
                if (v.bundledProductCodes) {
                    current.bundledProductCodes = v.bundledProductCodes;
                }

                // Set giftcard meta or set comment for others
                if (v.productCode === 'gift_card_open_value') {
                    current.metadata = v.metadata;
                } else if (v.type === 'Accommodation' || v.type === 'variation' || v.type === 'Course') {
                        current.metadata = v.metadata || {};
                        // Set timebased values to metadata
                        if (v.from && v.to) {
                            const from = new Date(v.from);
                            const to = new Date(v.to);
                            current.metadata.dates = [from, to];
                        }
                        // Set person data
                        if (v.persons) {
                            const currentPersons = [];
                            let personKey = 0;
                            Object.keys(v.persons).forEach(key => {
                                let type;
                                for (let i = 0; i < v.persons[key]; i++) {
                                    switch (key) {
                                        case 'numberOfChildrenYounger':
                                            type = 'child_younger';
                                        break;

                                        case 'numberOfChildrenOlder':
                                            type = 'child_older';
                                        break;

                                        default:
                                        case 'numberOfAdults':
                                            type = 'adult';
                                        break;
                                    };
                                    currentPersons.push({
                                        type,
                                        ...v.personNames[personKey],
                                    });
                                    personKey = personKey + 1;
                                };
                            });
                            current.metadata.guests = currentPersons;
                        }
                    } else if (v.type === 'Activity') {

                        current.metadata = Object.assign({
                            amount: 1,
                            dateTime: v.from.toISO(),
                        }, v.metadata);
                    } else if (v.type === 'active-hours-product') {
                        current.metadata = Object.assign({
                            amount: 1,
                            dateTime: v.from.toISO(),
                        }, v.metadata);
                    }

                query.items.push(current);
            });

            if (ctx.state.discountSubmitted) {
                query.promotionCode = ctx.state.discountCode;
            }

            // Add unregistered user data if applicable
            if (!ctx.rootGetters['user/getLoggedIn']) {
                const {
                    firstName,
                    lastName,
                    email,
                    mobileNumber,
                    streetAddress,
                    zipCode,
                    city,
                    marketingEmail,
                    register,
                } = ctx.rootGetters['unregistered/getUserData'];

                query.contactDetails = {
                    firstName,
                    lastName,
                    email,
                    phoneNumber: mobileNumber,
                    address: streetAddress,
                    zipCode,
                    city,
                };

                if (marketingEmail.indexOf(true) !== -1) {
                    const newsLetter = await this.$axios.request({
                        method: 'post',
                        url: '/proxy/vm-communication/v1/newsletter/subscribe',
                        data: {
                            firstName,
                            lastName,
                            email,
                        },
                    });
                }

                if (register.indexOf(true) !== -1) {
                    const register = await this.$axios.request({
                        method: 'post',
                        url: '/proxy/vm-customer/v2/registerWithoutPassword',
                        data: {
                            email,
                            firstName,
                            lastName,
                            phone: mobileNumber,
                            address: streetAddress,
                            zip: zipCode,
                            city,
                        },
                    });
                }
            } else if (!ctx.state.userPristine) {
                await this.dispatch('user/submit');
                this.commit('cart/setPayloadValue', { userPristine: true });
            }
            const response = await this.$axios.request({
                method: 'post',
                url: '/proxy/vm-shopping/v2/order/submit',
                data: query,
            });

            // Set purhcase flag for GTM purchase event before going to payment terminal
            if (response?.data?.paymentTerminalUrl) {
                this.$storage.setUniversal('purchaseFlag', true);
            }

            window.location.href = response.data.paymentTerminalUrl;
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }

            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('checkout.message.submitFailed'),
            });

            this.commit('cart/setSubmitLoading', false);
            this.$storage.removeUniversal('purchaseFlag');
        }
    },
};

export const getters = {
    ...storeForm.getters,
    getProducts: state => state.products,
    getPrices: state => {
        const prices = {
            total: 0,
            noTax: 0,
            tax: 0,
        };

        state.products.forEach(product => {
            prices.total = prices.total + product.price;
            prices.noTax = prices.noTax + product.priceWithoutTax;
            prices.tax = prices.tax + (prices.total - prices.noTax);
        });

        return prices;
    },
    getTotalsLoading: state => state.totalsLoading,
    getUpsell: state => state.upsell,
    getUpgrades: state => state.upgrade,
    getFinished: state => state.finished,
    getDiscountCode: state => state.discountCode,
    getDiscountSubmitted: state => state.discountSubmitted,
    getSubmitLoading: state => state.submitLoading,
    getProductsUnavailable: state => state.products.filter(product => product.unavailable).length > 0,
    containsGiftcard: state => state.products.filter(v => v.productCode === 'gift_card_open_value').length > 0,
    containsNonGiftcards: state => state.products.filter(v => v.productCode !== 'gift_card_open_value').length > 0,
};
