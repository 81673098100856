import httpStatus from 'http-status';
import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    locked: false,
    step: 1,
    token: '',
    email: '',
    service: 'gcp',
    fields: {
        email: '',
        newPassword: '',
        confirmPassword: '',
    },
});

export const mutations = {
    setPayload(state, payload) {
        if (typeof(payload) === 'object') {
            Object.keys(payload).forEach(key => {
                if (typeof(state[key]) === typeof(payload[key])) state[key] = payload[key];
            });
        }
    },
    setStep(state, value) {
        state.step = value;
    },
    setToken(state, value) {
        state.token = value;
    },
    setLocked(state, value) {
        state.locked = value;
    },
    reset(state) {
        state.step = 1;
        state.locked = false;
        state.token = null;
        state.fields = {
            email: '',
            newPassword: '',
            confirmPassword: '',
        };
    },
    ...storeForm.mutations,
};

export const actions = {
    async submitStep1(ctx) {
        this.commit('resetPassword/setLocked', true);
        try {
            const { email } = ctx.state.fields;
            let response;

            if (ctx.state.service === 'gcp') {
                response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-customer/v2/password/request-reset',
                    data: {
                        email,
                    },
                });
            } else if (ctx.state.service === 'legacy') {
                response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-customer/v1/password/request-reset',
                    data: {
                        email: ctx.state.fields.email,
                        language: this.$i18n.locale,
                    },
                });
            } else {
                throw new Error('Service is not set');
            }

            if (response.status === httpStatus.NO_CONTENT) {
                this.commit('resetPassword/setStep', 2);
            }
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('resetPassword.message.emailFailed'),
            });
        }
        this.commit('resetPassword/setLocked', false);
    },
    async submit(ctx) {
        this.commit('resetPassword/setLocked', true);
        try {
            const { service } = ctx.state;
            if (service === 'gcp') {
                const { token: code } = ctx.state;
                const { newPassword: password } = ctx.state.fields;

                const response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-customer/v2/password/reset',
                    data: {
                        code,
                        password,
                    },
                });
                this.dispatch('messages/setMessage', {
                    type: 'success',
                    message: this.app.i18n.t('resetPassword.message.passwordSuccess'),
                });
                this.$router.push(this.$paths.getPath('login'));
            } else if (service === 'legacy') {
                const response = await this.$axios.request({
                    method: 'post',
                    url: '/proxy/vm-customer/v1/password/reset',
                    data: {
                        email: ctx.state.fields.email,
                        token: ctx.state.token,
                        password: ctx.state.fields.newPassword,
                    },
                });
                this.dispatch('messages/setMessage', {
                    type: 'success',
                    message: this.app.i18n.t('resetPassword.message.passwordSuccess'),
                });
                this.$router.push(this.$paths.getPath('login'));
            }
            this.commit('resetPassword/reset');
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('resetPassword.message.passwordFailed'),
            });
        }
        this.commit('resetPassword/setLocked', false);
    },
};

export const getters = {
    ...storeForm.getters,
    getLocked: state => state.locked,
    getStep: state => state.step,
};
