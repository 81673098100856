import httpStatus from 'http-status';
import * as siteSettings from '~config/site.json';

export default ({ req, store, redirect }) => {
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

    if (process.server) {
        const { host } = req.headers;
        const domain = host.match(/\:[0-9]+$/) ? host.split(':')[0] : host;

        if (domain === 'omistajapalvelu.vierumaki.fi') {
            const isLoggedIn = store.getters['user/getLoggedIn'];

            if (isLoggedIn) {
                redirect(
                    httpStatus.TEMPORARY_REDIRECT,
                    `${siteSettings.domain}/homeowner`,
                );
            } else {
                redirect(
                    httpStatus.TEMPORARY_REDIRECT,
                    `${siteSettings.domain}`,
                );
            }
        }

        if (req.originalUrl.match(/.+\/$/) || req.headers.host.match(/^www\./)) {
            redirect(
                httpStatus.MOVED_PERMANENTLY,
                `${protocol}://${req.headers.host.replace(/^www\./, '')}${req.originalUrl.replace(/\/$/, '')}`,
            );
        }
    }
};
