
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
    name: 'ProductTable',
    props: {
        update: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            filter: 'product/getFilter',
            content: 'product/getAvailability',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            personCount: 'product/getPersonCount',
            persons: 'product/getPersons',
            isLoading: 'product/getAvailabilityLoading',
            cartContent: 'cart/getProducts',
            totalsLoading: 'cart/getTotalsLoading',
            selectingFilters: 'category/getSelectingFilters',
        }),
        showList() {
            return this.content.length > 0 && !this.isLoading && !this.selectingFilters && !this.update;
        },
        mainTitle() {
            return this.product.marketing_name ?? '';
        },
        sortedContents() {
            const contents = this.content;
            const availableContents = contents.filter(content => content[0]?.available === true);
            const unAvailableContent = contents.filter(content => content[0]?.available === false);
            const sortedAvailableContent = availableContents.sort((a, b) => (a[0] ? a[0].price : a[1].price) - (b[0] ? b[0].price : b[1].price));

            return [...sortedAvailableContent, ...unAvailableContent];
        },
        tableHead() {
            switch (this.product.classification) {
                case 'Activity':
                    return [
                        this.$i18n.t('product.classification.activity.date'),
                        this.$i18n.t('product.classification.activity.time'),
                        this.$i18n.t('product.classification.activity.duration'),
                        this.$i18n.t('product.classification.activity.product'),
                        this.$i18n.t('product.classification.activity.price'),
                        this.$i18n.t('product.classification.activity.free'),
                        '',
                    ];
                    break;

                default:
                case 'Course':
                    return [
                        '',
                        this.$i18n.t('product.classification.course.course'),
                        this.$i18n.t('product.classification.course.dates'),
                        this.$i18n.t('product.classification.course.places'),
                        this.$i18n.t('product.classification.course.total'),
                    ];
                    break;


            }
        },
        isHotel() {
            return this.product.classification === 'Accommodation';
        },
        isFennada() {
            return this.product.classification === 'Accommodation' && this.product.code === 'fennada';
        },
    },
    methods: {
        ...mapMutations({
            addProduct: 'cart/addProduct',
        }),
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        addProductToCart(item) {
            const amountToCart = item.amount ?? 1;
            const additionalData = {
                metadata: {
                    comment: '',
                },
                pagePath: this.product.product_pagePath,
                classification: item?.product?.classification,
            };

            switch (item.product.classification) {
                case 'Course':
                    additionalData.from = DateTime.fromISO(item.dateFrom);
                    additionalData.to = DateTime.fromISO(item.dateTo);
                    additionalData.persons = this.persons;
                    break;

                case 'Accommodation':
                    additionalData.from = this.fromDate;
                    additionalData.to = this.toDate;
                    additionalData.persons = this.persons;
                    break;

                case 'Activity':
                    additionalData.from = DateTime.fromISO(item.dateTime);
                    // set to from duration
                    if (item.product?.attributes?.duration_min) {
                        additionalData.to = DateTime.fromISO(item.dateTime).plus({ minutes: item.product?.attributes?.duration_min });
                        // fallback
                    } else {
                        additionalData.to = new Date(item.dateTime);
                    }

                    if (item.amount) {
                        additionalData.metadata.amount = item.amount;
                    }

                    ['resource1', 'resource2'].forEach(v => {
                        if (item[v] && item[v].code) {
                            additionalData.metadata[v] = item[v].code;
                        }
                    });
                    break;
            }

            this.addProduct({
                type: item.product.classification,
                productCode: item.product.code,
                image: this.product.images[0] && this.product.images[0].url ? this.product.images[0].url : null,
                title: this.productName(item),
                price: item.price,
                discountAmount: item.discountAmount,
                priceWithoutTax: 0,
                bundledProductCodes: this.addBundleProducts(item),
                ...additionalData,
            });

            this.$store.dispatch('cart/getTotals');

            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(item, 'addToCart', additionalData.pagePath)],
                },
            });
        },
        updateFilter(filter) {
            this.$emit('input', filter);
        },
        isBuyable(item) {
            return item.availableQuantity > 0;
        },
        formatPrice(price) {
            return `${price.toFixed(0)} €`;
        },
        isBundle(product) {
            return product.type === 'bundle';
        },
        addBundleProducts(item) {
            if (this.isBundle(item.product) && item.bundledProducts) {
                return item.bundledProducts.map(product => product.code);
            }

            return [];
        },
        getDuration(product) {
            return this.$util.duration(
                DateTime.fromISO(product.dateTime),
                DateTime.fromISO(product.dateTime).plus({ minutes: product?.product?.attributes?.duration_min }),
            );
        },
        productName(item) {
            if (this.isBundle(item.product) && item.bundledProducts) {
                const bundleNames = item.bundledProducts.map(product => product?.attributes?.marketing_name);

                return bundleNames.join(' + ');
            }

            return item.product?.attributes?.marketing_name;
        },
    },
};
