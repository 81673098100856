
    export default {
        name: 'NewsletterForm',
        props: {
            fields: {
                type: Object,
                required: false,
                default: () => ({}),
            },
        },
        computed: {
            formFields() {
                return {
                    title: this.fields?.title,
                    description: this.fields?.description,
                    store: 'newsletter',
                    action: 'newsletter/submit',
                    fields: [{
                        dataKey: 'firstName',
                        label: this.$t('newsletter.fields.firstName'),
                        validator: ['notEmpty'],
                    }, {
                        dataKey: 'lastName',
                        label: this.$t('newsletter.fields.lastName'),
                        validator: ['notEmpty'],
                    }, {
                        dataKey: 'email',
                        label: this.$t('newsletter.fields.email'),
                        validator: ['notEmpty', 'email'],
                    },
                    {
                        dataKey: 'b2b',
                        type: 'checkbox',
                        options: [
                            { value: true, label: this.$t('newsletter.fields.companyNewsletter') },
                        ],
                    },
                    {
                        dataKey: 'terms',
                        type: 'checkbox',
                        options: [
                            { value: true, label: this.$t('newsletter.fields.terms') },
                        ],
                        validator: ['notEmpty'],
                        cssClass: {
                            col2: true,
                        },
                    },
                    {
                        type: 'link',
                        contentType: 'link',
                        title: this.$t('loginModal.fields.termsLink'),
                        cssClass: {
                            'col2': true,
                            'justify-end': true,
                        },
                        link: {
                            url: '/tietosuoja',
                        },
                    },
                    {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('accessibility.fields.send'),
                    },
                    ],
                };
            },
        },
    };
