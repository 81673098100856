
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        data() {
            return {
                min: 0,
                max: 0,
                sliderDebounce: null,
            };
        },
        computed: {
            ...mapGetters({
                storeMin: 'category/getPriceFilterMin',
                storeMax: 'category/getPriceFilterMax',
                minInit: 'category/getPriceInitMin',
                maxInit: 'category/getPriceInitMax',
                priceReset: 'category/getPriceReset',
                categoryItems: 'category/getCategoryItems',
            }),
        },
        watch: {
            categoryItems: {
                handler(newValue) {
                    if (newValue) {
                        this.min = this.storeMin;
                        this.max = this.storeMax;
                    }
                },
            },
            priceReset: {
                handler(newValue) {
                    if (newValue) {
                        this.min = this.storeMin;
                        this.max = this.storeMax;
                    }
                },
            },
        },
        mounted() {
            this.min = this.storeMin;
            this.max = this.storeMax;
        },
        methods: {
            ...mapMutations({
                setPriceFilters: 'category/setPriceFilters',
            }),
            rangeChange(value, type) {
                if (this.sliderDebounce) {
                    window.clearTimeout(this.sliderDebounce);
                }

                // Debounce setting prices to state to filter the list
                this.sliderDebounce = window.setTimeout(() => {
                    if (type === 'min') {
                        // If larger than max, set as the new max value in state
                        if (parseFloat(value) > parseFloat(this.max)) {
                            this.setPriceFilters({ min: this.max, max: value });
                        } else {
                            this.setPriceFilters({ min: value, max: this.max });
                        }
                    } else if (type === 'max') {
                        // If smaller than min, set as the new min value in state
                        if (parseFloat(value) < parseFloat(this.min)) {
                            this.setPriceFilters({ min: value, max: this.min });
                        } else {
                            this.setPriceFilters({ min: this.min, max: value });
                        }
                    }
                }, 50);
            },
        },
    };
