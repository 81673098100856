
import { mapMutations, mapGetters } from 'vuex';

export default {
    name: 'Footer',
    computed: {
        ...mapGetters({
            content: 'navigation/getFooter',
        }),
        column1Content() {
            return this.content?.column1?.map(item => ({
                ...item,
                appearance: 'link',
            }));
        },
        column2Content() {
            return this.content?.column2?.map(item => ({
                ...item,
                appearance: 'link',
            }));
        },
    },
    methods: {
        ...mapMutations({
            setCookieConsentActive: 'navigation/setCookieConsentActive',
        }),
        openCookies() {
            this.setCookieConsentActive(true);
        },
    },
};
