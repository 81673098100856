
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Notifications',
    computed: {
        ...mapGetters({
            messages: 'messages/messages',
        }),
        notifications() {
            return [...this.messages];
        },
    },
    watch: {
        messages(to, from) {
            if (to.length > 0 && to[0].message) {
                this.$announcer.set(to[0].message);
            }
        },
    },
    methods: {
        ...mapMutations({
            removeMessage: 'messages/removeMessage',
        }),
        iconCheck(type) {
            if (type === 'info') {
                return 'info';
            } else if (type === 'warning') {
                return 'info';
            } else if (type === 'error') {
                return 'error';
            }

            return 'checkmark-circle-empty';
        },
    },
};
