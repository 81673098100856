
    export default {
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            articleTitle: {
                type: String,
                required: false,
                default: '',
            },
            tags: {
                type: Array,
                required: false,
                default: () => [],
            },
            createdAt: {
                type: String,
                required: false,
                default: '',
            },
            updatedAt: {
                type: String,
                required: false,
                default: '',
            },
            shortDescription: {
                type: String,
                required: false,
                default: '',
            },
            content: {
                type: [Object, Boolean],
                required: false,
                default: false,
            },
            image: {
                src: {
                    type: String,
                    required: true,
                    default: '',
                },
                alt: {
                    type: String,
                    required: false,
                    default: 'Image',
                },
                type: [Object, Boolean],
                required: false,
                default: false,
            },
        },
        data() {
            return {};
        },
        computed: {
            hasContent() {
                return this.content;
            },
            hasTags() {
                return this.tags?.length;
            },
        },
        methods: {},
    };
