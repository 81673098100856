
import { mapGetters, mapMutations } from 'vuex';
import VSimplebar from 'simplebar-vue';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'MegaMenu',
    components: {
        VSimplebar,
    },
    mixins: [breakpoints],
    props: {
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data: () => ({
        id: null,
        hoverBar: false,
        hoverMenu: false,
        enterTimeout: null,
        closeTimeout: null,
    }),
    computed: {
        ...mapGetters({
            menuItems: 'navigation/getNavTwo',
            navTwoActive: 'navigation/getNavTwoActive',
        }),
    },
    created() {
        this.id = new Date().getTime();
    },
    mounted() {
        window.addEventListener('resize', this.windowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        ...mapMutations({
            setMmActive: 'navigation/setNavTwoActive',
            setActivePage: 'navigation/setActivePage',
            setActivePortal: 'navigation/setActivePortal',
        }),
        handleMouseOver(item, values) {
            this.setActivePortal(`mega-menu-content-${this.id}`);
            this[`hover${item}`] = true;
            window.clearTimeout(this.closeTimeout);
            if (values && values.length) {
                this.enterTimeout = window.setTimeout(() => {
                    this.setActive(values);
                }, 600);
            };
        },
        handleEnter(values, url) {
            if (url) {
                this.setActivePage(values);
                this.$router.push(url);
                this.setMmActive([]);
            } else {
                const active = this.navTwoActive ?? [];
                this.setActivePortal(`mega-menu-content-${this.id}`);
                if (active[0] === values[0] && values.length === 1) {
                    this.setActive([]);
                } else if (active[1] === values[1] && values.length === 2) {
                    this.setActive([values[0]]);
                } else if (active[2] === values[2] && values.length === 3) {
                    this.setActive([values[0], values[1]]);
                } else {
                    this.setActive(values);
                }
            }
        },
        handleTab(event, values, url) {
            if (this.navTwoActive.length > 0 && !event.shiftKey && !url) {
                const element = document.querySelector(`ul.list-level1-${values[0]} li:first-child a`);
                if (element) {
                    event.preventDefault();
                    element.focus();
                }
            }
        },
        handleTabInMenu(event, index, values) {
            const current = this.navTwoActive ?? [];

            // moving up
            if (event.shiftKey) {
                if (index === 0) {
                    event.preventDefault();
                    switch (values.length) {
                        case 4:
                            document.querySelector(`a.list-level2-item-${values[2]}`).focus();
                        break;
                        case 3:
                            document.querySelector(`a.list-level1-item-${values[1]}`).focus();
                        break;

                        case 2:
                            document.querySelector(`.nav-two .mega-menu-list li.list-level0-${values[0]}`).focus();
                        break;
                    }
                }
            // moving down
            } else if (values.length === 2 && current[1] === values[1]) {
                const element = document.querySelector(`ul.list-level2-${values[1]} li:first-child a`);
                if (element) {
                    event.preventDefault();
                    element.focus();
                }
            } else if (values.length === 3 && current[2] === values[2]) {
                const element = document.querySelector(`ul.list-level3-${values[2]} li:first-child a`);
                if (element) {
                    event.preventDefault();
                    element.focus();
                }
            } else if (event.target.classList.contains('last')) {
                const element = document.querySelector(`.nav-two .mega-menu-list li.list-level0-${values[0]}`);
                event.preventDefault();
                this.setActive([]);
                element.focus();
            }
        },
        handleMouseOut(item) {
            window.clearTimeout(this.enterTimeout);
            this[`hover${item}`] = false;
            if (!this.hoverBar && !this.hoverMenu) {
                this.closeTimeout = window.setTimeout(() => {
                    this.setActive([]);
                }, 600);
            }
        },
        windowResize() {
            if (this.atMostTablet) {
                this.setActive([]);
            }
        },
        setActive(payload, url) {
            if (url) {
                this.setActivePage(payload);
                this.$router.push(url);
                this.setMmActive([]);
            } else {
                this.setMmActive(payload);
            }
        },
        getActiveItem() {
            if (this.navTwoActive.length > 0) {
                return [this.menuItems.find(v => v.id === this.navTwoActive[0])];
            }

            return [];
        },
        getChildren(items) {
            return items.filter(item => item.children);
        },
        isDisabled(item) {
            return typeof(item.children) !== 'object' && !item.url;
        },
    },
};
