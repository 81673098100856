
    export default {
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            boxes: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {},
        methods: {},
    };
