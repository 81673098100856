import { render, staticRenderFns } from "./SearchResults.vue?vue&type=template&id=294e52e8&scoped=true"
import script from "./SearchResults.vue?vue&type=script&lang=js"
export * from "./SearchResults.vue?vue&type=script&lang=js"
import style0 from "./SearchResults.vue?vue&type=style&index=0&id=294e52e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294e52e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActivityIndicator: require('/build/src/components/elements/ActivityIndicator.vue').default,Pagination: require('/build/src/components/elements/Pagination.vue').default})
