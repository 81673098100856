import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    fields: {
        search: '',
    },
    offset: 0,
    total: 0,
    pageSize: 10,
    lockedTerm: '',
    products: [],
    results: [],
    loading: false,
});

export const mutations = {
    ...storeForm.mutations,
    setLockedTerm(state, argument) {
        state.lockedTerm = argument;
    },
    setOffset(state, offset) {
        state.offset = offset;
    },
    setTotal(state, count) {
        state.total = count;
    },
    setProducts(state, products) {
        state.products = products;
    },
    setResults(state, results) {
        state.results = results;
    },
};

export const actions = {
    async submit(ctx) {
        ctx.commit('loading');
        try {
            const values = ctx.state.fields;

            // reset offset when search term changes
            if (values.search !== ctx.state.lockedTerm) {
                ctx.commit('setOffset', 0);
            }

            if (values.search.length > 0) {
                // set products
                const productResults = await this.$axios.request({
                    method: 'get',
                    url: '/proxy/vm-view/v2/search/product-suggestions',
                    params: {
                        searchTerm: values.search,
                    },
                });
                ctx.commit('setProducts', productResults.data);

                // set results
                const searchResults = await this.$axios.request({
                    method: 'get',
                    url: '/proxy/vm-view/v2/search/content',
                    params: {
                        searchTerm: values.search,
                        offset: ctx.state.offset * ctx.state.pageSize,
                    },
                });
                ctx.commit('setLockedTerm', values.search);
                ctx.commit('setResults', searchResults.data.results ?? []);
                ctx.commit('setTotal', searchResults.data.totalResults ?? 0);
            } else {
                ctx.commit('setTotal', 0);
                ctx.commit('setProducts', []);
                ctx.commit('setResults', []);
            }
        } catch (e) {
            console.error(e);
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('login.message.failed'),
            });
        }
        ctx.commit('loading');
    },
};

export const getters = {
    ...storeForm.getters,
    loading: state => state.loading,
    searchTerm: state => state.lockedTerm,
    pageSize: state => state.pageSize,
    offset: state => state.offset,
    total: state => state.total,
    products: state => state.products,
    results: state => state.results,
};
