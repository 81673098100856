
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'DateSelect',
    directives: {
        clickOutside,
    },
    props: {
        labels: {
            type: Object,
            required: false,
            default: () => ({
                from: 'From',
                to: 'To',
            }),
        },
        type: {
            type: [String],
            required: false,
            default: 'range',
        },
        value: {
            type: Object,
            required: false,
            default: () => ({
                start: new Date(),
                end: new Date(DateTime.now().plus({ weeks: 1 })
.toString()),
            }),
        },
        availableDates: {
            type: Array,
            required: false,
            default: undefined,
        },
        accommodationSchema: {
            type: Boolean,
            required: false,
            default: false,
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        commit: {
            type: String,
            required: false,
            default: null,
        },
        backgroundWhite: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        isToOpen: false,
        isFromOpen: false,
    }),
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
        from: {
            get() {
                return this.value.start;
            },
            set(value) {
                const luxonValue = DateTime.fromJSDate(value);
                let endDate;
                if (luxonValue.startOf('day') >= this.value.end.startOf('day')) {
                    if (this.isAvailableDate(DateTime.fromJSDate(value).plus({ days: 1 }))) {
                        endDate = DateTime.fromJSDate(value).plus({ days: 1 })
                            .set({ hours: 23, minutes: 59, seconds: 59 });
                        this.$refs.toCalendar.updateValue(endDate.toJSDate());
                        this.$refs.toCalendar.focusDate(endDate.toJSDate());
                    } else {
                        endDate = DateTime.fromJSDate(value)
                            .set({ hours: 23, minutes: 59, seconds: 59 });
                        this.$refs.toCalendar.updateValue(endDate.toJSDate());
                        this.$refs.toCalendar.focusDate(endDate.toJSDate());
                    }
                };
                this.$emit('input', {
                    start: luxonValue,
                    end: endDate ? endDate : this.value.end,
                });
                this.isFromOpen = false;
            },
        },
        to: {
            get() {
                return this.value.end;
            },
            set(value) {
                if ((new Date(value)).getTime() >= this.value.start.toMillis()) {
                    this.$emit('input', {
                        start: this.value.start,
                        end: DateTime.fromJSDate(value),
                    });
                    this.isToOpen = false;
                }
            },
        },
        getFromMinDate() {
            if (typeof(this.availableDates) === 'object') {
                return undefined;
            }

            return new Date();
        },
        getToMinDate() {
            if (typeof(this.availableDates) === 'object') {
                return undefined;
            }

            return this.from.plus({ day: 1 }).toString();
        },
    },
    methods: {
        handleFocus(event, element) {
            this.isToOpen = this.isFromOpen = false;
        },
        handleClickOutside() {
            this.isFromOpen = false;
            this.isToOpen = false;
        },
        open(which) {
            switch (which) {
                case 'to':
                    this.isToOpen = !this.isToOpen;
                    if (this.isFromOpen) {
                        this.isFromOpen = !this.isFromOpen;
                    }
                    break;

                default:
                case 'from':
                    this.isFromOpen = !this.isFromOpen;
                    if (this.isToOpen) {
                        this.isToOpen = !this.isToOpen;
                    }
                    break;
            }
        },
        isAvailableDate(date) {
            if (!this.availableDates) return true;

            let available = false;

            this.availableDates.every(item => {
                if (date.toMillis() > item.start.getTime() && date.toMillis() <= item.end.getTime()) {
                    available = true;

                    return false;
                }

                return true;
            });

            return available;
        },
        dateToString(date) {
            return date ?`${date.get('day')}. ${this.$i18n.t(`month.${date.get('month') - 1}`)} ${date.get('year')}`: '';
        },
        dateToMobileString(date) {
            return date ? `${date.get('day')}.${date.get('month')}.${date.get('year')}`: '';
        },
    },
};
