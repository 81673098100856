
export default {
    name: 'VMNumber',
    props: {
        minValue: {
            type: Number,
            required: false,
            default: 0,
        },
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
                this.$emit('update:value', value);
            },
        },
    },
    methods: {
        add() {
            this.internalValue = this.internalValue + 1;
        },
        subtract() {
            if (this.internalValue > 0) {
                this.internalValue = this.internalValue - 1;
            }
        },
    },
};
