const Cookies = require('cookie');

export default async function({ $axios, req, res, store }) {
    if (process.server) {
        if (req.headers.cookie) {
            const cookies = Cookies.parse(req.headers.cookie);
            if (cookies.accessToken && cookies.refreshToken) {
                store.commit('user/setLoggedIn', true);
                store.dispatch('homeowner/apartments/load');
            } else if (cookies.refreshToken) {
                const refreshResponse = await $axios.request({
                    method: 'get',
                    url: '/api/v2/refresh',
                });
                const newToken = Cookies.parse(refreshResponse.headers['set-cookie'].find(item => item.match(/^accessToken=/)));
                res.setHeader('set-cookie', refreshResponse.headers['set-cookie']);
                $axios.defaults.headers.common.cookie = `${req.headers.cookie}; accessToken=${newToken.accessToken}`;
                store.commit('user/setLoggedIn', true);
                store.dispatch('homeowner/apartments/load');
            } else {
                store.commit('user/setLoggedIn', false);
            }
        }
    }
};
