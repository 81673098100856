
import { mapGetters, mapMutations } from 'vuex';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'Modal',
    mixins: [breakpoints],
    computed: {
        ...mapGetters({
            id: 'modal/getId',
            open: 'modal/getOpen',
            closeButton: 'modal/getCloseButton',
            returnElement: 'modal/getReturnElement',
        }),
        cssClass() {
            if (this.open) {
                return 'open';
            }

            return false;
        },
        iconProperties() {
            if (this.isDesktop) {
                return {
                    size: '25xl',
                    icon: 'close-circle',
                };
            }

            return {
                size: '2xl',
                icon: 'close-circle',
            };
        },
    },
    watch: {
        $route(to, from) {
            // Close modal on route change
            this.close();
        },
        open(to, from) {
            if (to) {
                this.$refs.content.$el.focus();
            } else {
                document.querySelector(this.returnElement ? this.returnElement : 'body').focus();
                this.setReturnElement(null);
            }
        },
    },
    methods: {
        ...mapMutations({
            setOpen: 'modal/setOpen',
            setReturnElement: 'modal/setReturnElement',
        }),
        close() {
            this.setOpen(false);
        },
    },
};
