
export default {
    name: 'SearchHeader',
    props: {
        fields: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    computed: {
        isMobile() {
            if (!process.server) {
                return window.outerWidth < this.$breakpoints.mobile;
            }

            return false;
        },
        formFields() {
            return {
                store: 'search',
                action: 'search/submit',
                fields: [{
                        dataKey: 'search',
                        label: this.$t('search.fields.heading'),
                        placeholder: this.$t('search.fields.placeholder'),
                        labelPosition: 'top',
                        theme: 'white',
                    }, {
                        type: 'submit',
                        action: 'search/submit',
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                        },
                        label: this.$t('search.fields.button'),
                    },
                ],
            };
        },
    },
};
