import { render, staticRenderFns } from "./ImageHalfTextHalf.vue?vue&type=template&id=820a0906&scoped=true"
import script from "./ImageHalfTextHalf.vue?vue&type=script&lang=js"
export * from "./ImageHalfTextHalf.vue?vue&type=script&lang=js"
import style0 from "./ImageHalfTextHalf.vue?vue&type=style&index=0&id=820a0906&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "820a0906",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/build/src/components/elements/ContentfulImage.vue').default,TextEditor: require('/build/src/components/elements/TextEditor.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default})
