import { render, staticRenderFns } from "./AlertBanner.vue?vue&type=template&id=018d85b8&scoped=true"
import script from "./AlertBanner.vue?vue&type=script&lang=js"
export * from "./AlertBanner.vue?vue&type=script&lang=js"
import style0 from "./AlertBanner.vue?vue&type=style&index=0&id=018d85b8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018d85b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkElement: require('/build/src/components/elements/LinkElement.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default,DSHeightTransition: require('/build/node_modules/@lamiaoy/st1-ds-components/src/components/Components/HeightTransition/HeightTransition.vue').default})
