
const flexViewConfig = require('~config/map-flexview.json');

function loadOaScript() {
    return new Promise(resolve => {
        const callbackName = 'vierumakiOaCallback';
        window[callbackName] = resolve;
    });
}

export default {
    name: 'FlexViewMap',
    props: {
        viewType: {
            type: String,
            required: false,
            default: 'tour',
        },
    },
    computed: {
        id() {
            return 'd9hl9z';
        },
        lang() {
            return this.$i18n.locale;
        },
    },
    async mounted() {
        if (!window.oa) {
            await loadOaScript();
        }
        const conf = {
            frontendtype: this.viewType,
            initMode: 'map',
            fitDataBounds: true,
        };
        window.oa.api.flexviewpage(conf);
    },
};
