
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'Upsell',
    data() {
        return {
            offset: 0,
        };
    },
    computed: {
        ...mapGetters({
            cartItems: 'cart/getProducts',
            items: 'cart/getUpsell',
        }),
    },
    watch: {
        items(value) {
            this.offset = 0;
        },
    },
    methods: {
        ...mapMutations({
            toggleCart: 'navigation/toggleCartOpen',
            setDates: 'product/setDates',
        }),
        navigate(newOffset) {
            this.offset = newOffset;
        },
        handleClick(selectedItem) {
            const sourceItem = this.cartItems.find(cartItem => cartItem.code === selectedItem.sourceProductCode);
            this.setDates({
                start: sourceItem.from,
                end: sourceItem.to,
            });
            this.$router.push(selectedItem.url);
            this.toggleCart();
        },
        isRightActive() {
            if (this.items
               && this.items.length > 2
               && this.offset*2 < this.items.length-2
            ) {
                return true;
            }

            return false;
        },
        isLeftActive() {
            if (this.offset > 0) {
                return true;
            }

            return false;
        },
    },
};
