import { render, staticRenderFns } from "./ContactCards.vue?vue&type=template&id=7a474159&scoped=true"
import script from "./ContactCards.vue?vue&type=script&lang=js"
export * from "./ContactCards.vue?vue&type=script&lang=js"
import style0 from "./ContactCards.vue?vue&type=style&index=0&id=7a474159&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a474159",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkElement: require('/build/src/components/elements/LinkElement.vue').default,ContactCard: require('/build/src/components/elements/Cards/ContactCard.vue').default})
