
export default {
    name: 'CheckboxInput',
    props: {
        dataKey: {
            type: [String],
            required: false,
            default: '',
        },
        options: {
            type: [Array],
            required: false,
            default: () => [],
        },
        cssClass: {
            type: [Object],
            required: false,
            default: () => ({}),
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        label: {
            type: [String, Number],
            required: false,
            default: '',
        },
        value: {
            type: [Array],
            required: false,
            default: () => [],
        },
        commit: {
            type: [Function],
            required: false,
            default: null,
        },
        helpText: {
            type: [String],
            required: false,
            default: null,
        },
        error: {
            type: [String],
            required: false,
            default: null,
        },
    },
    data: () => ({
        instanceId: null,
    }),
    created() {
        this.instanceId = `checkbox-${this.$util.uuidv4()}`;
    },
    methods: {
        setValue(event, value) {
            const commitValue = [...this.value];

            if (commitValue.indexOf(value) !== -1) {
                commitValue.splice(commitValue.indexOf(value), 1);
            } else {
                commitValue.push(value);
            }

            this.$emit('commit', {
                dataKey: this.dataKey,
                value: commitValue,
            });
        },
    },
};
