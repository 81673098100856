
import { mapGetters, mapMutations, mapActions } from 'vuex';
import VSimplebar from 'simplebar-vue';
import htmlScrollLockMixin from '~/mixins/htmlScrollLockMixin';

export default {
    name: 'Cart',
    components: {
        VSimplebar,
    },
    mixins: [htmlScrollLockMixin ],
    props: {
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            products: 'cart/getProducts',
            productsUnavailable: 'cart/getProductsUnavailable',
            upgrades: 'cart/getUpgrades',
            prices: 'cart/getPrices',
            cartOpen: 'navigation/getCartOpen',
            searchOpen: 'navigation/getSearchOpen',
            isLoggedIn: 'user/getLoggedIn',
            isFinished: 'cart/getFinished',
            totalsLoading: 'cart/getTotalsLoading',
            isGiftcard: 'cart/containsGiftcard',
        }),
        checkoutDisabled() {
            if (!process.server) {
                return this.products.length === 0 || this.totalsLoading || this.productsUnavailable;
            }

            return false;

        },
    },
    methods: {
        ...mapMutations({
            removeProduct: 'cart/removeProduct',
            upgradeProduct: 'cart/upgradeProduct',
            toggleCartOpen: 'navigation/toggleCartOpen',
            setRedirect: 'login/setRedirect',
            setModalId: 'modal/setId',
            setModalOpen: 'modal/setOpen',
            setModal: 'modal/setModal',
            setModalOpen: 'modal/setOpen',
            setModalCloseButton: 'modal/setCloseButton',
            setModalId: 'modal/setId',
            setModalReturn: 'modal/setReturnElement',
            setLoading: 'content/setLoading',
        }),
        openModal(id) {
            this.setModalId(id);
            this.setModalOpen(true);
        },
        openLoginModal() {
            this.setModal({
                id: 'checkout-login',
                returnElement: '.nuxt',
                closeButton: false,
                backdropExit: false,
                open: true,
            });
            this.loginModalShown = true;
        },
        upgradeFromModal(upgrade) {
            this.upgradeProduct(upgrade);
            this.setModalOpen(false);
        },
        currentUpgrades(product) {
            if (this.upgrades[product.dataKey] && !product.unavailable) {
                return this.upgrades[product.dataKey];
            }

            return [];
        },
        removeProductClick(product) {
            this.removeProduct(product.dataKey);
            this.$gtm.push({
                event: 'remove_from_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(product)],
                },
            });
        },
        removeProductEnter(product) {
            this.removeProductClick(product);
        },
        handleEnter() {
            this.toggleCartOpen();
            if (this.cartOpen) {
                document.querySelector('.cart-content .icon.close')?.focus();
                this.$store.commit('setLockHtmlScroll', true);
            } else {
                // fix sticky targeting
                document.querySelector('.nav-two .cart-control .cart-icon').focus();
                this.$store.commit('setLockHtmlScroll', false);
            }
        },
        handleTab(event) {
            if (event.target.classList.contains('close') && event.shiftKey
                || event.target.classList.contains('close') && this.products.length === 0
                || event.target.classList.contains('btn-checkout') && !event.shiftKey) {
                event.preventDefault();
                this.handleEnter();
            }
        },
        getRedirect() {
            this.toggleCartOpen();

            if (this.isGiftcard || this.isLoggedIn) {
                this.setLoading(true);
                this.$router.push(this.$paths.getPath('checkout'));
            } else {
                this.openLoginModal();
            }
        },
    },
};
