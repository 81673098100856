
import { mapGetters } from 'vuex';

export default {
    name: 'Forms',
    props: {
        // add props to disable output from contentful
        id: {
            type: String,
            required: false,
            default: '',
        },
        contentType: {
            type: String,
            required: false,
            default: 'loginForm',
        },
        // props in actual use
        fields: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            loggedIn: 'user/getLoggedIn',
        }),
    },
};
