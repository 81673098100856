
import { mapGetters } from 'vuex';

export default {
    name: 'CheckoutNav',
    computed: {
        ...mapGetters({
            isGiftcard: 'cart/containsGiftcard',
            isFinished: 'cart/getFinished',
        }),
        isDisabled() {
            return this.$route.path !== this.$paths.getPath('checkout');
        },
    },
    methods: {
        navigateBack() {
            if (this.$nuxt.context.from === undefined) {
                this.$router.push(this.$paths.getPath('landingPage'));
            } else if (this.$nuxt.context.from.path === this.$paths.getPath('login')) {
                window.history.go(-2);
            } else {
                window.history.go(-1);
            }
        },
    },
};
