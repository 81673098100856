import { render, staticRenderFns } from "./ProductItems.vue?vue&type=template&id=0c8739dc&scoped=true"
import script from "./ProductItems.vue?vue&type=script&lang=js"
export * from "./ProductItems.vue?vue&type=script&lang=js"
import style0 from "./ProductItems.vue?vue&type=style&index=0&id=0c8739dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8739dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateSelect: require('/build/src/components/elements/Form/DateSelect.vue').default,PersonSelect: require('/build/src/components/elements/Form/PersonSelect.vue').default,EquipmentSelect: require('/build/src/components/elements/Form/EquipmentSelect.vue').default,DiscountCodePopup: require('/build/src/components/elements/DiscountCodePopup.vue').default,DateRange: require('/build/src/components/elements/Form/DateRange.vue').default,RadioFilter: require('/build/src/components/elements/Form/RadioFilter.vue').default,AmountFilter: require('/build/src/components/elements/Form/AmountFilter.vue').default,VMFilterSelect: require('/build/src/components/elements/Form/VMFilterSelect.vue').default,ProductTable: require('/build/src/components/blocks/ProductPage/ProductItems/ProductTable.vue').default,CategoryCards: require('/build/src/components/blocks/ProductPage/ProductItems/CategoryCards.vue').default})
