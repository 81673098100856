export const state = () => ({
    id: '',
    open: false,
    returnElement: null,
    closeButton: true,
    backdropExit: true,
});

export const mutations = {
    setModal(state, modalOptions) {
        state = Object.assign(state, modalOptions); // eslint-disable-line no-param-reassign
    },
    setId(state, payload) {
        state.id = payload;
    },
    setOpen(state, payload) {
        if (typeof(payload) === 'boolean') {
            state.open = payload;
        } else {
            state.open = !state.open;
        }
        if (state.open === false) {
            state.closeButton = true;
            state.backdropExit = true;
        }
    },
    setReturnElement(state, payload) {
        state.returnElement = payload;
    },
    setCloseButton(state, payload) {
        state.closeButton = payload;
    },
};

export const getters = {
    getId: state => state.id,
    getOpen: state => state.open,
    getReturnElement: state => state.returnElement,
    getCloseButton: state => state.closeButton,
    getBackdropExit: state => state.backdropExit,
};
