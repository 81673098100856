
export default {
    name: 'ContactCard',
    props: {
        image: {
            src: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                required: false,
                default: 'Image',
            },
            type: Object,
            required: false,
            default: () => ({}),
        },
        name: {
            type: String,
            required: true,
            default: '',
        },
        jobTitle: {
            type: String,
            required: false,
            default: '',
        },
        email: {
            type: String,
            required: false,
            default: '',
        },
        phoneNumber: {
            type: String,
            required: false,
            default: '',
        },
        displayImage: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        showImage() {
            return this.displayImage && this.image && 'src' in this.image;
        },
    },
    methods: {},
};
