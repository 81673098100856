import { mapGetters } from 'vuex';

export default {
    head() {
        return {
            title: this.title,
            htmlAttrs: {
                class: 'theme-vierumaki',
            },
            meta: [
                { hid: 'og:title', name: 'og:title', content: `${this.title} - Vierumäki` },
                { hid: 'description', name: 'description', content: this.description ?? '' },
                { hid: 'og:description', name: 'og:description', content: this.description ?? '' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            content: 'content/getData',
            title: 'content/getTitle',
            description: 'content/getDescription',
        }),
    },
};
