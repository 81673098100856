import { render, staticRenderFns } from "./AccommodationProductCard.vue?vue&type=template&id=2a29c864&scoped=true"
import script from "./AccommodationProductCard.vue?vue&type=script&lang=js"
export * from "./AccommodationProductCard.vue?vue&type=script&lang=js"
import style0 from "./AccommodationProductCard.vue?vue&type=style&index=0&id=2a29c864&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a29c864",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCardContent: require('/build/src/components/elements/Cards/ProductCardContent.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default})
