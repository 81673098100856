
export default {
    name: 'EducationPage',
    props: {
        id: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        degreeTitle: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: Object,
            required: false,
            default: null,
        },
        sidebarInfoText: {
            type: String,
            required: false,
            default: '',
        },
        sidebarInfoButton: {
            type: Object,
            required: false,
            default: null,
        },
        degreeExtent: {
            type: String,
            required: false,
            default: null,
        },
        degreeStartTime: {
            type: String,
            required: false,
            default: null,
        },
        degreeLength: {
            type: String,
            required: false,
            default: null,
        },
        degreePrice: {
            type: Number,
            required: false,
            default: null,
        },
        degreePriceDescription: {
            type: String,
            required: false,
            default: null,
        },
        degreeImplementation: {
            type: String,
            required: false,
            default: null,
        },
        body: {
            type: Array,
            required: false,
            default: () => [],
        },
        footer: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        showSidebarInfo() {
            return this.degreeExtent || this.degreeStartTime || this.degreeLength || this.degreePrice || this.degreePriceDescription || this.degreeImplementation;
        },
    },
    mounted() {
        this.pushEcommerceEvent('view_item');
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);

            return date.toLocaleDateString('fi-FI');
        },
        pushEcommerceEvent(event, additionalAttributes = {}) {
            this.$gtm.push({
                event,
                ecommerce: {
                    items: [{
                        item_id: this.id,
                        item_name: this.degreeTitle,
                        price: this.degreePrice,
                        currency: 'EUR',
                        quantity: 1,
                        item_category: 'Koulutus',
                        ...additionalAttributes,
                    }],
                },
            });
        },
    },
};
