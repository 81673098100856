import httpStatus from 'http-status';

export default function(ctx) {
    if (ctx.route.path === ctx.$paths.getPath('checkout') && ctx.route.query) {
        if (ctx.route.query.transactionId && ctx.route.query.responseCode === 'OK') {
            ctx.store.commit('cart/setFinished', true);
        }
    } else if (process.server) {
        ctx.store.commit('cart/setFinished', false);
    }
};
