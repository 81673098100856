
export default {
    name: 'Tag',
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        url: {
            type: String,
            required: false,
            default: '',
        },
    },
};
