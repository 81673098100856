import { mapGetters } from 'vuex';

export default {
    name: 'htmlStrollLockMixin',
    head() {
        const bodyClasses = [
            ...(this.lockHtmlScroll ? [ 'no-overflow-y' ] : []),
        ];

        return {
            htmlAttrs: {
                class: bodyClasses.join(' '),
            },
        };
    },
    computed: {
        ...mapGetters({
            lockHtmlScroll: 'lockHtmlScroll',
        }),
    },
};
