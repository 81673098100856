import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=780d5695&scoped=true"
import script from "./Accordion.vue?vue&type=script&lang=js"
export * from "./Accordion.vue?vue&type=script&lang=js"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=780d5695&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780d5695",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentRouter: require('/build/src/components/ContentRouter.vue').default,VMAccordionItem: require('/build/src/components/elements/VMAccordionItem.vue').default,VMAccordion: require('/build/src/components/elements/VMAccordion.vue').default})
