
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters({
                alert: 'navigation/getAlert',
                showAlert: 'navigation/getShowAlert',
            }),
            message() {
                return this.alert?.message;
            },
            links() {
                return this.alert?.links ?? [];
            },
        },
        methods: {
            close() {
                const newHeight = `${document.querySelector('[role~=banner]').getBoundingClientRect().height - document.querySelector('.alert-banner .alert-contents').getBoundingClientRect().height}px`;
                this.$emit('alertClosed', { height: newHeight });
                this.$store.commit('navigation/dismissAlert');
            },
        },
    };
