
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

export default {
    name: 'TextEditor',
    props: {
        tag: {
            type: String,
            required: false,
            default: 'div',
        },
        content: {
            type: Object,
            required: false,
            default: () => {},
        },
        fontSize: {
            type: String,
            required: false,
            default: '16-regular',
        },
    },
    computed: {
        html() {
            try {
                return documentToHtmlString(this.content, {
                    renderNode: {
                        [BLOCKS.EMBEDDED_ASSET]: node => `${ this.renderImage(node) }`,
                    },
                }).replace(/\n/g, '<br>');
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                };

                return 'Could not render html';
            }
        },
    },
    methods: {
        renderImage(node) {
            const { url } = node.data.target;
            if (/^\/\/assets/.test(url)) {
                // The node is not an image, so show a link
                return `<p><a href="${url}" target="_blank">${node.data.target.title}</a></p>`;
            }

            return `<img src="${ node.data.target.url }" alt="" />`;
        },
    },
    render(h) {
        return h(this.tag, {
            staticClass: 'std',
            class: {
                ['text-editor-content']: true,
                [`text--${this.textAlign}`]: this.textAlign,
                [`font-size-${this.fontSize}`]: true,
            },
            domProps: {
                innerHTML: this.html,
            },
        });
    },
};
