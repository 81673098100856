
    export default {
        name: 'Media',
        props: {
            contentType: {
                type: String,
                required: false,
                default: 'media',
            },
            fields: {
                type: Object,
                required: false,
                default: () => {},
            },
        },
    };
