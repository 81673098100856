
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ResetPasswordForm',
    props: {
        fields: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            currentStep: 'resetPassword/getStep',
            loggedIn: 'user/getLoggedIn',
        }),
        emailFields() {
            return {
                title: this.fields?.title,
                description: this.fields?.description,
                store: 'resetPassword',
                action: 'resetPassword/submitStep1',
                fields: [{
                        dataKey: 'email',
                        label: this.$t('resetPassword.fields.email'),
                        validator: ['notEmpty', 'email'],
                    }, {
                        type: 'submit',
                        action: 'submit',
                        label: this.$t('resetPassword.fields.send'),
                        cssClass: {
                            'disabled': this.locked,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                    }, {
                        type: 'button',
                        cssClass: {
                            'btn-link': true,
                            'btn-secondary': true,
                        },
                        action: () => this.gotoLogin(),
                        label: this.$t('resetPassword.fields.login'),
                    },
                ],
            };
        },
        passwordFields() {
            return {
                title: this.$t('resetPassword.text.renewPassword'),
                description: this.$t('resetPassword.text.renewPasswordByline'),
                store: 'resetPassword',
                action: 'resetPassword/submit',
                fields: [{
                        dataKey: 'newPassword',
                        label: this.$t('resetPassword.fields.newPassword'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            { type: 'minLength', value: 6 },
                            { type: 'maxLength', value: 36 },
                        ],
                    }, {
                        dataKey: 'confirmPassword',
                        label: this.$t('resetPassword.fields.confirmationPassword'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            {
                                type: 'equals',
                                field: ['resetPassword', 'newPassword'],
                            },
                            { type: 'maxLength', value: 36 },
                        ],
                    }, {
                        type: 'submit',
                        action: 'submit',
                        label: this.$t('resetPassword.fields.save'),
                        cssClass: {
                            'btn-primary': true,
                            'btn-full': true,
                        },
                    },
                ],
            };
        },
    },
    methods: {
        ...mapActions({
            logout: 'login/logout',
        }),
        gotoLogin() {
            this.$router.push(this.$paths.getPath('login'));
        },
    },
};
