
import { mapMutations } from 'vuex';

export default {
    name: 'Link',
    props: {
        link: {
            path: {
                type: String,
                required: false,
                default: '',
            },
            url: {
                type: String,
                required: false,
                default: '',
            },
            type: Object,
            required: false,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        contentType: {
            type: [String, Boolean],
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        url: {
            type: String,
            required: false,
            default: '/',
        },
        urlType: {
            type: String,
            required: false,
            default: '',
        },
        appearance: {
            type: String,
            required: false,
            default: '',
        },
        buttonAppearance: {
            type: String,
            required: false,
            default: '',
        },
        popup: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        id: {
            type: String,
            required: false,
            default: '',
        },
        full: {
            type: Boolean,
            default: false,
            required: false,
        },
        focusable: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    computed: {
        getClasses() {
            const classes = [];

            if (this.appearance === 'button') {
                classes.push('btn');
                classes.push('link-button'); // ?!? extra? - J
                switch (this.buttonAppearance) {
                    case 'plain':
                        classes.push('btn-plain');
                    break;
                    case 'white':
                        classes.push('btn-white');
                    break;
                    case 'secondary':
                        classes.push('btn-secondary');
                    break;
                    default:
                    case 'primary':
                        classes.push('btn-primary');
                    break;
                }
            } else if (this.appearance === 'link') {
                classes.push('link');
            }

            if (this.disabled) {
                classes.push('disabled');
            }
            if (this.full) {
                classes.push('btn-full');
            }

            return classes.join(' ');
        },
    },
    methods: {
        ...mapMutations({
            setId: 'modal/setId',
            openModal: 'modal/setOpen',
        }),
        openPopup() {
            if (this.id?.length) {
                this.setId(this.id);
                this.openModal(true);
            }
        },
    },
};
