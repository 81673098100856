
    import breakpoints from '~/mixins/breakpoints';


    export default {
        name: 'ContentfulModal',
        mixins: [breakpoints],
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            image: {
                type: [Object, Boolean],
                required: false,
                default: false,
            },
            content: {
                type: Array,
                required: false,
                default: () => ([]),
            },
            button: {
                type: [Object, Boolean],
                required: false,
                default: false,
            },
            id: {
                type: [String, Boolean],
                required: false,
                default: false,
            },
        },
        computed: {
            hasContent() {
                return this.content;
            },
            imageUrl() {
                if (this?.image?.url?.length) {
                    return `${this.image.url}?w=700`;
                }

                return '';
            },
        },
        methods: {
            show() {
                this.$modal.show(this.id);
            },
            hide() {
                this.$modal.hide(this.id);
            },
            getContent(item) {
                return item?.fields?.content;
            },
            getFontSize(item) {
                return item?.fields?.fontSize;
            },
        },
    };
