const defaultState = () => ({
    apartments: [],
    loaded: false,
});

export default {
    state: () => defaultState(),
    mutations: {
        commitPayload(state, payload) {
            Object.keys(payload).forEach(key => {
                if (typeof(payload[key]) === typeof(state[key])) {
                    state[key] = payload[key];
                } else {
                    throw new Error(`Failed assigning data ${key} in homeowner/apartments`);
                }
            });
        },
    },
    actions: {
        async load (ctx) {
            try {
                const { data } = await this.$axios.request({
                    method: 'get',
                    url: this.$paths.getEndpoint('homeownerApartments'),
                });
                this.commit('homeowner/apartments/commitPayload', {
                    apartments: data,
                    loaded: true,
                });
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
            }
        },
    },
    getters: {
        isHomeOwner: state => state.apartments.length > 0,
        getApartments: state => state.apartments,
        isLoaded: state => state.loaded,
    },
};
