import { cloneDeep } from 'lodash';

export default ({ app, store }, inject) => {
    inject('contentParser', {
        parseContent(content) {
            const parsedContent = [];

            content.forEach((data, key) => {
                switch (data.contentType) {
                    case 'header':
                        parsedContent.push({
                            primaryLinks: data.fields?.primaryLinks?.map((ldata, lkey) => this.formatLink(ldata)),
                            secondaryLinks: data.fields?.secondaryLinks?.map((ldata, lkey) => this.formatMenuItem(this, ldata)),
                        });
                    break;

                    case 'footer':
                        data.fields.column1 = data.fields?.column1
                            ?.map((ldata, lkey) => this.formatLink(ldata));

                        data.fields.column2 = data.fields?.column2
                            ?.map((ldata, lkey) => this.formatLink(ldata));

                        data.fields.column4 = data.fields?.column4.map(fdata => this.formatLink(fdata));
                        data.fields.bottom = {
                            copyright: data.fields?.copyright,
                            legalLinks: data.fields?.legalLinks.map(ldata => this.formatLink(ldata)),
                        };

                        parsedContent.push(data.fields);
                    break;

                    case 'notification':
                        parsedContent.push({
                            id: data?.id,
                            message: data?.fields?.message,
                            links: data?.fields?.links?.map(link => this.formatLink(link)),
                        });
                    break;

                    case 'externalData':
                        if (data.fields?.type === 'category') {
                            data.fields.attributes.classification = 'category';

                            if (data?.fields?.schema) {
                                data.fields.attributes.schema = data.fields.schema;
                            }
                        }

                        store.commit('product/setBuffer', data.fields);
                        parsedContent.push({
                            id: data.id,
                            contentType: 'externalData',
                        });
                    break;

                    case 'loginForm':
                        if (data.image) {
                            data.fields.image = this.formatImage(data.fields?.image);
                        }
                        parsedContent.push(data);
                    break;

                    case 'imageHalfTextHalf':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            text: data.fields?.text,
                            assetPosition: data.fields?.assetPosition,
                            textColor: data.fields?.textColor,
                            isBoxed: data.fields?.isBoxed,
                            youtubeVideoId: data.fields?.youtubeVideoId,
                        };

                        if (data.fields?.image) {
                            parsedContent[key].image = this.formatImage(data.fields?.image);
                        }

                        parsedContent[key].buttons = [];

                        data.fields?.buttons?.forEach((fdata, fkey) => {
                            parsedContent[key].buttons[fkey] = this.formatLink(fdata);
                        });

                        return parsedContent;
                    break;

                    case 'banner':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            isBoxed: data.fields?.isBoxed,
                            textAlign: data.fields?.textAlign,
                            content: data.fields?.content?.fields,
                        };

                        parsedContent[key].buttons = [];

                        data.fields?.content?.fields?.buttons?.forEach((fdata, fkey) => {
                            parsedContent[key].buttons[fkey] = this.formatLink(fdata);
                        });

                        return parsedContent;
                    break;

                    case 'infoBoxes':
                        parsedContent[key] = {
                            contentType: data.contentType,
                            id: data.id,
                            title: data.fields?.title,
                            boxes: data.fields?.boxes?.map(fdata => fdata.fields),
                        };

                        return parsedContent;
                    break;

                    case 'halfContentGroup':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            isBoxed: data.fields?.isBoxed,
                            priorityBackground: data.fields?.priorityBackground,
                            usePriorityBackground: data.fields?.usePriorityBackground,
                            contents: data.fields?.contents?.map(fdata => {
                                if (fdata.fields.buttons) {
                                    fdata.fields.buttons = this.formatButtons(fdata.fields?.buttons);
                                }

                                return fdata.fields;
                            }),
                        };

                        return parsedContent;
                    break;

                    case 'heroSlider':
                        const slides = data.fields.slides.map(slide => this.flattenObject(slide));

                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            slides,
                        };

                        return parsedContent;
                    break;

                    case 'cardList':
                        parsedContent[key] = Object.assign(data, data.fields);
                        delete parsedContent[key].fields;

                        parsedContent[key].cards?.forEach((card, cKey) => {
                            parsedContent[key].cards[cKey] = Object.assign(parsedContent[key].cards[cKey], parsedContent[key].cards[cKey].fields);
                            delete parsedContent[key].cards[cKey].fields;

                            if (parsedContent[key].cards[cKey].links) {
                                parsedContent[key].cards[cKey].links?.forEach((link, lkey) => {
                                    if (link.fields.url) {
                                        switch (link.fields?.url?.contentType) {
                                            case 'educationPage':
                                            case 'standardPage':
                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.title,
                                                    url: {
                                                        contentType: 'standardPage',
                                                        slug: link.fields?.url?.pagePath,
                                                    },
                                                };
                                            break;

                                            case 'externalUrl':
                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.url?.entryName,
                                                    url: {
                                                        contentType: 'string',
                                                        value: link.fields?.url?.url,
                                                    },
                                                };
                                            break;

                                            case 'assetUrl':
                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.url?.entryName,
                                                    url: {
                                                        contentType: 'string',
                                                        value: `https:${link.fields?.url?.url}`,
                                                    },
                                                };
                                            break;

                                            case 'articlePage':
                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.title,
                                                    url: {
                                                        contentType: link.fields?.url?.contentType,
                                                        slug: `artikkeli/${link.fields?.url?.fields?.slug}`,
                                                    },
                                                };
                                            break;

                                            case 'product':
                                                const product = link.fields?.url?.products?.[Object.keys(link.fields?.url?.products)?.[0]];

                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.title,
                                                    url: {
                                                        contentType: 'standardPage',
                                                        slug: product?.pagePath,
                                                    },
                                                };
                                            break;

                                            case 'category':
                                                const category = link.fields?.url?.categories[Object.keys(link.fields?.url?.categories)?.[0]];

                                                parsedContent[key].cards[cKey].links[lkey] = {
                                                    title: link.fields?.title,
                                                    url: {
                                                        contentType: 'standardPage',
                                                        slug: category?.pagePath,
                                                    },
                                                };
                                            break;
                                        }
                                    }
                                });
                            }
                        });

                        return parsedContent;
                    break;

                    case 'contentCards':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            title: data.fields?.title,
                            seeMoreLink: data.fields?.seeMoreLink ? this.formatLink(data.fields?.seeMoreLink) : undefined,
                            backgroundColor: data.fields?.backgroundColor,
                            textColor: data.fields?.textColor,
                            cards: data.fields?.cards?.map(cardsData => {
                                const fdata = cloneDeep(cardsData);

                                if (fdata.fields?.image) {
                                    fdata.fields.image = this.formatImage(fdata.fields.image);
                                }

                                if (fdata.fields?.button) {
                                    fdata.fields.button = this.formatLink(fdata.fields.button);

                                    if (data.fields?.slidesButtonAppearance?.length
                                        && ['primary', 'secondary', 'white'].includes(data.fields?.slidesButtonAppearance)) {
                                        fdata.fields.button = { ...fdata.fields.button, buttonAppearance: data.fields.slidesButtonAppearance };
                                    }
                                }

                                fdata.fields.contentType = fdata.contentType;

                                return fdata.fields;
                            }),
                        };

                        return parsedContent;
                    break;

                    case 'heroBanner':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            displayOverlay: data.fields?.displayOverlay,
                            overlayTextColor: data.fields?.overlayTextColor,
                            overlayBackgroundColor: data.fields?.overlayBackgroundColor,
                            overlayTitle: data.fields?.overlayTitle,
                            overlayDescription: data.fields?.overlayDescription,
                            links: data.fields?.links?.map(link => this.formatLink(link)),
                            contents: data.fields?.contents?.map(item => this.flattenObject(item)),
                        };

                        return parsedContent;
                    break;

                    case 'contentSlider':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            title: data.fields?.title,
                            seeMoreLink: data.fields?.seeMoreLink ? this.formatLink(data.fields?.seeMoreLink) : undefined,
                            cardSize: data.fields?.cardSize,

                            slides: data.fields?.slides?.map(fdata => {
                                if (fdata.contentType === 'contentCard') {
                                    if (fdata.fields?.image) {
                                        fdata.fields.image = this.formatImage(fdata.fields.image);
                                    }

                                    if (fdata.fields?.button) {
                                        fdata.fields.button = this.formatLink(fdata.fields.button);
                                    }

                                    fdata.fields.contentType = fdata.contentType;

                                    return fdata.fields;
                                } else if (fdata.contentType === 'product') {
                                    if ('products' in fdata && this.getFirstFromObject(fdata, 'products')) {
                                        fdata.product = this.getFirstFromObject(fdata, 'products');
                                        fdata.products = undefined;
                                    } else {
                                        // If Contentful object doesn't have a product attached, remove slide
                                        return null;
                                    }
                                }

                                return fdata;
                            }).filter(item => item),
                        };

                        return parsedContent;
                    break;

                    case 'contactCards':
                        const cards = [];
                        const cardLength = 7;

                        for (let i = 1; i < cardLength; i++ ) {
                            if (`name${i}` in data.fields && data.fields[`name${i}`]) {
                                const item = {};
                                item.name = data.fields[`name${i}`];
                                if (`image${i}` in data.fields && data.fields[`image${i}`]) {
                                    item.image = this.formatImage(data.fields[`image${i}`]);
                                }
                                if (`jobTitle${i}` in data.fields && data.fields[`jobTitle${i}`]) {
                                    item.jobTitle = data.fields[`jobTitle${i}`];
                                }
                                if (`phoneNumber${i}` in data.fields && data.fields[`phoneNumber${i}`]) {
                                    item.phoneNumber = data.fields[`phoneNumber${i}`];
                                }
                                if (`email${i}` in data.fields && data.fields[`email${i}`]) {
                                    item.email = data.fields[`email${i}`];
                                }
                                cards.push(item);
                            }
                        }

                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            title: data.fields?.title,
                            displayImage: data.fields?.displayImage,
                            seeMoreLink: data.fields?.seeMoreLink ? this.formatLink(data.fields?.seeMoreLink) : undefined,
                            cards,
                        };

                        return parsedContent;
                    break;

                    case 'quickLinks':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            links: data.fields?.links?.map(fdata => {
                                if (fdata.fields?.image) {
                                    fdata.fields.image = this.formatImage(fdata.fields.image);
                                }

                                if (fdata.fields?.link) {
                                    fdata.fields.link = this.formatLink(fdata.fields.link);
                                }

                                fdata.fields.contentType = fdata.contentType;

                                return fdata.fields;
                            }),
                        };

                        return parsedContent;
                    break;

                    case 'accordion':
                        const items = [];
                        const accordionLength = 21;

                        for (let i = 1; i < accordionLength; i++ ) {
                            if (`title${i}` in data.fields && data.fields[`title${i}`] && `contents${i}` in data.fields && data.fields[`contents${i}`] && data.fields[`contents${i}`].length) {
                                const item = {};
                                item.title = data.fields[`title${i}`];
                                item.content = this.parseContent(data.fields[`contents${i}`]);
                                items.push(item);
                            }
                        }

                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            title: data.fields?.title,
                            items,
                        };

                        return parsedContent;
                    break;

                    case 'wysiwygBlock':
                        parsedContent[key] = {
                            id: data.id,
                            contentType: data.contentType,
                            content: { content: data.fields?.content?.content },
                            alignLeft: data.fields?.alignLeft,
                            fontSize: data.fields?.fontSize,
                        };

                        return parsedContent;
                    break;

                    case 'eventCalendar':
                        parsedContent[key] = {
                            contentType: data.contentType,
                            filters: data.fields?.filters?.map(fdata => ({ id: fdata?.id, ...fdata?.fields, isChecked: true })),
                        };

                        store.commit('calendar/setFilters', parsedContent[key].filters);
                    break;

                    case 'articlePage':
                        let image = undefined;
                        let tags = undefined;

                        if (data?.fields?.image) {
                            image = { image: this.formatImage(data.fields?.image) };
                        }

                        if (data?.fields?.tags?.length) {
                            tags = data?.fields?.tags?.map(item => item.fields?.label);
                        }

                        parsedContent[key] = {
                            contentType: data.contentType,
                            title: data.fields?.title,
                            content: data.fields?.content?.fields?.content ?? undefined,
                            shortDescription: data.fields?.shortDescription,
                            createdAt: data?.createdAt,
                            updatedAt: data?.updatedAt,
                            tags,
                            ...image,
                        };

                        return parsedContent;
                    break;

                    case 'articleList':
                        let articleTags = undefined;
                        if (data?.fields?.tags?.length) {
                            articleTags = { tags: data?.fields?.tags?.map(item => (item?.id)) };
                        }

                        parsedContent[key] = {
                            contentType: data.contentType,
                            title: data?.fields?.title,
                            ...articleTags,
                        };

                        return parsedContent;
                    break;

                    case 'proximiMap':
                        parsedContent[key] = {
                            id: `${data?.id}-${key}`,
                            contentType: data.contentType,
                        };
                    break;

                    case 'educationPage':
                        if (data.fields.sidebarInfoButton) {
                            data.fields.sidebarInfoButton = this.formatLink(data.fields.sidebarInfoButton);
                        }

                        parsedContent[key] = data;
                    break;

                    default:
                        parsedContent[key] = data;
                    break;
                };
            });

            return parsedContent;
        },
        formatImage(image) {
            return {
                id: image.id,
                src: image.url,
                alt: image.title,
            };
        },
        formatMenuItem(self, item) {
            const result = {
                id: item.id,
                title: item.fields?.entryName,
                appearance: item.fields?.link?.fields?.appearance,
            };

            if (item.fields?.contactInformation && typeof (item.fields.contactInformation) === 'object') {
                result.contactInformation = item.fields?.contactInformation?.map(v => v.fields?.value);
            }

            if (item.fields?.link?.fields?.url?.products && Object.values(item.fields?.link?.fields?.url?.products).length > 0) {
                result.url = Object.values(item.fields?.link?.fields?.url?.products)[0].pagePath;
            } else if (item.fields?.link?.fields?.url?.fields?.pagePath) {
                result.url = item.fields?.link?.fields?.url?.fields?.pagePath;
            } else if (item.fields?.link?.fields?.url?.contentType === 'category' && this.parseUrl(item.fields?.link?.fields?.url)?.url.length > 0) {
                result.url = this.parseUrl(item.fields?.link?.fields?.url)?.url;
            }

            if (typeof (item.fields?.children) === 'object') {
                result.children = [];

                Object.values(item.fields?.children).forEach(child => {
                    result.children.push(this.formatMenuItem(self, child));
                });
            }

            return result;
        },
        flattenObject(obj) {
            // Skip fields we do not want to flatten
            const skipFlatten = ['links', 'image', 'buttons'];
            const flattened = {};

            Object.keys(obj).forEach(key => {
                if (key === 'buttons') {
                    flattened[key] = this.formatButtons(obj[key]);
                } else if (key === 'image') {
                    flattened[key] = this.formatImage(obj[key]);
                } else if (typeof obj[key] === 'object' && obj[key] !== null && !skipFlatten.includes(key)) {
                    Object.assign(flattened, this.flattenObject(obj[key]));
                } else {
                    flattened[key] = obj[key];
                }
            });

            return flattened;
        },
        formatButtons(buttons) {
            return buttons.map(item => item.contentType === 'button'
                ? this.formatButton(item)
                : this.formatLink(item));
        },
        formatButton(button) {
            return {
                contentType: button.contentType,
                title: button.fields?.title,
                url: button.fields?.url?.fields ?? undefined,
                type: button.fields?.type ?? undefined,
                appearance: button.fields?.appearance,
                primary: button.fields?.appearance === 'primary',
                secondary: button.fields?.appearance === 'secondary',
                white: button.fields?.appearance === 'white',
            };
        },
        formatLink(link) {
            return {
                contentType: link.contentType,
                id: link.id,
                title: link.fields?.title,
                icon: link.fields?.icon,
                appearance: link.fields?.appearance,
                buttonAppearance: link.fields?.buttonAppearance,
                ...this.parseUrl(link?.fields?.url),
            };
        },
        parseUrl(url = {}) {
            const prefix = app.i18n.locale !== app.i18n.defaultLocale ? `${app.i18n.localeProperties.code}/` : '';

            let path = '';
            if (!url || !'contentType' in url) {
                return { url: false };
            }

            if (url.contentType === 'popup') {
                const popup = { ...url?.fields, url: url?.id };
                if (popup?.button?.id) {
                    popup.button = this.formatLink(popup.button);
                }

                return {
                    urlType: url.contentType,
                    popup,
                };
            }

            if (url.contentType === 'standardPage') {
                path = `${prefix}${url?.fields?.pagePath}`;
            } else if (url.contentType === 'externalUrl') {
                path = url?.fields?.url;
            } else if (url.contentType === 'product' && this.getFirstFromObject(url, 'products')) {
                path = this.getFirstFromObject(url, 'products')?.pagePath;
            } else if (url.contentType === 'category' && this.getFirstFromObject(url, 'categories')) {
                path = this.getFirstFromObject(url, 'categories')?.pagePath;
            } else if (url.contentType === 'assetUrl') {
                path = url.fields?.asset?.url;
            } else if (url.contentType === 'articlePage') {
                path = `/artikkeli/${url?.fields?.slug}`;
            } else if (url.contentType === 'educationPage') {
                path = url?.fields?.pagePath;
            }

            return {
                urlType: url.contentType,
                url: path,
            };
        },
        getFirstFromObject(object, property) {
            if (property in object && Object.keys(object[property]).length) {
                return object[property][Object.keys(object[property])[0]];
            }

            return false;
        },
    });
};
