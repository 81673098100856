
import { mapMutations } from 'vuex';
import contentMixin from '~/mixins/content.js';

export default {
    mixins: [contentMixin],
    computed: {
        computedError() {
            if (this.content.filter(item => item.contentType === 'error').length === 0) {
                this.setContent([{
                    contentType: 'error',
                    data: {
                        title: this.$t('content.message.serverErrorTitle'),
                        error: this.$t('content.message.serverErrorMessage'),
                        message: [this.$t('content.message.serverErrorDescription')],
                    },
                }]);
            }

            return this.content;
        },
    },
    methods: {
        ...mapMutations({
            setContent: 'content/setData',
        }),
    },
};
