import { render, staticRenderFns } from "./ProductIllustration.vue?vue&type=template&id=9bb9c96e&scoped=true"
import script from "./ProductIllustration.vue?vue&type=script&lang=js"
export * from "./ProductIllustration.vue?vue&type=script&lang=js"
import style0 from "./ProductIllustration.vue?vue&type=style&index=0&id=9bb9c96e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bb9c96e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductImageSlider: require('/build/src/components/elements/ProductImageSlider.vue').default,Tag: require('/build/src/components/elements/Tag.vue').default})
