
import activityIcon from '~/static/icons/activity-indicator.svg';
export default {
    name: 'ActivityIndicator',
    props: {
        center: {
            type: Boolean,
            default: false,
            required: false,
        },
        text: {
            type: String,
            default: '',
            required: false,
        },
    },
    methods: {
        getIcon() {
            return activityIcon;
        },
    },
};
