
import { mapGetters, mapMutations, mapActions } from 'vuex';
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';
import VMIcon from '~/components/elements/VMIcon';

export default {
    name: 'GiftCardSelect',
    directives: {
        clickOutside,
    },
    components: {
        VMIcon,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        ...mapGetters({
            giftCardValues: 'giftcard/getFields',
            values: 'giftcard/getValues',
        }),
        price() {
            return this.giftCardValues.cardValue;
        },
    },
    created() {
        this.loadValues();
    },
    methods: {
        ...mapMutations({
            setValue: 'giftcard/setValue',
        }),
        ...mapActions({
            loadValues: 'giftcard/loadValues',
        }),
        handleClickOutside() {
            if (this.open) {
                this.open = false;
            };
        },
        valueChange(value) {
            this.price = value;
        },
        doOpen() {
            if (this.open === false) {
                this.internalValues = {
                    ...Object(this.value),
                };
            }
            this.open = !this.open;
        },
        navigate() {
            this.$router.push({ path: '/lahjakortti', hash: '#order-gift-card' });
        },
    },
};
