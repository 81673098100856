
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'ProductGiftcardOrder',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            dialogId: 'giftcard/getDialogId',
        }),
        formFields() {
            return {
                store: 'giftcard',
                action: 'giftcard/register',
                fields: [{
                        dataKey: 'cardValue',
                        type: 'select',
                        label: this.$t('giftcard.fields.value'),
                        validator: ['notEmpty'],
                        labelPosition: 'top',
                        theme: 'white',
                        options: Object.keys(this.product.gift_card_value_options).map(key => ({
                                value: key,
                                label: `${this.product.gift_card_value_options[key]} €`,
                            }),
                        ),
                        cssClass: {
                            col2: true,
                        },
                    }, {
                        dataKey: 'name',
                        label: this.$t('giftcard.fields.name'),
                        validator: ['notEmpty'],
                        labelPosition: 'top',
                        theme: 'white',
                        cssClass: {
                            col5: true,
                        },
                    }, {
                        dataKey: 'message',
                        type: 'textarea',
                        label: this.$t('giftcard.fields.message'),
                        labelPosition: 'top',
                        theme: 'white',
                        maxlength: 150,
                        cssClass: {
                            col5: true,
                        },
                    }, {
                        dataKey: 'email',
                        label: this.$t('giftcard.fields.email'),
                        validator: ['notEmpty', 'email'],
                        labelPosition: 'top',
                        theme: 'white',
                        cssClass: {
                            col5: true,
                        },
                    }, {
                        dataKey: 'confirmEmail',
                        label: this.$t('giftcard.fields.confirmEmail'),
                        validator: [
                            'notEmpty',
                            'email',
                            { type: 'equals', field: ['giftcard', 'email'] },
                        ],
                        labelPosition: 'top',
                        theme: 'white',
                        cssClass: {
                            col5: true,
                        },
                    }, {
                        dataKey: 'image',
                        type: 'imageSelect',
                        title: this.$t('giftcard.fields.image'),
                        options: this.imageSelection,
                        validator: ['notEmpty'],
                        theme: 'white',
                    }, {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'btn-submit': true,
                            'btn-primary': true,
                        },
                        label: this.$t('giftcard.fields.submit'),
                    },
                ],
            };
        },
        imageSelection() {
            return Object.entries(this.product).filter((key, value) => {
                if (String(key).match(/^gift_card_image/g) && value) {
                    return true;
                }

                return false;
            })
            .map(v => {
                const target = v[1].conversions.find(c => c.name === '600');

                return {
                    value: v[0],
                    source: target.url,
                };
            }) ?? [];
        },
    },
    mounted() {
        this.setDialogId(this.$util.uuidv4());
    },
    methods: {
        ...mapActions({
            register: 'giftcard/register',
        }),
        ...mapMutations({
            setDialogId: 'giftcard/setDialogId',
            setDialogOpen: 'modal/setOpen',
            clearCart: 'cart/clear',
        }),
        process() {
            this.clearCart();
            this.register();
        },
    },
};
