import { render, staticRenderFns } from "./EducationPaymentPage.vue?vue&type=template&id=4a3559e2&scoped=true"
import script from "./EducationPaymentPage.vue?vue&type=script&lang=js"
export * from "./EducationPaymentPage.vue?vue&type=script&lang=js"
import style0 from "./EducationPaymentPage.vue?vue&type=style&index=0&id=4a3559e2&prod&lang=scss&scoped=true"
import style1 from "./EducationPaymentPage.vue?vue&type=style&index=1&id=4a3559e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3559e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default,ActivityIndicator: require('/build/src/components/elements/ActivityIndicator.vue').default,Button: require('/build/src/components/elements/Button.vue').default,VMCheckbox: require('/build/src/components/elements/Form/VMCheckbox.vue').default})
