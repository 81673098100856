
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'FilterSelector',
    computed: {
        ...mapGetters({
            loading: 'product/getAvailabilityLoading',
            product: 'product/getProduct',
            values: 'product/getFilter',
        }),
        filtersSelected() {
            const filterValues = [];
            this.product.filters.forEach(filter => {
                switch (filter.type) {
                    case 'checkbox':
                        if (this.values[filter.name].length) {
                            this.values[filter.name].forEach(value => {
                                filterValues.push({
                                    type: filter.type,
                                    label: filter.options.find(option => option.value === value).title,
                                    value: [value],
                                    name: filter.name,
                                    scope: filter.scope,
                                });
                            });
                        }
                    break;

                    case 'amount':
                        if (this.values.amount > 1) {
                            filterValues.push({
                                type: filter.type,
                                label: `${this.values[filter.name]} ${filter.unit}`,
                                name: filter.name,
                                value: 1,
                                scope: filter.scope,
                            });
                        }
                    break;

                    case 'radio':
                        if (this.values[filter.name]) {
                            filterValues.push({
                                type: filter.type,
                                label: filter.options.find(option => option.value === this.values[filter.name]).title,
                                name: filter.name,
                                scope: filter.scope,
                            });
                        }
                    break;
                }
            });

            return filterValues;
        },
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        ...mapMutations({
            resetFilters: 'product/resetFilters',
        }),
        doFilterReset() {
            this.resetFilters(this.product.filters);
            this.loadAvailability();
        },
        select(filter) {
            this.$emit('input', filter);
        },
    },
};
