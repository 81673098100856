import { render, staticRenderFns } from "./DiscountCode.vue?vue&type=template&id=05630f60&scoped=true"
import script from "./DiscountCode.vue?vue&type=script&lang=js"
export * from "./DiscountCode.vue?vue&type=script&lang=js"
import style0 from "./DiscountCode.vue?vue&type=style&index=0&id=05630f60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05630f60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMInput: require('/build/src/components/elements/Form/VMInput.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
