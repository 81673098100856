import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    loading: false,
    step: 1,
    token: '',
    verifyNotification: {},
    fields: {
        email: '',
        password: '',
    },
    redirect: null,
});

export const mutations = {
    setPayload(state, payload) {
        if (typeof(payload) === 'object') {
            Object.keys(payload).forEach(value => {
                if (typeof(payload[value]) === typeof(state[value])) {
                    state[value] = payload[value];
                } else {
                    throw new Error(`Could not set value on store login state ${value}`);
                }
            });
        }
    },
    setRedirect(state, value) {
        state.redirect = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    ...storeForm.mutations,
};

export const actions = {
    async email(ctx) {
        if (ctx.state.loading) return;

        const { email } = ctx.state.fields;

        // Set modal login forms disabled during this API call
        this.commit('user/setLoading', true);

        // Do not allow to run this API call multiple times at the same time
        this.commit('login/setLoading', true);

        /* Reset the email status to the default value, so the status change
         * can be detected later */
        this.commit('user/setEmailStatus', null);

        const result = await this.$axios.request({
            method: 'post',
            url: '/api/v2/exists',
            data: { email },
        });

        this.commit('user/setLoading', false);
        this.commit('login/setLoading', false);

        /* If customer email doesn't exist. Save the email to registration
         * field, for the registration step that comes next in modal login. */
        if (!result.data.data.account.exists) {
            this.commit('registration/setValue', { field: 'email', value: email });
        }

        this.commit('user/setEmailStatus', result.data.data.account.exists);
    },
    async login(ctx) {
        try {
            const { email, password } = ctx.state.fields;

            const { data } = await this.$axios.request({
                method: 'post',
                url: '/api/v2/login',
                data: {
                    email,
                    password,
                },
            });
            await this.dispatch('homeowner/apartments/load');

            if (data.message === 'unverified') {
                this.dispatch('messages/setMessage', {
                    type: 'success',
                    message: this.app.i18n.t('login.message.verify'),
                });
            } else {
                this.commit('user/assignUserData', data);

                if (this.$cookies.get('loginRedirect')) {
                    this.dispatch('messages/setMessage', {
                        type: 'success',
                        message: `${this.app.i18n.t('login.message.signon')}. ${this.app.i18n.t('login.message.redirect')}...`,
                    });

                    const redirect = this.$cookies.get('loginRedirect');
                    this.$cookies.remove('loginRedirect');
                    this.$router.push(`.${redirect}`);
                } else {
                    this.dispatch('messages/setMessage', {
                        type: 'success',
                        message: this.app.i18n.t('login.message.signon'),
                    });

                    if (!ctx.rootGetters['user/completedRegistration']) {
                        this.$router.push(this.$paths.getPath('profile'));
                    } else if (this.$router.app._route.fullPath !== this.$paths.getPath('checkout')) { // eslint-disable-line no-underscore-dangle
                        this.$router.push(ctx.state.redirect ? ctx.state.redirect : './');
                    }
                }
                this.commit('user/setLoggedIn', true);
                this.commit('login/setRedirect', null);
                this.$gtm.push({
                    event: 'login',
                });
            }
        } catch (e) {
            console.error(e);
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('login.message.failed'),
            });
        }
    },
    async googleIdentity(ctx) {
        try {
            await this.app.$auth.loginWith('google');
        } catch (e) {
            console.error(e);
        }
    },
    async sendVerification(ctx, payload) {
        try {
            const response = await this.$axios.request({
                method: 'post',
                url: this.$paths.getEndpoint('verify'),
                data: payload,
            });
            this.commit('login/setPayload', {
                verifyNotification: {
                    type: 'success',
                    message: this.app.i18n.t('registration.message.verificationSuccess'),
                },
            });
        } catch (e) {
            this.commit('login/setPayload', {
                verifyNotification: {
                    type: 'error',
                    message: this.app.i18n.t('registration.message.verificationFailed'),
                },
            });
        }
    },
    async logout(ctx) {
        try {
            await this.$axios.request({
                method: 'get',
                url: this.$paths.getEndpoint('logout'),
            });
            this.$storage.removeUniversal('user');
            this.commit('user/reset');
            this.commit('user/setLoggedIn', false);
            this.dispatch('messages/setMessage', {
                type: 'success',
                message: this.app.i18n.t('login.message.signoff'),
            });
            this.$router.push(this.$paths.getPath('landingPage'));
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('login.message.logoutFailed'),
            });
        }
    },
};

export const getters = {
    ...storeForm.getters,
    getStep: state => state.step,
    getVerifyNotification: state => state.verifyNotification,
    getRedirect: state => state.redirect,
};
