import { render, staticRenderFns } from "./VMAccordion.vue?vue&type=template&id=f63be840&scoped=true"
import script from "./VMAccordion.vue?vue&type=script&lang=js"
export * from "./VMAccordion.vue?vue&type=script&lang=js"
import style0 from "./VMAccordion.vue?vue&type=style&index=0&id=f63be840&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f63be840",
  null
  
)

export default component.exports