
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'EventCalendar',
        mixins: [breakpoints],
        async fetch() {
            try {
                const calendarEvents = await this.$axios.request({
                    method: 'get',
                    url: '/proxy/vm-view/v1/content/calendar-events',
                    // params: {
                    //     locale: this.app.i18n.locale,
                    // },
                });

                this.$store.commit('calendar/setData', calendarEvents?.data);
            } catch (e) {
                console.error(e);
            }
        },
    };
