
import breakpoints from '@/mixins/breakpoints';
import htmlScrollLockMixin from '~/mixins/htmlScrollLockMixin';
export default {
    mixins: [ breakpoints, htmlScrollLockMixin ],
    props: {
        mainImage: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        images: {
            type: Array,
            required: false,
            default: () => [],
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            active: 0,
            slideIndex: 0,
            modalPopUp: false,
            rightArrowPressed: false,
            leftArrowPressed: false,
        };
    },
    computed: {
        showLastButton() {
            return this.active + 1 >= this.images.length;
        },
        computedImages() {
            return this.images.filter((image, index) => index === this.slideIndex);
        },
        iconProperties() {
            if (this.isDesktop) {
                return {
                    size: 'xxxl',
                    icon: 'slider-arrow',
                };
            }

            return {
                size: 'xxl',
                icon: 'slider-arrow',
            };
        },
    },
    mounted() {
        document.addEventListener('keyup', evt => {
            if (evt.key === 'ArrowRight') {
                this.rightArrowPressed = true;
                this.leftArrowPressed = false;
                this.addSlides(1);
            } else if (evt.key === 'ArrowLeft') {
                this.leftArrowPressed = true;
                this.rightArrowPressed = false;
                this.addSlides(-1);
            } else if (evt.key === 'Escape') {
                this.closeModal();
            }
        });
    },
    methods: {
        openModal() {
            this.modalPopUp = true;
            this.$store.commit('setLockHtmlScroll', true);
            this.slideIndex = 0;
        },

        closeModal() {
            this.modalPopUp = false;
            this.$store.commit('setLockHtmlScroll', false);
        },

        addSlides(slideNumber) {
            const { images } = this;
            if (this.slideIndex <= 1 && slideNumber === -1) {
                this.slideIndex = 0;

                return;
            }
            if ((this.slideIndex === 1 && slideNumber === -1) || (this.slideIndex === images.length - 1 && slideNumber === 1)) {
                this.slideIndex = 0;

                return;
            }

            this.slideIndex += slideNumber;
        },
    },
};
