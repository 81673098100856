import { DateTime } from 'luxon';
import StoreForm from '~/util/storeFormExtend';

const storeForm = new StoreForm();

const defaultState = () => ({
    fields: {
        id: '',
        apartmentId: '',
        start: DateTime.now(),
        end: DateTime.now().plus({ days: 1 }),
    },
    step: 1,
    loading: false,
});

export default {
    state: () => defaultState(),
    mutations: {
        setPayloadValue(state, payload) {
            Object.keys(payload).forEach(key => {
                if (typeof(payload[key]) === typeof(state[key])) {
                    if (typeof(payload[key]) === 'object') {
                        Object.assign(state[key], payload[key]);
                    } else {
                        state[key] = payload[key];
                    }
                } else {
                    throw new Error('Could not set value on homeowner/ownReservation');
                }
            });
        },
        reset (state) {
            Object.assign(state, defaultState());
        },
        ...storeForm.mutations,
    },
    actions: {
        async save (ctx) {
            let endpoint, method;
            const { id, apartmentId } = ctx.state.fields;
            this.commit('homeowner/blockReservation/loading');

            if (id !== '') {
                method = 'put';
                endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/blocks/${id}`;
            } else {
                method = 'post';
                endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/blocks`;
            }

            try {
                const {
                    apartmentId,
                    start,
                    end,
                } = ctx.state.fields;

                const response = await this.$axios.request({
                    method,
                    url: endpoint,
                    data: {
                        dateStart: start.toFormat('yyyy-MM-dd'),
                        dateEnd: end.toFormat('yyyy-MM-dd'),
                    },
                });

                await this.dispatch('homeowner/apartment/load', apartmentId);

                this.commit('homeowner/blockReservation/setPayloadValue', {
                    step: 2,
                });

                return true;
            } catch (e) {
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('homeowner.message.reservationFailed'),
                });
                this.commit('homeowner/blockReservation/loading');
                this.commit('dsmodal/setOpen', false);
            };

            return false;
        },

    },
    getters: {
        step: state => state.step,
        ...storeForm.getters,
    },
};
