
import { mapGetters, mapMutations } from 'vuex';

export default {
    props: {
        name: {
            type: [ String ],
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            open: 'dsmodal/open',
        }),
    },
    mounted() {
        window.addEventListener('keyup', e => {
            if (this.open && (e.key === 'Escape' || e.key === 'Esc')) {
                this.unsetNameAndClose();
            }
        });
    },
    methods: {
        ...mapMutations({
            setName: 'dsmodal/setName',
            unsetNameAndClose: 'dsmodal/unsetNameAndClose',
        }),
        emitBackdropClick(event) {
            if (event.target.id === 'dsModal') {
                this.$emit('backdropClick');
            }
        },
    },
};
