
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'CategoryProduct',
    props: {
        layout: {
            type: String,
            required: false,
            default: 'product',
        },
    },
    computed: {
        ...mapGetters({
            items: 'category/getFilteredCategoryItems',
        }),
        mappedItems() {
            return this.items.map(item => ({
                link: item.product?.attributes?.product_pagePath ?? item.category.attributes.category_pagePath ?? '',
                image: item.mainImage ?? item.category?.mainImage ?? item.product?.mainImage,
                name: item.name ?? item.category?.attributes?.category_marketing_name ?? item.product?.attributes?.marketing_name,
                description: item.attributes?.product_description_short ?? item.category?.attributes?.category_free_text ?? item.product?.attributes?.product_description_short,
                startingPrice: item.product?.attributes?.starting_price_label ?? null,
                button: item.product?.type === 'assortment' ?? '',
            }));
        },
    },
    mounted() {
        this.loadAvailability();
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
    },
};
