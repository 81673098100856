
    import { mapMutations, mapGetters } from 'vuex';
    import breakpoints from '~/mixins/breakpoints';

    let components = {};
    if (process.browser) {
        const Calendar = require('~/components/blocks/EventCalendar/WeeklyCalendar').default;
        components = {
            Calendar,
        };
    }

    export default {
        name: 'EventCalendarDesktop',
        components,
        mixins: [breakpoints],
        data() {
            const timezone = 'Europe/Moscow';
            const masks = { weekdays: 'WWW', title: 'MMMM' };

            return {
                masks,
                timezone,
            };
        },
        computed: {
            ...mapGetters({
                datePickerData: 'calendar/getDatePickerData',
                storeSelectedDate: 'calendar/getSelectedDate',
            }),
            selectedDate: {
                get() {
                    return this.storeSelectedDate;
                },
                set(value) {
                    if (value instanceof Date) {
                        this.setSelectedDate(value.getTime());
                    }
                },
            },
        },
        methods: {
            ...mapMutations({
                setSelectedDate: 'calendar/setSelectedDate',
            }),
            setToday() {
                this.setSelectedDate(Date.now());
                this.$refs.datepicker.move(new Date());
            },
        },
    };
