import bpsStore from '@lamiaoy/st1-ds-components/src/modules/breakpoints/store/breakpoints';
const options = JSON.parse('{"namespace":"breakpoints"}');
const { namespace } = options;

export default ({ store }, inject) => {
    store.registerModule(
        namespace,
        bpsStore(options),
        {
            preserveState: Boolean(store.state[namespace]),
        },
    );
};
