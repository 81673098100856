
export default {
    name: 'Search',
    props: {
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
