
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'LoginForm',
    props: {
        fields: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            verifyNotification: 'login/getVerifyNotification',
        }),
        formFields() {
            return {
                title: this.fields?.title,
                description: this.fields?.description,
                store: 'login',
                action: 'login/login',
                fields: [{
                        dataKey: 'email',
                        label: this.$t('login.fields.email'),
                        validator: ['notEmpty', 'email'],
                    }, {
                        dataKey: 'password',
                        label: this.$t('login.fields.password'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            { type: 'minLength', value: 6 },
                        ],
                    }, {
                        type: 'link',
                        contentType: 'link',
                        title: this.$t('login.fields.forgotPassword'),
                        cssClass: {
                            'col2': true,
                            'justify-end': true,
                            'size-s': true,
                        },
                        link: {
                            url: this.$paths.getPath('resetPassword'),
                        },
                    }, {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('login.fields.loginButton'),
                    }, {
                        type: 'link',
                        contentType: 'link',
                        title: this.$t('login.fields.registerLink'),
                        cssClass: {
                            registerLink: true,
                        },
                        link: {
                            url: this.$paths.getPath('registration'),
                        },
                    },
                ],
            };
        },
    },
    mounted() {
        if (Object.keys(this.verifyNotification).length > 0) {
            this.notify(this.verifyNotification);
            this.setLogin({
                verifyNotification: {},
            });
        }
    },
    methods: {
        ...mapActions({
            notify: 'messages/setMessage',
            logout: 'login/logout',
        }),
        ...mapMutations({
            setLogin: 'login/setPayload',
        }),
    },
};
