const defaultState = () => ({
	name: '',
	open: false,
	showCloseButton: false,
	closeOnBackdropClick: true,
});

export default {
	namespaced: true,
	state: () => defaultState(),
	mutations: {
		setName(state, name) {
			state.name = name;
		},
		setOpen(state, open) {
			state.open = open;
		},
		setNameAndOpen(state, name) {
			state.name = name;
			state.open = true;
		},
		unsetNameAndClose(state) {
			state.name = '';
			state.open = false;
		},
		setShowCloseButton(state, showCloseButton) {
			state.showCloseButton = showCloseButton;
		},
		setCloseOnBackdropClick(state, closeOnBackdropClick) {
			state.closeOnBackdropClick = closeOnBackdropClick;
		},
		reset(state) {
			Object.assign(state, defaultState());
		},
	},
	getters: {
		name: state => state.name,
		open: state => state.open,
		showCloseButton: state => state.showCloseButton,
		closeOnBackdropClick: state => state.closeOnBackdropClick,
	},
};
