
export default {
    props: {
        dataKey: {
            type: [String],
            required: false,
            default: '',
        },
        type: {
            type: [String],
            required: false,
            default: 'text',
        },
        cssClass: {
            type: [String, Array, Object],
            required: false,
            default: undefined,
        },
        validator: {
            type: [Array],
            required: false,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
        value: {
            type: [Number, String, Boolean, Array, Object],
            required: false,
            default: null,
        },
        commit: {
            type: [Function],
            required: false,
            default: null,
        },
        error: {
            type: [String],
            required: false,
            default: null,
        },
    },
};
