
export default {
    name: 'Pagination',
    props: {
        offset: {
            type: Number,
            required: false,
            default: 0,
        },
        total: {
            type: Number,
            required: false,
            default: 0,
        },
        size: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    computed: {
        pages() {
            const pages = [];

            for (let i = 0; i < this.total/this.size; i++) {
                pages.push(i+1);
            }

            return pages;
        },
    },
    methods: {
        navigate(offset) {
            this.$emit('change', offset);
        },
    },
};
