const messageTimeout = 5000;

function getId() {
    return Math.round(Math.random() * 1000000);
}

export const state = () => ({
    messages: [],
    timeouts: [],
});

export const mutations = {
    setStateMessage(state, payload) {
        if (typeof(payload?.type) !== 'string') {
            payload.type = 'error';
            payload.id = getId();
        }
        state.messages.push(payload);
    },
    setTimeout(state, payload) {
        state.timeouts.push(payload);
    },
    removeMessage(state, index) {
        if (typeof(index) !== 'number' && state.messages.length) {
            state.messages.shift();
            if (state.timeouts.length) {
                state.timeouts.shift();
            }
        } else {
            window.clearTimeout(state.timeouts[index]);
            state.timeouts.splice(index, 1);
            state.messages.splice(index, 1);
        }
    },
};

export const actions = {
    setMessage({ commit }, message) {
        commit('setStateMessage', { id: getId(), ...message });
        if (process.server === false) {
            const item = setTimeout(() => {
                commit('removeMessage');
            }, messageTimeout);
            commit('setTimeout', item);
        }
    },
};

export const getters = {
    messages: state => state.messages,
};
