
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'ContactCards',
        mixins: [breakpoints],
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            displayImage: {
                type: Boolean,
                required: true,
            },
            cards: {
                type: Array,
                required: true,
            },
            seeMoreLink: {
                title: {
                    type: String,
                    required: true,
                },
                url: {
                    type: String,
                    required: true,
                },
                appearance: {
                    type: String,
                    required: false,
                },
                type: [Object, Boolean],
                required: false,
                default: false,
            },
        },
        computed: {
            hasTitle() {
                return this?.title?.length;
            },
        },
    };
