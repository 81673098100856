
    import { addClassNames } from '~/helpers/component';
    import fieldMixin from '~/mixins/widget';

    export default {
        name: 'ImageHalfContentHalf',
        mixins: [fieldMixin],
        props: {
            contentType: {
                type: String,
                required: false,
                default: '',
            },
            text: {
                type: Object,
                required: false,
                default: () => ({}),
            },
            textColor: {
                type: String,
                required: false,
                default: '',
            },
            image: {
                src: {
                    type: String,
                    required: true,
                    default: '',
                },
                alt: {
                    type: String,
                    required: false,
                    default: 'Image',
                },
                type: Object,
                required: false,
                default: () => ({}),
            },
            buttons: {
                type: Array,
                required: false,
                default() {
                    return [];
                },
            },
            assetPosition: {
                type: String,
                required: false,
                default: '',
            },
            isBoxed: {
                type: Boolean,
                required: false,
                default: false,
            },
            youtubeVideoId: {
                type: String,
                required: false,
                default: '',
            },
        },
        computed: {
            gridClassNames() {
                const gridClassNames = {
                    'page-grid': true,
                    'without-gap': true,
                    'without-margin': true,
                };

                return addClassNames(gridClassNames);
            },
            widgetClassNames() {
                const widgetClassNames = {
                    'img-float-left': this.assetPosition === 'left',
                    'img-float-right': this.assetPosition === 'right',
                    'text-black': this.textColor === 'black',
                    'text-blue': this.textColor === 'blue',
                };

                return addClassNames(widgetClassNames);
            },
            getSrc() {
                return `https://www.youtube.com/embed/${this.youtubeVideoId}?rel=0&showinfo=0`;
            },
            hasButtons() {
                return this.buttons?.length;
            },
        },
        methods: {
            textHas(property) {
                return 'fields' in this.text && this.text.fields && property in this.text.fields && this.text.fields[property];
            },
        },
    };
