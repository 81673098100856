import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=421f406a&scoped=true"
import script from "./ArticleList.vue?vue&type=script&lang=js"
export * from "./ArticleList.vue?vue&type=script&lang=js"
import style0 from "./ArticleList.vue?vue&type=style&index=0&id=421f406a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421f406a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActivityIndicator: require('/build/src/components/elements/ActivityIndicator.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default})
