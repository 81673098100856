
import { addClassNames } from '~/helpers/component';

export default {
    props: {
        small: {
            type: Boolean,
            required: false,
        },
        large: {
            type: Boolean,
            required: false,
        },
        full: {
            type: Boolean,
            required: false,
        },
        primary: {
            type: Boolean,
            required: false,
        },
        secondary: {
            type: Boolean,
            required: false,
        },
        transparent: {
            type: Boolean,
            required: false,
        },
        transparentWhite: {
            type: Boolean,
            required: false,
        },
        transparentDark: {
            type: Boolean,
            required: false,
        },
        white: {
            type: Boolean,
            required: false,
        },
        plain: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        align: {
            type: String,
            default: 'left',
            required: false,
        },
        url: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        classNames() {
            const classNames = {
                'btn': true,
                'btn-small': this.small,
                'btn-large': this.large,
                'btn-full': this.full,
                'btn-primary': this.primary,
                'btn-secondary': this.secondary,
                'btn-transparent': this.transparent,
                'btn-transparent-white': this.transparentWhite,
                'btn-transparent-dark': this.transparentDark,
                'btn-white': this.white,
                'btn-plain': this.plain,
            };

            return addClassNames(classNames);
        },

        classNamesBtnHolder() {
            const classNamesBtnHolder = {
                'btn-holder': true,
                'btn-left': this.align === 'left',
                'btn-right': this.align === 'right',
                'btn-center': this.align === 'center',
            };

            return addClassNames(classNamesBtnHolder);
        },
    },
    methods: {
        btnClick(event) {
            if (this.url.match(/^http|https/)) {
                window.location.href=this.url;
            } else if (this.url) {
                if (this.$i18n.locale === 'en') {
                    this.$router.push(this.url.replace(/^en\//, ''));
                } else {
                    this.$router.push(this.url);
                }
            }
        },
        clickHandle(event) {
            if (this.url) {
                return this.btnClick(event);
            }

            return this.$emit('click', event);
        },
    },
};
