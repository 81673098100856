
    export default {
        name: 'VMAccordionItem',
        props: {
            name: {
                type: String,
                default: '',
                required: false,
            },
            smallType: {
                type: Boolean,
                default: false,
                required: false,
            },
            firstActive: {
                type: String,
                default: '',
                required: false,
            },
            selectedAccordionTab: {
                type: String,
                default: '',
                required: false,
            },
        },
        data() {
            return {
                active: false,
            };
        },
        mounted() {
            if (this.firstActive?.length && this.firstActive === this.name) {
                this.active = true;
            }
        },
        methods: {
            toggle(event) {
                this.$parent.$children.forEach((item, index) => {
                    if (item.active && item.name !== event) {
                        item.active = false;
                    }
                    if (item.name === event) {
                        this.active = !this.active;
                    }
                });
            },
            enter(el) {
                const { width } = getComputedStyle(el);
                el.style.width = width;
                el.style.position = 'absolute';
                el.style.visibility = 'hidden';
                el.style.height = 'auto';

                const { height } = getComputedStyle(el);
                el.style.width = null;
                el.style.position = null;
                el.style.visibility = null;
                el.style.height = 0;

                // Force repaint to make sure the animation is triggered correctly.
                getComputedStyle(el).height;

                requestAnimationFrame(() => {
                    el.style.height = height;
                });

                if (this.selectedAccordionTab) {
                    this.$emit('clickedAccordion', this.selectedAccordionTab);
                }

            },
            afterEnter(el) {
                el.style.height = 'auto';
            },
            leave(el) {
                const { height } = getComputedStyle(el);
                el.style.height = height;

                // Force repaint to make sure the animation is triggered correctly.
                getComputedStyle(el).height;

                requestAnimationFrame(() => {
                    el.style.height = 0;
                });
            },
        },
    };
