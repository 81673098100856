import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=5ce41728&scoped=true"
import script from "./RegisterForm.vue?vue&type=script&lang=js"
export * from "./RegisterForm.vue?vue&type=script&lang=js"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=5ce41728&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce41728",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMForm: require('/build/src/components/elements/VMForm.vue').default,DSHeightTransition: require('/build/node_modules/@lamiaoy/st1-ds-components/src/components/Components/HeightTransition/HeightTransition.vue').default})
