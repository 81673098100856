export const state = () => ({
    lockHtmlScroll: false,
});

export const mutations = {
    setLockHtmlScroll(state, lockHtmlScroll) {
        state.lockHtmlScroll = lockHtmlScroll;
    },
};

export const getters = {
    lockHtmlScroll: state => state.lockHtmlScroll,
};
