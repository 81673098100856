
export default {
    name: 'VMBoolean',
    props: {
        type: {
            type: String,
            required: false,
            default: 'switch',
        },
        dataKey: {
            type: [String],
            required: false,
            default: '',
        },
        value: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.dataKey) {
                    this.$emit('update:value', {
                        dataKey: this.dataKey,
                        value,
                    });
                } else {
                    this.$emit('update:value', value);
                }
            },
        },
    },
    methods: {
        toggle() {
            this.internalValue = !this.internalValue;
        },
    },
};
