
import { mapGetters, mapMutations } from 'vuex';
import { DateTime } from 'luxon';
import Vue from 'vue';
import translate from '~lamiaoy/st1-ds-components/src/mixins/translate';

Vue.mixin(translate);

export default {
    name: 'DefaultLayout',
    data: () => ({
        inited: false,
        debounce: null,
        headerFixed: false,
    }),
    computed: {
        ...mapGetters({
            pageClass: 'content/getPageClass',
            getActivePortal: 'navigation/getActivePortal',
            cartOpen: 'navigation/getCartOpen',
            menuOpen: 'navigation/getMenuOpen',
            legacyModalOpen: 'modal/getOpen',
            modalBackdropExit: 'modal/getBackdropExit',
            navTwoActive: 'navigation/getNavTwoActive',
            isErrorPage: 'navigation/getErrorPage',
            searchActive: 'navigation/getSearchActive',
            cookieConsent: 'navigation/getCookieConsent',
            isFinished: 'cart/isFinished',
            contentLoading: 'content/getLoading',
            modalOpen: 'dsmodal/open',
            modalName: 'dsmodal/name',
            closeOnBackdropClick: 'dsmodal/closeOnBackdropClick',
        }),
        calculateHeader() {
            if (this.headerFixed) {
                return `${document?.querySelector('[role~=banner]')?.getBoundingClientRect().height}px`;
            }

            return '';
        },
    },
    watch: {
        contentLoading(to, from) {
            if (!to) {
                window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
            }
        },
    },
    created() {
        if (this.$storage.getUniversal('user') && Object.keys(this.$storage.getUniversal('user')).length > 0) {
            Object.keys(this.$storage.getUniversal('user')).forEach(key => {
                this.$store.commit('user/setValueFromStorage', {
                    key: 'fields',
                    value: this.$storage.getUniversal('user'),
                });
            });
        };

        let alerts = this.$storage.getUniversal('dismissedAlerts');
        // do things with alerts
        if (alerts && typeof(alerts) === 'object') {
            // clean old alerts
            alerts = alerts
                .filter(alert => alert.ts > DateTime.now().minus({ weeks: 1 })
                .toMillis());
            this.$storage.setUniversal('dismissedAlerts', alerts);
            // pass things to app
            this.$store.commit('navigation/assignDismissedAlerts', alerts.map(alert => alert.id));
        }
    },
    mounted() {
        this.$util.restoreCart();
        if (window) {
            window.addEventListener('scroll', this.onScroll);
            this.initBps({
                window,
            });
        }
        this.$store.commit('navigation/setPayload', { ready: true });
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        ...mapMutations({
            initBps: 'breakpoints/init',
            setModalOpen: 'dsmodal/setOpen',
        }),
        onScroll(event) {
            const y = window.scrollY;

            if (y > 0) {
                this.headerFixed = true;
            } else {
                this.headerFixed = false;
            }
        },
        focusContent() {
            this.$refs.main.$el.focus();
        },
        handleBackgroundClick() {
            if (this.cartOpen) {
                this.$store.commit('navigation/toggleCartOpen');
            }
            if (this.menuOpen) {
                this.$store.commit('navigation/toggleMenuOpen');
            }
            if (this.navTwoActive) {
                this.$store.commit('navigation/setNavTwoActive', []);
            }
            if (this.searchActive) {
                this.$store.commit('navigation/setSearchActive', false);
                this.$store.commit('navigation/toggleSearchOpen');
                this.$store.commit('navigation/toggleSearchPanelOpen');
            }
            if (this.modalOpen && this.modalBackdropExit) {
                this.$store.commit('modal/setOpen', false);
            }
        },
    },
};
