
    export default {
        name: 'Accordion',
        props: {
            title: {
                type: String,
                required: false,
                default: '',
            },
            items: {
                type: Array,
                required: true,
            },
        },
    };
