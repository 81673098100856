import { render, staticRenderFns } from "./ProductImageSlider.vue?vue&type=template&id=909f30d6&scoped=true"
import script from "./ProductImageSlider.vue?vue&type=script&lang=js"
export * from "./ProductImageSlider.vue?vue&type=script&lang=js"
import style0 from "./ProductImageSlider.vue?vue&type=style&index=0&id=909f30d6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909f30d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default,LightBox: require('/build/src/components/elements/LightBox.vue').default})
