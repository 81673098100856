import { render, staticRenderFns } from "./CategoryItems.vue?vue&type=template&id=6c0b05a7&scoped=true"
import script from "./CategoryItems.vue?vue&type=script&lang=js"
export * from "./CategoryItems.vue?vue&type=script&lang=js"
import style0 from "./CategoryItems.vue?vue&type=style&index=0&id=6c0b05a7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0b05a7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateRange: require('/build/src/components/elements/Form/DateRange.vue').default,RadioFilter: require('/build/src/components/elements/Form/RadioFilter.vue').default,CheckboxFilter: require('/build/src/components/elements/Form/CheckboxFilter.vue').default,AmountFilter: require('/build/src/components/elements/Form/AmountFilter.vue').default,CategoryTable: require('/build/src/components/blocks/ProductPage/ProductItems/CategoryTable.vue').default})
