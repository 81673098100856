
export default {
    name: 'AccessibilityFeedback',
    props: {
        fields: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        formFields() {
            return {
                title: this.fields?.title,
                description: this.fields?.description,
                store: 'accessibility',
                action: 'accessibility/submit',
                fields: [{
                        dataKey: 'name',
                        label: this.$t('accessibility.fields.name'),
                    }, {
                        dataKey: 'email',
                        label: this.$t('accessibility.fields.email'),
                    }, {
                        dataKey: 'message',
                        type: 'textarea',
                        label: this.$t('accessibility.fields.feedback'),
                        validator: ['notEmpty'],
                    }, /* {
                        dataKey: 'captcha',
                        type: 'hCaptcha',
                    }, */ {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('accessibility.fields.send'),
                    },
                ],
            };
        },
    },
};
