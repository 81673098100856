import { get, set } from 'lodash';

export default class {
    constructor() {
        this.mutations = {
            loading(state, payload) {
                if (typeof(payload) === 'boolean') {
                    state.loading = payload;

                    return true;
                }
                state.loading = !state.loading;

                return true;
            },
            setValue(state, payload) {
                if (!payload.target) {
                    payload.target = 'fields';
                }
                set(state, `${payload.target}.${payload.field}`, payload.value);
            },
        };
        this.getters = {
            loading: state => state.loading,
            getFields: state => state.fields,
            getField: state => (key, target) => get(state, `${target}.${key}`) ?? null,
            getErrors: state => () => {
                let currentFields;
                if (typeof(state.fields) === 'object') {
                    currentFields = Object.values(state.fields);
                } else if (typeof(state.fields) === 'array') {
                    currentFields = state.fields;
                }

                return currentFields.filter(field => typeof(field.error) === 'string').length;
            },
        };
    };

    getValues(parent, fields) {
        const parsedValues = {};

        Object.keys(fields).forEach(name => {
            parsedValues[name] = fields[name].value;
        });

        return parsedValues;
    };
};
