
export default {
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        cheapestPrice: {
            type: [Number],
            required: false,
            default: 0,
        },
        cheapestPriceDiscount: {
            type: [Number],
            required: false,
            default: 0,
        },
        productIndex: {
            type: [Number],
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            cardPopUp: false,
        };
    },
    computed: {
        mainImage() {
            if (this.product?.product?.mainImage && 'url' in this.product?.product?.mainImage) {
                return this.$util.parseImage(this.product.product.mainImage, 1);
            }

            return false;
        },

        images() {
            const imageUrls = [];
            const product = this.product?.product;
            const attributes = product?.attributes;
            imageUrls.push(this.$util.parseImage(product.mainImage));

            let i = 1;
            while (!!attributes[`secondary_image${i}`]) {
                const v = attributes[`secondary_image${i}`];
                imageUrls.push(this.$util.parseImage(v));

                i++;
            }

            return imageUrls;
        },

        hasOffer() {
            return this.product?.product?.attributes?.has_offer;
        },

        hasOptions() {
            return this.product?.rooms.find(room => room[0].available === true);
        },

        offerDescription() {
            return this.product?.product?.attributes?.offer_description;
        },

        sortedRooms() {
            const productRooms = this.product.rooms;
            const availableProduct = productRooms.filter(categoryItem => categoryItem[0].available === true);
            const unAvailableProduct = productRooms.filter(categoryItem => categoryItem[0].available === false);
            const sortedAvailableProduct = availableProduct.sort((a, b) => (a[0] ? a[0].price : a[1].price) - (b[0] ? b[0].price : b[1].price));

            return [...sortedAvailableProduct, ...unAvailableProduct];
        },

        name() {
            return this.product?.product.attributes?.marketing_name || this.product?.name;
        },

        containerClass() {
            if (this.hasOffer && this.hasOptions) {
                return 'darkgreen';
            } else if (!this.hasOptions) {
                return 'warning-light';
            }

            return '';
        },

        accommodationClass() {
            if (this.hasOffer && this.hasOptions) {
                return 'accommodation-offer';
            } else if (!this.hasOptions) {
                return 'accommodation-options';
            }

            return '';
        },
    },
    methods: {
        productHas(property) {
            if (!this.product?.product?.attributes || typeof this.product.product?.attributes !== 'object') return false;

            return property in this.product.product?.attributes && this.product.product?.attributes[property];
        },
        cardShow() {
            this.cardPopUp = !this.cardPopUp;
            if (!this.cardPopUp) {
                const container = this.$refs[`product-${this.product.product.code}-${this.productIndex}`];
                if (container) {
                    container.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                }
            }
        },

        enter(element) {
            if (process.server) {
                return false;
            }

            const { width } = window.getComputedStyle(element);
            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const { height } = window.getComputedStyle(element);

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            window.getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            if (process.server) {
                return false;
            }
            element.style.height = 'auto';
        },
        leave(element) {
            if (process.server) {
                return false;
            }
            const { height } = window.getComputedStyle(element);
            element.style.height = height;
            window.getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },
    },
};
