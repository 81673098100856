import { find } from 'lodash/collection';
import httpStatus from 'http-status';

export const state = () => ({
    data: [],
    title: '',
    description: '',
    pageClass: [],
    loading: false,
});

export const mutations = {
    setData(state, payload) {
        if (typeof(payload) === 'object') {
            state.data = payload;
        } else {
            state.data = [];
        }
    },
    setTitle(state, payload) {
        state.title = payload;
    },
    setDescription(state, payload) {
        state.description = payload;
    },
    setPageClass(state, payload) {
        state.pageClass = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
};

export const actions = {
    async load(ctx, { route, error }) {
        try {
            let currentRoute;

            const params = {
                locale: this.app.i18n.locale,
            };

            if (route.path.replace(/^\/(sv|en)/, '') === '') {
                currentRoute = '/';
            } else {
                currentRoute = route.path.replace(/^\/(sv|en)/, '');
            }

            if (currentRoute.match(/^\/preview/)) {
                if (currentRoute.match(/^\/preview$/)) {
                    currentRoute = '/';
                } else {
                    currentRoute = currentRoute.replace(/^\/preview/, '');
                }
                params.preview = 1;
            }

            if (currentRoute.length > 1) {
                currentRoute = currentRoute.replace(/^\//, '');
            }

            if (['/demo/product-page', this.app.$paths.getPath('checkout'), this.app.$paths.getPath('profile')].includes(route.path)) {
                return false;
            }

            const contentResponse = await this.$axios.request({
                method: 'get',
                url: this.$paths.getEndpoint('content'),
                params: {
                    ...params,
                    pagePath: currentRoute,
                },
            });

            let content = contentResponse.data?.content?.fields?.body;
            let title = contentResponse.data?.content?.fields?.pageTitle ?? contentResponse.data?.content?.fields?.entryName;
            const description = contentResponse.data?.content?.fields?.metaText;
            const pageClass = [];

            // fire off redirects
            if (content) {
                const redirect = find(content, (part => part.contentType === 'cardList' && part.fields.display.component === 'redirect'));

                if (redirect) {
                    if (process.server) {
                        this.app.context.redirect(
                            httpStatus.TEMPORARY_REDIRECT,
                            redirect.fields.display.url,
                        );
                    } else if (redirect.fields.display.url.replace(/^\//, '') === window.location.href.replace(/(http|https):\/\/[^\/]*\//, '')) {
                        $nuxt.refresh();
                    } else {
                        this.app.context.redirect(
                            httpStatus.TEMPORARY_REDIRECT,
                            redirect.fields.display.url,
                        );
                    }

                    return false;
                }
            }

            if (contentResponse.data?.content?.contentType === 'articlePage') {
                content = [contentResponse.data?.content];
                title = title ?? contentResponse.data?.content?.fields?.title;
                pageClass.push('article');
            } else if (contentResponse.data?.content?.contentType === 'productPage') {
                const body = contentResponse.data?.content?.fields?.body;
                if (body) {
                    const externalData = body.find(x => x.contentType === 'externalData');
                    const marketingName = externalData?.fields?.attributes?.marketing_name;
                    if (marketingName) {
                        title = marketingName;
                    }
                    pageClass.push('product');
                }
            } else if (contentResponse.data?.content?.contentType === 'categoryPage') {
                const body = contentResponse.data?.content?.fields?.body;
                if (body) {
                    const externalData = body.find(x => x.contentType === 'externalData');
                    const marketingName = externalData?.fields?.attributes?.category_marketing_name;
                    if (marketingName) {
                        title = marketingName;
                    }
                    pageClass.push('category');

                    if (externalData.fields?.attributes?.item_layout?.product) {
                        pageClass.push(externalData.fields?.attributes?.item_layout?.product);
                    }
                }
            } else if (contentResponse.data?.content?.contentType === 'educationPage') {
                content = [contentResponse.data?.content];
                title = title ?? contentResponse.data?.content?.fields?.degreeTitle;
                pageClass.push('education');
            }

            if (pageClass.length > 0) {
                ctx.commit('setPageClass', pageClass);
            } else {
                ctx.commit('setPageClass', []);
            }

            this.commit('navigation/setErrorPage', false);
            this.commit('navigation/setLocalizedPaths', contentResponse.data?.localizedPagePaths);
            this.commit('content/setData', this.app.$contentParser.parseContent(content));
            this.commit('content/setTitle', title);
            this.commit('content/setDescription', description);
            const primaryFilters = contentResponse.data?.content?.fields?.primaryFilters;
            if (primaryFilters) {
                this.commit('category/setAvailabilityFilters', primaryFilters);
            } else if (contentResponse?.data?.content?.contentType === 'categoryPage') {
                this.commit('category/setAvailabilityFilters', []);
            }
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
               // console.error(e);
                console.error(e);
            } else {
                console.error(`status: ${e.response?.status}\nmessage: ${e.response?.data?.message}`);
            }
            switch (e.response?.status) {
                case httpStatus.NOT_FOUND:
                    this.commit('navigation/setErrorPage', true);
                    this.commit('content/setData', [{
                        contentType: 'error',
                        data: {
                            title: this.app.i18n.t('content.message.errorTitle'),
                            error: this.app.i18n.t('content.message.errorMessage'),
                            message: this.app.i18n.t('content.message.notFoundMessage'),
                        },
                    }]);
                    if (typeof error === 'function') {
                        error({
                            statusCode: e.response?.status,
                            message: this.app.i18n.t('content.message.errorTitle'),
                        });
                    }
                break;

                default:
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('content.message.loadFailed'),
                    });
                break;
            }
        }
    },
};

export const getters = {
    getData: state => state.data,
    getPageClass: state => state.pageClass.join(' '),
    getTitle: state => state.title,
    getDescription: state => state.description,
    getLoading: state => state.loading,
};
