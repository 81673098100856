
import { mapGetters, mapMutations, mapActions } from 'vuex';
import htmlScrollLockMixin from '~/mixins/htmlScrollLockMixin';

export default {
    name: 'MobileNav',
    mixins: [htmlScrollLockMixin ],
    data() {
        return {
            showLangSelect: false,
        };
    },
    computed: {
        ...mapGetters({
            loggedIn: 'user/getLoggedIn',
            userData: 'user/getUserData',
            searchOpen: 'navigation/getSearchOpen',
            menuOpen: 'navigation/getMenuOpen',
            baseMenuItems: 'navigation/getNavOne',
            menuItems: 'navigation/getNavTwo',
            navTwoActive: 'navigation/getNavTwoActive',
            langState: 'navigation/getLanguageState',
            localizedPaths: 'navigation/getLocalizedPaths',
            isHomeOwner: 'homeowner/apartments/isHomeOwner',
        }),
        languageCode() {
            return this.$i18n.locale === 'fi' ? 'en' : 'fi';
        },
        languages() {
            return [
                { code: 'fi', label: this.$t('lang.fi') },
                { code: 'en', label: this.$t('lang.en') },
                // { code: 'sv', label: this.$t('lang.sv') },
            ];
        },
    },
    methods: {
        ...mapActions({
            logout: 'login/logout',
        }),
        ...mapMutations({
            toggleMenuOpen: 'navigation/toggleMenuOpen',
            setMmActive: 'navigation/setNavTwoActive',
            openLang: 'navigation/toggleLanguage',
        }),
        setMenuOpen() {
            this.toggleMenuOpen();

            if (this.menuOpen) {
                this.$store.commit('setLockHtmlScroll', true);
                document.querySelector('.mobile-nav .menu-content .icon.close').focus();
            }
        },
        closeMenu() {
            this.setMmActive([]);
            this.toggleMenuOpen();
            this.showLangSelect = false;
            this.$refs['menu-link'].focus();
        },
        clickOutside() {
            if (this.menuOpen) {
                this.closeMenu();
            }
        },
        handleFunctionClick(item) {
            switch (item) {
                case 'logout':
                    this.logout();
                break;

                case 'login':
                    this.$router.push(this.$paths.getPath('login'));
                break;

                case 'profile':
                    this.$router.push(this.$paths.getPath('profile'));
                break;
            }
            this.toggleMenuOpen();
        },
        getChildren(items) {
            if (typeof(items) === 'object') {
                return Object.values(items).filter(item => item.children);
            }

                return [];

        },
        getLevel2Items() {
            if (typeof(this.menuItems) === 'object') {
                return this.menuItems
                    .filter(v => typeof(v.children) === 'object')
                    .map((v, k) => v.children)
                    .flat();
            }

                return [];

        },
        setActive(payload, url) {
            if (url) {
                this.$router.push(url);
                this.toggleMenuOpen();
                this.setMmActive([]);
            } else if (payload === 'language') {
                this.toggleLanguageSelector();
            } else {
                this.setMmActive(payload);
            }
        },
        toggleLanguageSelector() {
            this.showLangSelect = !this.showLangSelect;
        },
        switchLocale(langCode) {
            if (langCode === 'sv' || langCode === 'en') {
                if (this.localizedPaths[langCode] === '/') {
                    this.$router.push(`/${langCode}`);
                } else if (this.localizedPaths[langCode]) {
                    this.$router.push(`/${langCode}/${this.localizedPaths[langCode]}`);
                } else {
                    this.$router.push(`/${langCode}`);
                }
            } else if (this.localizedPaths[langCode] === '/') {
                    this.$router.push('/');
                } else {
                    this.$router.push(`/${this.localizedPaths[langCode]}`);
                }
            this.closeMenu();
            this.openLang();
        },
        handleEnter(payload, url) {
            this.setActive(payload, url);
            const selection = payload ?? [];
            switch (selection.length) {
                case 3:
                    document.querySelector(`.mobile-nav .level3-list-${selection[2]} li:nth-child(2) a`)?.focus();
                break;
                case 2:
                    document.querySelector(`.mobile-nav .level2-list-${selection[1]} li:nth-child(2) a`)?.focus();
                break;
                case 1:
                    document.querySelector(`.mobile-nav .level1-list-${selection[0]} li:nth-child(2) a`)?.focus();
                break;
                case 0:
                    document.querySelector('.mobile-nav .level0-list li:first-child a')?.focus();
                break;
            }
        },
        handleTab(event, payload) {
            const selection = payload ?? [];

            if (
                (event.target.classList.contains('back') && event.shiftKey)
                || (event.target.classList.contains('last') && !event.shiftKey)
            ) {
                event.preventDefault();
                switch (selection.length) {
                    case 3:
                        this.setMmActive([...selection.slice(0, 2)]);
                        document.querySelector(`.mobile-nav .level2-list .item-${selection[2]} a`)?.focus();
                    break;

                    case 2:
                        this.setMmActive([selection[0]]);
                        document.querySelector(`.mobile-nav .level1-list .item-${selection[1]} a`)?.focus();
                    break;
                    case 1:
                        this.setMmActive([]);
                        document.querySelector(`.mobile-nav .level0-list .item-${selection[0]} a`)?.focus();
                    break;
                }
            }
        },
        handleTabOut(event) {
            if (
                (event.target.classList.contains('close') && event.shiftKey)
                || (!event.target.classList.contains('close') && !event.shiftKey)
            ) {
                event.preventDefault();
                this.closeMenu();
            }
        },
        isDisabled(item) {
            return typeof(item.children) !== 'object' && !item.url;
        },
    },
};
