
    export default {
        name: 'WysiwygBlock',
        props: {
            content: {
                type: Object,
                required: false,
                default: () => {},
            },
            alignLeft: {
                type: Boolean,
                required: false,
                default: true,
            },
            fontSize: {
                type: String,
                required: false,
                default: '',
            },
        },
    };
