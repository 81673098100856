const httpStatus = require('http-status');
const localeConfig = require('~config/locales.json');

export default function ({ app, $route, $axios, redirect }) {
    $axios.onRequest(config => {
        config.headers['x-locale'] = app.i18n.locale || app.i18n.defaultLocale;
    });
    $axios.onError(error => {
        const code = parseInt(error.response && error.response.status);

        switch (code) {
            case httpStatus.FORBIDDEN:
            case httpStatus.UNAUTHORIZED:
                // add route info as callbackUrl
                redirect(app.$paths.getPath('landingPage'));
            break;
        }
    });
}
