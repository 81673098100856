import { render, staticRenderFns } from "./Forms.vue?vue&type=template&id=b75a3ae2&scoped=true"
import script from "./Forms.vue?vue&type=script&lang=js"
export * from "./Forms.vue?vue&type=script&lang=js"
import style0 from "./Forms.vue?vue&type=style&index=0&id=b75a3ae2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b75a3ae2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/build/src/components/elements/ContentfulImage.vue').default,SearchHeader: require('/build/src/components/elements/SearchHeader.vue').default,RegisterForm: require('/build/src/components/blocks/Forms/RegisterForm.vue').default,LoginForm: require('/build/src/components/blocks/Forms/LoginForm.vue').default,ResetPasswordForm: require('/build/src/components/blocks/Forms/ResetPasswordForm.vue').default,AccessibilityFeedback: require('/build/src/components/blocks/Forms/AccessibilityFeedback.vue').default,NewsletterForm: require('/build/src/components/blocks/Forms/NewsletterForm.vue').default,SearchResults: require('/build/src/components/elements/SearchResults.vue').default})
