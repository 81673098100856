
import VSimplebar from 'simplebar-vue';

export default {
    name: 'VMSelect',
    components: {
        VSimplebar,
    },
    props: {
        dataKey: {
            type: [String],
            required: false,
            default: '',
        },
        header: {
            type: [String],
            required: false,
            default: undefined,
        },
        component: {
            type: [String],
            required: false,
            default: 'input',
        },
        type: {
            type: [String],
            required: false,
            default: 'text',
        },
        cssClass: {
            type: [String, Array, Object],
            required: false,
            default: undefined,
        },
        validator: {
            type: [Array],
            required: false,
            default() {
                return [];
            },
        },
        label: {
            type: [String],
            required: false,
            default: 'Label',
        },
        labelPosition: {
            type: [String],
            required: false,
            default: 'inside',
            validator: value => ['inside', 'top'].indexOf(value) !== -1,
        },
        theme: {
            type: [String],
            required: false,
            default: null,
            validator: value => ['white'].indexOf(value) !== -1,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: [Array, Object],
            required: false,
            default: () => [],
        },
        value: {
            type: [Number, String, Array, Object],
            required: false,
            default: '',
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        commit: {
            type: [Function],
            required: false,
            default: null,
        },
        helpText: {
            type: [String],
            required: false,
            default: null,
        },
        error: {
            type: [String],
            required: false,
            default: null,
        },
    },
    data: () => ({
        active: false,
        open: false,
        focused: 0,
    }),
    computed: {
        computedValue() {
            if (typeof(this.value) === 'object') {
                try {
                    return Object.values(this.options).find(v => JSON.stringify(v) === JSON.stringify(this.value)).label;
                } catch (e) {
                    return this.options[0].label;
                }
            } else if (this.value) {
                return Object.values(this.options).find(v => v.value === this.value).label;
            }

            return '';
        },
    },
    watch: {
        value(to, from) {
            if (this.computedValue !== '') {
                this.active = true;
            }
        },
        focused(to, from) {
            if (this.open) {
                this.$announcer.set(Object.values(this.options)[to].label);
            }
        },
        open(to, from) {
            if (to === true) {
                this.$refs['simplebar-list'].scrollElement.scrollTop = 0;
                this.$announcer.set(Object.values(this.options)[this.focused].label);
            }
        },
    },
    mounted() {
        const focused = this.options.find(o => o.focused);
        if (focused) {
            this.setValue(focused);
        }
    },
    methods: {
        handleKeyDown(event) {
            switch (event.key) {
                case 'ArrowUp':
                    if (this.open) {
                        event.preventDefault();
                        this.focused = this.focused - 1;
                        if (this.options.length > 5) {
                            this.$refs['simplebar-list'].scrollElement.scrollTop = this.focused * 35;
                        }
                        if (this.focused === -1) {
                            this.open = false;
                        }
                    }
                break;
                case 'ArrowDown':
                    if (this.open) {
                        event.preventDefault();
                        this.focused = this.focused + 1;
                        if (this.options.length > 5 && this.focused >= 4) {
                            this.$refs['simplebar-list'].scrollElement.scrollTop = this.focused * 35;
                        }
                        if (this.focused + 1 > Object.values(this.options).length) {
                            this.open = false;
                        }
                    }
                break;
                case 'Enter':
                    if (this.open) {
                        this.setValue(Object.values(this.options)[this.focused]);
                        this.focused = 0;
                        this.open = false;
                    } else {
                        this.open = true;
                        this.focused = 0;
                    }
                break;
            }
        },
        setFocus(event, state) {
            this.active = state;
            if (state === false && this.open) {
                this.setValue(Object.values(this.options)[this.focused]);
                this.focused = 0;
                this.open = false;
            }
        },
        setValue(value) {
            let currentValue;

            if (typeof(value) === 'object' && value.value) {
                currentValue = value.value;
            }
            this.$emit('input', value);
            this.$emit('commit', {
                dataKey: this.dataKey,
                value: currentValue,
            });
        },
    },
};
