import { render, staticRenderFns } from "./HeroContent.vue?vue&type=template&id=644747a0&scoped=true"
import script from "./HeroContent.vue?vue&type=script&lang=js"
export * from "./HeroContent.vue?vue&type=script&lang=js"
import style0 from "./HeroContent.vue?vue&type=style&index=0&id=644747a0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644747a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/build/src/components/elements/ContentfulImage.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default})
