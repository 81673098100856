
export default {
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: '',
        },
        backgroundImage: {
            src: {
                type: String,
                required: false,
                default: '',
            },
            alt: {
                type: String,
                required: false,
                default: 'Image',
            },
            type: Object,
            default: () => ({}),
        },
        priorityBackground: {
            type: String,
            required: false,
            default: '',
        },
        usePriorityBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasOverlay: {
            type: Boolean,
            default: false,
            required: false,
        },
        textAlign: {
            type: String,
            required: false,
            default: '',
        },
        textColor: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        titleType: {
            type: String,
            required: false,
            default: 'H2',
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
        buttons: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        image: {
            src: {
                type: String,
                required: false,
                default: '',
            },
            alt: {
                type: String,
                required: false,
                default: 'Image',
            },
            type: Object,
            default: () => ({}),
        },
        allowCustomTextColor: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        backgroundStyle() {
            if (this.backgroundImage?.url && !this.usePriorityBackground) {
                if (this.hasOverlay) {
                    if (this.textColorClass === 'text-white') {
                        return `background-image: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url('${this.backgroundImage.url}?w=900')`;
                    }

                    return `background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2)), url('${this.backgroundImage.url}?w=900')`;
                }

                return `background-image: url('${this.backgroundImage.url}?w=900')`;
            }

            return '';
        },
        backgroundClass() {
            if ('url' in this.backgroundImage && this.backgroundImage?.url && !this.usePriorityBackground) {
                return 'background-image';
            } else if (!this.usePriorityBackground) {
                return `background-${this.backgroundColor}`;
            }

                return 'background';
        },
        bgOverlayClass() {
            if (this.hasOverlay && !this.usePriorityBackground) {
                return 'background-overlay';
            }

                return '';
        },
        textColorClass() {
            if (this.allowCustomTextColor) {
                return `text-${this.textColor}`;
            } else if ('url' in this.backgroundImage && this.backgroundImage?.url && !this.usePriorityBackground) {
                return `text-${this.textColor}`;
            } else if (this.backgroundColor === 'white' || this.backgroundColor === 'lightgray') {
                return 'text-black';
            } else if (this.usePriorityBackground && this.priorityBackground === 'white' ) {
                return 'text-black';
            }

            return 'text-white';
        },
        textAlignClass() {
            return `text-${this.textAlign}`;
        },
    },
    methods: {
        isPrimary(button) {
            return button.appearance === 'button';
        },
    },
};
