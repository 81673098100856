
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime, Duration } from 'luxon';
export default {
    name: 'DiscountCodePopup',
    props: {
        showIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            componentId: null,
            modalId: null,
        };
    },
    computed: {
        ...mapGetters({
            discountSubmitted: 'cart/getDiscountSubmitted',
            modalOpen: 'modal/getOpen',
        }),
    },
    created() {
        this.componentId = this.$util.uuidv4();
        this.modalId = `discount-popup-${this.$util.uuidv4()}`;
    },
    mounted() {
        document.onreadystatechange = () => {
            if (this.discountSubmitted) {
                const values = localStorage.getItem('cart');
                const promotionCodeValues = JSON.parse(values);
                const now = DateTime.now().toMillis();
                const oneDay = Duration.fromObject({ days: 1 }).toMillis();
                const enteredDate = DateTime.fromISO(promotionCodeValues?.submittedDate).toMillis();
                const compareDatesBoolean = (now - enteredDate) > oneDay;

                if (enteredDate && compareDatesBoolean) {
                    this.setDiscountCode('');
                    this.setDiscountSubmitted(false);
                    this.$emit('submit');
                    this.getTotals();
                }
            }
        };
    },
    methods: {
        ...mapMutations({
            setId: 'modal/setId',
            setOpen: 'modal/setOpen',
            setReturnElement: 'modal/setReturnElement',
            setDiscountCode: 'cart/setDiscountCode',
            setDiscountSubmitted: 'cart/setDiscountSubmitted',
        }),
        ...mapActions({
            getTotals: 'cart/getTotals',
        }),
        handleSubmit() {
            this.$emit('submit');
            this.setOpen(false);
        },
        openPopup() {
            this.setId(this.modalId);
            this.setOpen(true);
            this.setReturnElement(`.discount-popup-${this.componentId} a:first-child`);
        },
    },
};
