export default function({ app, route, store, req, redirect }) {
    let url, query;

    // fetch same variables regardless of client/server
    if (process.server) {
        const serverUrl = req._parsedUrl; // eslint-disable-line no-underscore-dangle
        const searchParams = new URLSearchParams(serverUrl.query);
        query = {};
        searchParams.forEach((value, key) => {
            query[key] = value;
        });

        url = serverUrl.pathname;
    } else {
        url = route.path;
        query = route.query; // eslint-disable-line prefer-destructuring
    }
    // redirect reset password
    if (url === app.$paths.getPath('login') && query.mode === 'resetPassword') {
        redirect(`${app.$paths.getPath('resetPassword')}?${Object.keys(query).map(k => [k, query[k]].join('=')).join('&')}&step=3`); // eslint-disable-line newline-per-chained-call
    // assign store variables for verification
    } else if (url === app.$paths.getPath('login') && query.mode === 'verifyEmail') {
        store.dispatch('login/sendVerification', {
            code: query.oobCode,
        });
    // assign store variables for reset password
    } else if (url === app.$paths.getPath('resetPassword') && query.mode === 'resetPassword') {
        if (query.service) {
            store.commit('resetPassword/setPayload', {
                service: query.service,
                step: Number(query.step) ?? 1,
                token: query.oobCode ?? query.token,
            });
        }
    // reset store on login & profile
    } else if (url === app.$paths.getPath('login') || url === app.$paths.getPath('profile')) {
        store.commit('resetPassword/setPayload', {
            step: 1,
            token: '',
        });
    }
};
