
import { DateTime } from 'luxon';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'ProductItems',
    data() {
        return {
            calendarDebounce: null,
            updatedFiltersData: false,
        };
    },
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            filter: 'product/getFilter',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            persons: 'product/getPersons',
            availabilityLoading: 'product/getAvailabilityLoading',
            categoryItems: 'category/getCategoryItems',
            primaryFilters: 'category/getPrimaryFilters',
            categoryHasPersons: 'category/categoryHasPersons',
            categoryHasDateSelect: 'category/categoryHasDateSelect',
            filters: 'category/getSecondaryFilters',
        }),
        computedDates: {
            get() {
                return {
                    start: this.fromDate,
                    end: this.toDate,
                };
            },
            set(value) {
                this.dateChange(value);
            },
        },
        equipments() {
            const equipments = this.filters.filter(value => value.name === 'equipment');

            return equipments[0];
        },
        equipmentsData() {
            return this.equipments?.selectedOptions;
        },
        selectedDates: {
            get() {
                return [this.fromDate, this.toDate];
            },
            set(values) {
                this.setDates({ start: DateTime.fromJSDate(values[0]), end: DateTime.fromJSDate(values[1]) });
                this.loadAvailability();
            },
        },
        getCalendarLabels() {
            switch (this.product.classification) {
                case 'Course':
                    return {
                        from: this.$i18n.t('product.classification.course.calendar.from'),
                        to: this.$i18n.t('product.classification.course.calendar.to'),
                    };
                    break;

                default:
                case 'Accomodation':
                    return {
                        from: this.$i18n.t('product.classification.accommodation.calendar.from'),
                        to: this.$i18n.t('product.classification.accommodation.calendar.to'),
                    };
                    break;
            }
        },
        PromoCodeAvailability() {
            const product = this.product.product_pagePath;
            if ( product === 'tennis_sisalla' || product === 'ulkotennis_massa' || product === 'ulkotennis_greenset' || product === 'padel_sisalla' ||product === 'e-fatbike' || product === 'padel_ulkona' || product === 'sulkapallokentta' || product === 'sulkapallokentat' || product === 'squashkentta' || product === 'maastopyorien_vuokraus' ) {
                return true;
            }

            return false;
        },
        isProduct() {
            return this.product?.type === 'product';
        },
        isCategory() {
            return this.product?.type === 'category';
        },
        showSearchFilters() {
            return this.isProduct || (this.categoryHasDateSelect && this.categoryHasPersons);
        },
        showCategoryPrimaryFilters() {
            return this.isCategory && this.primaryFilters && this.primaryFilters?.length > 0;
        },
    },
    watch: {
        persons() {
            this.updatedFiltersData = true;
        },
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        ...mapMutations({
            setFilter: 'product/setFilter',
            setDates: 'product/setDates',
            setPersons: 'product/setPersons',
        }),
        dateChange(value) {
            this.updatedFiltersData = true;
            if (this.calendarDebounce) {
                window.clearTimeout(this.calendarDebounce);
            };
            this.calendarDebounce = window.setTimeout(() => {
                this.setDates(value);
            }, 50);
        },
        getClassificationByline() {
            if (this.product?.type === 'category') {
                return false;
            }

            switch (this.product.classification) {
                case 'Activity':
                    return false;
                    break;

                case 'Course':
                    return this.$i18n.t('product.classification.course.byline');
                    break;

                default:
                case 'Accomodation':
                    return this.$i18n.t('product.classification.accommodation.byline');
                    break;
            };
        },
        getClassificationHeading() {
            if (this.product?.code === 'AKTIVITEETTIKAUPPA' || this.product?.code === 'OHJATUT_TUNNIT') {
                return false;
            }

            if (this.isCategory) {
                let title = '';
                switch (this.product?.schema) {
                    case 'Sports and activities':
                        if (this.product?.code === 'KENTAT') {
                            title = this.$i18n.t('category.heading.court');
                        } else if (this.product?.code === 'VALINEET') {
                            title = this.$i18n.t('category.heading.accessory');
                        } else {
                            title = this.$i18n.t('category.heading.default');
                        }
                        break;

                    case 'Activity':
                        title = this.$i18n.t('category.heading.activity');
                        break;

                    case 'Courses':
                        title = this.$i18n.t('category.heading.course');
                        break;

                    case 'Accommodation':
                        title = this.$i18n.t('category.heading.accommodation');
                        break;

                    case 'Education':
                        title = this.$i18n.t('category.heading.education');
                        break;

                    default:
                        title = this.$i18n.t('category.heading.default');
                        break;
                }

                if (this.categoryItems && this.categoryItems.length) {
                    return title;
                }

                return title;
            }

            switch (this.product.classification) {
                case 'Activity':
                    return this.$i18n.t('product.classification.activity.heading');
                    break;

                case 'Course':
                    return this.$i18n.t('product.classification.course.heading');
                    break;

                default:
                case 'Accomodation':
                    return this.$i18n.t('product.classification.accommodation.heading');
                    break;
            }
        },
        updateFilter(payload) {
            this.setFilter(payload);

            if (payload.scope === 'backend-availability') {
                this.loadAvailability();
            }
        },
        updateEquipment() {
            this.updatedFiltersData = true;
        },
        updatePersons(value) {
            this.setPersons(value);
            this.updatedFiltersData = true;
        },
        searchProducts() {
            this.loadAvailability();
            this.updatedFiltersData = false;
        },
    },
};
