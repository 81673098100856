
import { DateTime } from 'luxon';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'ProductPageBlock',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            previousSchema: 'product/getPreviousSchema',
            previousClassification: 'product/getPreviousClassification',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
        }),

        gtmProduct() {
            return { ...this.product, product_pagePath: null };
        },
    },
    mounted() {
        let currentDefault;
        if (this.product?.type === 'product') {
            this.$gtm.push({
                event: 'view_item',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(this.gtmProduct)],
                },
            });
        }

        this.setAvailability({});

        const defaults = {
            activeHours: {
                start: DateTime.now(),
                end: DateTime.now().set({ hours: 23, minutes: 59, seconds: 59 }),
            },
            activity: {
                start: DateTime.now(),
                end: DateTime.now().set({ hours: 23, minutes: 59, seconds: 59 }),
            },
            courses: {
                start: DateTime.now().plus({ days: 1 })
                    .set({ hours: 0, minutes: 0, seconds: 1 }),
                end: DateTime.now().plus({ months: 1, days: 1 })
                    .set({ hours: 0, minutes: 0, seconds: 1 }),
            },
            default: {
                start: DateTime.now().plus({ days: 1 })
                    .set({ hours: 0, minutes: 0, seconds: 1 }),
                end: DateTime.now().plus({ days: 2 })
                    .set({ hours: 23, minutes: 59, seconds: 59 }),
            },
        };

        // Category
        if (this.product.type === 'category'
            && (this.previousSchema !== this.product.schema || this.previousClassification !== this.product.classification)
        ) {
            switch (this.product.schema) {
                case 'Active hours':
                    currentDefault = defaults.activeHours;
                break;

                case 'Courses':
                    currentDefault = defaults.courses;
                break;

                default:
                case 'Accommodation':
                    currentDefault = defaults.default;
                break;
            }
        } else {
            // Product default
            switch (this.product.classification) {
                case 'Activity':
                    currentDefault = defaults.activity;
                break;

                case 'Course':
                case 'Courses':
                    currentDefault = defaults.courses;
                break;

                default:
                    currentDefault = defaults.default;
                break;
            }
        }

        if (this.$route.query.start && this.$route.query.end) {
            this.setDates({
                start: DateTime.fromMillis(Number(this.$route.query.start)),
                end: DateTime.fromMillis(Number(this.$route.query.end)),
            });
        } else {
            this.setDates(currentDefault);
        }

        this.loadAvailability();
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        ...mapMutations({
            setDates: 'product/setDates',
            setAvailability: 'product/setAvailability',
        }),
    },
};
