import { render, staticRenderFns } from "./EducationList.vue?vue&type=template&id=bfb88a46&scoped=true"
import script from "./EducationList.vue?vue&type=script&lang=js"
export * from "./EducationList.vue?vue&type=script&lang=js"
import style0 from "./EducationList.vue?vue&type=style&index=0&id=bfb88a46&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfb88a46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default,VMAccordionItem: require('/build/src/components/elements/VMAccordionItem.vue').default,VMAccordion: require('/build/src/components/elements/VMAccordion.vue').default,ContentfulImage: require('/build/src/components/elements/ContentfulImage.vue').default})
