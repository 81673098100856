
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'VMImageSelect',
    mixins: [breakpoints],
    props: {
        dataKey: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        theme: {
            type: String,
            required: false,
            default: '',
            validator: value => ['white'].indexOf(value) !== -1,
        },
        helpText: {
            type: String,
            required: false,
            default: '',
        },
        error: {
            type: String,
            required: false,
            default: '',
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        const bps = {};
        bps[this.$breakpoints.desktop] = { perView: 2 };
        bps[this.$breakpoints.largeMobile] = { perView: 1 };

        return {
            active: 0,
            bps,
        };
    },
    computed: {
        iconProperties() {
            return {
                size: this.isDesktop ? 'xxxl' : 'xxl',
                icon: 'slider-arrow',
            };
        },
        showLastButton() {
            if (this.atMostMobile) {
                return !(this.active >= this.options.length - 1);
            } else if (this.atMostTablet) {
                return !(this.active >= this.options.length - 2);
            }

            return !(this.active >= this.options.length - 4);
        },
    },
    methods: {
        select(value) {
            this.$emit('value:update', {
                dataKey: this.dataKey,
                value,
            });
            this.$emit('commit', {
                dataKey: this.dataKey,
                value,
            });
        },
    },
};
