
import { mapActions, mapGetters } from 'vuex';
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';
import breakpoints from '~/mixins/breakpoints';

export default {
    name: 'EquipmentSelect',
    directives: {
        clickOutside,
    },
    mixins: [breakpoints],
    props: {
        equipments: {
            type: [Object, Array],
            required: false,
            default: () => {
            },
        },
    },
    data() {
        return {
            timeout: null,
            open: false,
            internalValues: {
                numberOfAdults: 0,
                numberOfChildrenOlder: 0,
                numberOfChildrenYounger: 0,
            },
            filter: this.equipments,
        };
    },
    computed: {
        ...mapGetters({
            filters: 'category/getSecondaryFilters',
        }),
        equipmentsData() {
            return this.equipments?.selectedOptions;
        },
    },
    watch: {
        equipmentsData(to, from) {
            if ((to && from) && (to.length < from.length)) {
                this.$emit('updateEquipment');
            }
        },
    },
    mounted() {
        this.internalValues = {
            ...Object(this.equipments),
        };
    },
    methods: {
        ...mapActions({
            toggleFilters: 'category/toggleFilters',
        }),
        doOpen() {
            if (this.open === false) {
                this.internalValues = {
                    ...Object(this.equipments),
                };
            }
            this.open = !this.open;
        },
        handleClickOutside() {
            if (this.open) {
                this.open = false;
            }
            ;
        },
        isSelected(filter, option) {
            return filter.selectedOptions?.includes(option?.key);
        },
    },
};
