import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    fields: {
        firstName: '',
        lastName: '',
        email: '',
        b2b: [],
        terms: [],
    },
    redirect: null,
});

export const mutations = {
    ...storeForm.mutations,
    clear(state) {
        state.fields = {
            firstName: '',
            lastName: '',
            email: '',
            b2b: [],
            terms: [],
        };
    },
};

export const actions = {
    async submit(ctx) {
        try {
            const values = {
                firstName: ctx.state.fields.firstName,
                lastName: ctx.state.fields.lastName,
                email: ctx.state.fields.email,
                tags: [!!ctx.state.fields.b2b[0] && 'B2B'].filter(x => x),
            };

            const registerEmail = await this.$axios.request({
                method: 'post',
                url: '/proxy/vm-communication/v1/newsletter/subscribe',
                data: values,
            });
            this.dispatch('messages/setMessage', {
                type: 'success',
                message: this.app.i18n.t('newsletter.message.success'),
            });
            ctx.commit('clear');
        } catch (e) {
            this.dispatch('messages/setMessage', {
                type: 'error',
                message: this.app.i18n.t('newsletter.message.failed'),
            });
        }
    },
};

export const getters = {
    ...storeForm.getters,
    getRedirect: state => state.redirect,
};
