import { render, staticRenderFns } from "./ProductTable.vue?vue&type=template&id=ece201ae&scoped=true"
import script from "./ProductTable.vue?vue&type=script&lang=js"
export * from "./ProductTable.vue?vue&type=script&lang=js"
import style0 from "./ProductTable.vue?vue&type=style&index=0&id=ece201ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece201ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterSelector: require('/build/src/components/blocks/ProductPage/ProductItems/FilterSelector.vue').default,ActivityIndicator: require('/build/src/components/elements/ActivityIndicator.vue').default,ProductCardContent: require('/build/src/components/elements/Cards/ProductCardContent.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
