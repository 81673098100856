import { find, sortBy } from 'lodash';
import { DateTime, Interval } from 'luxon';

const defaultState = () => ({
    blocks: [],
    start: DateTime.now().startOf('month'),
    end: DateTime.now().endOf('month'),
    loading: true,
});

export default {
    state: () => defaultState(),
    mutations: {
        setValue(state, payload) {
            Object.keys(payload).forEach(key => {
                if (typeof(payload[key]) === typeof(state[key])) {
                    state[key] = payload[key];
                } else {
                    throw new Error('Could not set value on homeowner/ownReservation');
                }
            });
        },
        setLoading(state, payload) {
            if (typeof(payload) === 'boolean') {
                state.loading = payload;
            };
        },
    },
    actions: {
        async load(ctx, apartmentId) {
            let currentApartment = apartmentId;

            if (!currentApartment) {
                try {
                    currentApartment = ctx.rootState.homeowner?.apartments?.apartments?.[0]?.apartmentId;
                } catch (e) {
                    return false;
                }
            }

            this.commit('homeowner/apartment/setLoading', true);

            try {
                const { start, end } = ctx.state;
                const { data: ownerBlocks } = await this.$axios.request({
                    method: 'get',
                    url: `/proxy/vm-homeowner/v1/apartments/${currentApartment}/blocks`,
                });

                const { data: reservations } = await this.$axios.request({
                    method: 'get',
                    url: `/proxy/vm-homeowner/v1/apartments/${currentApartment}/reservations`,
                });

                const { data: otherReservations } = await this.$axios.request({
                    method: 'get',
                    url: `/proxy/vm-homeowner/v1/apartments/${currentApartment}/otherreservations`,
                });

                ownerBlocks.forEach((value, key) => {
                    ownerBlocks[key].type = 'bookable';
                });

                reservations.forEach((value, key) => {
                    reservations[key].type = 'booked';
                });

                otherReservations.forEach((value, key) => {
                    otherReservations[key].type = 'vm-booked';
                });

                this.commit('homeowner/apartment/setValue', {
                    blocks: sortBy([
                        ...ownerBlocks,
                        ...reservations,
                        ...otherReservations,
                    ], value => DateTime.fromFormat(value.dateStart, 'yyyy-MM-dd').toMillis()),
                });
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('homeowner.message.loadFailed'),
                });
            };

            this.commit('homeowner/apartment/setLoading', false);
        },
    },
    getters: {
        isHomeOwner: (state, getters, rootState) => () => rootState.user?.fields?.apartments?.length > 0,
        getLoading: state => state.loading,
        getBlocks: (state, getters, rootState) => apartmentId => {
            const role = getters.getRole(apartmentId);

            const contracts = getters.getContracts(apartmentId);
            const blocks = [...state.blocks];
            const contractIntervals = contracts.map(contract => Interval.fromDateTimes(
                DateTime.fromFormat(contract.dateStart, 'yyyy-MM-dd'),
                DateTime.fromFormat(contract.dateEnd, 'yyyy-MM-dd'),
            ));
            const parsedBlocks = [];

            blocks.forEach(block => {
                const currentInterval = Interval.fromDateTimes(
                    DateTime.fromFormat(block.dateStart, 'yyyy-MM-dd'),
                    DateTime.fromFormat(block.dateEnd, 'yyyy-MM-dd'),
                );

                if (block.type === 'bookable') {
                    const result = [];

                    contractIntervals.forEach(contract => {
                        try {
                            const splitInterval = currentInterval.intersection(contract);

                            if (splitInterval === null) {
                                throw new Error();
                            }

                            parsedBlocks.push({
                                ...block,
                                dateStart: splitInterval.start.toFormat('yyyy-MM-dd'),
                                dateEnd: splitInterval.end.toFormat('yyyy-MM-dd'),
                            });
                        } catch (e) {
                            return false;
                        }
                    });
                } else {
                    parsedBlocks.push({
                        ...block,
                        dateStart: currentInterval.start.toFormat('yyyy-MM-dd'),
                        dateEnd: currentInterval.end.toFormat('yyyy-MM-dd'),
                    });
                }
            });

            return parsedBlocks;
        },
        getContracts: (state, getters, rootState) => apartmentId => {
            const currentApartment = find(rootState.homeowner?.apartments?.apartments, v => v.apartmentId === apartmentId);

            if (currentApartment) {
                return currentApartment.contracts;
            }

            return [];
        },
        getRole: (state, getters, rootState) => apartmentId => {
            const contracts = getters.getContracts(apartmentId);

            if (contracts.length > 0) {
                const roles = [...new Set(contracts
                    .map(item => item.role.toLowerCase())
                    .filter(v => v),
                )];

                if (roles.length === 1) {
                    return roles[0];
                }
            }

            return 'invalid';
        },
        getAvailableDisplayDates: (state, getters, rootState) => apartmentId => {
            const role = getters.getRole(apartmentId);
            const blocks = getters.getBlocks(apartmentId).filter(item => item.type === 'bookable');
            const contracts = getters.getContracts(apartmentId);

            return contracts.map(item => ({
                start: new Date(item.dateStart),
                end: new Date(item.dateEnd),
            }));
        },
        getAvailableSelectionDates: (state, getters, rootState) => apartmentId => {
            const role = getters.getRole(apartmentId);
            const contracts = getters.getContracts(apartmentId);
            const now = DateTime.now().startOf('day');
            let blocks, contractIntervals, blocksIntervals;
            let result = [];

            if (role === 'user') {
                result = getters.getBlocks(apartmentId)
                    .filter(item => item.type === 'bookable')
                    .map(item => ({
                        start: new Date(item.dateStart),
                        end: new Date(item.dateEnd),
                    }));
            } else {
                blocks = getters.getBlocks(apartmentId).filter(item => item.type === 'vm-booked');
                contractIntervals = contracts.map(item =>
                    Interval.fromDateTimes(DateTime.fromFormat(item.dateStart, 'yyyy-MM-dd'), DateTime.fromFormat(item.dateEnd, 'yyyy-MM-dd')),
                );

                blocksIntervals = blocks.map(item =>
                    Interval.fromDateTimes(DateTime.fromFormat(item.dateStart, 'yyyy-MM-dd'), DateTime.fromFormat(item.dateEnd, 'yyyy-MM-dd')),
                );

                contractIntervals.forEach(item => {
                    result.push(...item.difference(...blocksIntervals));
                });

                result = result
                    .filter(item => item.e.startOf('day').toMillis() > now.toMillis())
                    .map(item => ({
                        start: item.s.startOf('day').toMillis() < now.toMillis() ? now.toJSDate() : item.s.startOf('day').toJSDate(),
                        end: item.e.startOf('day').toJSDate(),
                    }));
            }

            if (result.length > 0) {
                return result;
            }

            return undefined;
        },
    },
};
