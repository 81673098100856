
import { mapGetters } from 'vuex';

export default {
    name: 'EducationExtra',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
        imageHalf() {
            return [{
                image: this.$util.parseImage(this.product.image, 0),
                text: this.product.text_element,
            }, {
                image: this.$util.parseImage(this.product.image_2, 0),
                text: this.product.text_element2,
            }];
        },
        additionalText() {
            return this.product.product_education_related_extra_info;
        },
    },
};
