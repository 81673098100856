import { render, staticRenderFns } from "./RadioFilter.vue?vue&type=template&id=4ab07a94&scoped=true"
import script from "./RadioFilter.vue?vue&type=script&lang=js"
export * from "./RadioFilter.vue?vue&type=script&lang=js"
import style0 from "./RadioFilter.vue?vue&type=style&index=0&id=4ab07a94&prod&lang=Scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab07a94",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
