const middleware = {}

middleware['auth-adds'] = require('../src/middleware/auth-adds.js')
middleware['auth-adds'] = middleware['auth-adds'].default || middleware['auth-adds']

middleware['checkout'] = require('../src/middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['redirect'] = require('../src/middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['reset-password'] = require('../src/middleware/reset-password.js')
middleware['reset-password'] = middleware['reset-password'].default || middleware['reset-password']

export default middleware
