import { render, staticRenderFns } from "./EducationPage.vue?vue&type=template&id=71a69864&scoped=true"
import script from "./EducationPage.vue?vue&type=script&lang=js"
export * from "./EducationPage.vue?vue&type=script&lang=js"
import style0 from "./EducationPage.vue?vue&type=style&index=0&id=71a69864&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a69864",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/build/src/components/elements/ContentfulImage.vue').default,ContentRouter: require('/build/src/components/ContentRouter.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
