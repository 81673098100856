import { render, staticRenderFns } from "./CheckboxFilter.vue?vue&type=template&id=db45c112&scoped=true"
import script from "./CheckboxFilter.vue?vue&type=script&lang=js"
export * from "./CheckboxFilter.vue?vue&type=script&lang=js"
import style0 from "./CheckboxFilter.vue?vue&type=style&index=0&id=db45c112&prod&lang=Scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db45c112",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
