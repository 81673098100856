import { render, staticRenderFns } from "./EventCalendar.vue?vue&type=template&id=cad96d62&scoped=true"
import script from "./EventCalendar.vue?vue&type=script&lang=js"
export * from "./EventCalendar.vue?vue&type=script&lang=js"
import style0 from "./EventCalendar.vue?vue&type=style&index=0&id=cad96d62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cad96d62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileCalendar: require('/build/src/components/blocks/EventCalendar/MobileCalendar.vue').default,DesktopCalendar: require('/build/src/components/blocks/EventCalendar/DesktopCalendar.vue').default})
