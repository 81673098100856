import { render, staticRenderFns } from "./ContentfulModal.vue?vue&type=template&id=def0c4b2&scoped=true"
import script from "./ContentfulModal.vue?vue&type=script&lang=js"
export * from "./ContentfulModal.vue?vue&type=script&lang=js"
import style0 from "./ContentfulModal.vue?vue&type=style&index=0&id=def0c4b2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "def0c4b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextEditor: require('/build/src/components/elements/TextEditor.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default,ProductModal: require('/build/src/components/blocks/ProductModal.vue').default})
