
import { mapGetters } from 'vuex';

export default {
    name: 'GiftcardDetails',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
    },
};
