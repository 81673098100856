
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'LoginModal',
    data() {
        return {
            step: 0,
            emailVerified: false,
            notVerifiedOnce: true,
            registrationFormValid: false,
            disabled: false,
        };
    },
    computed: {
        userEmail() {
            return this.userFields.email || null;
        },
        ...mapGetters({
            isLoggedIn: 'user/getLoggedIn',
            emailStatus: 'user/getEmailStatus',
            userFields: 'registration/getFields',
            loginFields: 'login/getFields',
            registered: 'registration/getRegistrationStatus',
            loading: 'user/loading',
            bps: 'breakpoints/getState',
        }),
        modalLabel() {
            if (this.step === 4) {
                return this.$t('loginModal.texts.registerFail');
            } else if (this.step === 3) {
                return this.$t('loginModal.texts.registerThanksHeader');
            } else if (this.step === 2) {
                return this.$t('loginModal.texts.givePassword');
            } else if (this.step === 1) {
                return this.$t('loginModal.texts.loginHeading');
            }

            return this.$t('loginModal.texts.heading');
        },
        emailForm() {
            return {
                store: 'registration',
                fields: [
                    {
                        dataKey: 'email',
                        label: this.$t('login.fields.email'),
                        validator: ['notEmpty', 'email'],
                    },
                    {
                        type: 'submit',
                        action: () => this.evaluateLogin(),
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('loginModal.texts.continue'),
                    },
                ],
            };
        },
        registerForm() {
            return {
                store: 'registration',
                fields: [
                    {
                        dataKey: 'password',
                        label: this.$t('login.fields.password'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            { type: 'minLength', value: 6 },
                            { type: 'maxLength', value: 36 },
                        ],
                    },
                    {
                        dataKey: 'confirmPassword',
                        label: this.$t('registration.fields.confirmPassword'),
                        type: 'password',
                        validator: [
                            'notEmpty',
                            {
                                type: 'equals',
                                field: ['registration', 'password'],
                            },
                            { type: 'maxLength', value: 36 },
                        ],
                    },
                    {
                        dataKey: 'friends',
                        type: 'checkbox',
                        options: [
                            { value: true, label: this.$t('registration.fields.friends') },
                        ],
                    },
                    {
                        type: 'submit',
                        action: () => this.register(),
                        cssClass: {
                            'disabled': !this.registrationFormValid,
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('registration.fields.registerButton'),
                    },
                ],
            };
        },
        passwordForm() {
            return {
                store: 'login',
                action: 'login/login',
                fields: [
                    {
                        dataKey: 'password',
                        label: this.$t('login.fields.password'),
                        type: 'password',
                        validator: ['notEmpty'],
                    },
                    {
                        type: 'submit',
                        action: 'submit',
                        cssClass: {
                            'loginButton': true,
                            'btn-primary': true,
                            'btn-full': true,
                        },
                        label: this.$t('login.fields.loginButton'),
                    },
                    {
                        type: 'link',
                        contentType: 'link',
                        title: this.$t('login.fields.forgotPassword'),
                        cssClass: {
                            'text-link': true,
                            'size-s': true,
                        },
                        link: {
                            url: this.$paths.getPath('resetPassword'),
                        },
                    },
                ],
            };
        },
    },
    watch: {
        loading(to, from) {
            this.disabled = to;
        },
        emailStatus(to, from) {
            if (to === true) {
                this.step = 1;
            } else if (to === false) {
                this.step = 2;
            }
        },
        registered(to, from) {
            if (to === true) {
                this.step = 3;
            }
        },
        isLoggedIn(to, from) {
            if (to === true) {
                this.setModalOpen(false);
                this.step = 0;
            }
        },
    },
    methods: {
        ...mapMutations({
            setModalOpen: 'modal/setOpen',
        }),
        ...mapActions({
            submitRegistration: 'registration/submit',
            verifyEmail: 'registration/verifyEmail',
        }),
        async validateEmail(inputValid) {
            this.$store.commit('user/setLoading', true);
            if (inputValid) {
                this.emailVerified = await this.verifyEmail();
                this.notVerifiedOnce = false;
            }
            this.$store.commit('user/setLoading', false);
        },
        async register() {
            const response = await this.submitRegistration();

            if (response === true) {
                this.step = 3;
            } else {
                this.step = 4;
            }
        },
        close() {
            this.$store.commit('modal/setOpen', false);
            this.$router.push(this.$paths.getPath('landingPage'));
        },
        evaluateLogin() {
            if (this.emailVerified) {
                this.step = 1;
            } else {
                this.step = 2;
            }
        },
        setStep(step) {
            this.step = step;
        },
        back() {
            if (this.step > 0) {
                this.step = 0;
            } else {
                this.setModalOpen(false);
            }
        },
    },
};
