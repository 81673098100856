
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
    name: 'ProductSearch',
    data: () => ({
        selected: 'accommodation',
        timeout: 0,
    }),
    computed: {
        ...mapGetters({
            persons: 'product/getPersons',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            activities: 'product/getActivities',
            filters: 'category/getSecondaryFilters',
            product: 'product/getProduct',
        }),
        computedDates: {
            get() {
                return {
                    start: this.fromDate,
                    end: this.toDate,
                };
            },
            set(value) {
                this.setDates(value);
            },
        },
        tabs() {
            return {
                accommodation: { icon: 'bed', text: this.$t('productSearch.tabs.accommodation') },
                course: { icon: 'tennis', text: this.$t('productSearch.tabs.course') },
                activities: { icon: 'heart', text: this.$t('productSearch.tabs.activities') },
                giftcard: { icon: 'gift', text: this.$t('productSearch.tabs.giftcard') },
            };
        },
    },
    created() {
        if ((!this.fromDate && !this.toDate) || (typeof(this.fromDate) === 'string' && typeof(this.toDate) === 'string')) {
            this.setDates({
                start: DateTime.now().plus({ days: 1 }),
                end: DateTime.now().plus({ days: 2 })
                    .set({ hours: 23, minutes: 59, seconds: 59 }),
            });
        }
        this.loadActivities();
    },
    mounted() {
        this.setActive('accommodation');
    },
    methods: {
        ...mapActions({
            loadActivities: 'product/loadActivities',
        }),
        ...mapMutations({
            setDates: 'product/setDates',
            setPersons: 'product/setPersons',
        }),
        getCalendarLabels(tab) {
            switch (tab) {
                case 'course':
                    return {
                        from: this.$i18n.t('product.classification.course.calendar.from'),
                        to: this.$i18n.t('product.classification.course.calendar.to'),
                    };
                break;

                default:
                case 'accommodation':
                    return {
                        from: this.$i18n.t('product.classification.accommodation.calendar.from'),
                        to: this.$i18n.t('product.classification.accommodation.calendar.to'),
                    };
                break;
            }
        },
        navigate(tab) {
            switch (tab) {
                case 'course':
                    this.$router.push({
                        path: this.$paths.getPath('courseSearch'),
                        query: {
                            start: this.fromDate.toMillis(),
                            end: this.toDate.toMillis(),
                        },
                    });
                break;

                default:
                case 'accommodation':
                    this.$router.push({
                        path: this.$paths.getPath('accommodationSearch'),
                        query: {
                            start: this.fromDate.toMillis(),
                            end: this.toDate.toMillis(),
                        },
                    });
                break;
            }
        },
        setActive(value) {
            this.$refs['product-search'].querySelectorAll('.panel').forEach(element => {
                element.classList.remove('active');
            });

            if (!process.server) {
                window.clearTimeout(this.timeout);

                this.timeout = window.setTimeout(() => {
                    if (this.$refs[`panel-${value}`].length > 0) {
                        this.$refs[`panel-${value}`][0].classList.add('active');
                    }
                }, 1000);
            }

            switch (value) {
                case 'accommodation':
                    this.setDates({
                        start: DateTime.now().plus({ days: 1 })
                            .set({ hours: 0, minutes: 0, seconds: 1 }),
                        end: DateTime.now().plus({ days: 2 })
                            .set({ hours: 23, minutes: 59, seconds: 59 }),
                    });
                break;

                case 'course':
                    this.setDates({
                        start: DateTime.now().plus({ days: 1 })
                            .set({ hours: 0, minutes: 0, seconds: 1 }),
                        end: DateTime.now().plus({ months: 1, days: 1 })
                            .set({ hours: 23, minutes: 59, seconds: 59 }),
                    });
                break;

                case 'activities':
                    this.setDates({
                        start: DateTime.now(),
                        end: DateTime.now().set({ hours: 23, minutes: 59, seconds: 59 }),
                    });
                break;
            };
            this.selected = value;
        },
    },
};
