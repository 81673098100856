import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

export const state = () => ({
    dialogId: '',
    fields: {
        cardValue: '',
        name: '',
        message: '',
        email: '',
        confirmEmail: '',
        image: '',
    },
    values: {},
});

export const mutations = {
    ...storeForm.mutations,
    setDialogId(state, id) {
        state.dialogId = id;
    },
    setValues(state, payload) {
        state.values = payload;
    },
};

export const actions = {
    async loadValues(ctx) {
        const response = await this.$axios.request({
            method: 'get',
            url: '/proxy/vm-view/v1/content/page',
            params: {
                locale: this.$i18n.locale,
                pagePath: 'lahjakortti',
            },
        });
        this.commit('giftcard/setValues', response.data.content.fields.body[0].fields.attributes.gift_card_value_options);
    },
    register(ctx) {
        if (ctx.rootGetters['cart/containsNonGiftcards'] || ctx.rootGetters['cart/getProducts'].length > 0) {
            this.commit('modal/setCloseButton', false);
            this.commit('modal/setId', ctx.state.dialogId);
            this.commit('modal/setOpen');
        } else {
            const product = {
                type: 'giftcard',
                productCode: 'gift_card_open_value',
                image: ctx.rootGetters['product/getProductImage'](ctx.state.fields.image),
                title: ctx.rootGetters['product/getProduct'].marketing_name,
                price: Number(ctx.state.fields.cardValue),
                priceWithoutTax: Number(ctx.state.fields.cardValue),
                from: new Date(),
                to: new Date((new Date()).setFullYear((new Date()).getFullYear() + 1)),
                metadata: {
                    value: Number(ctx.state.fields.cardValue),
                    imageAttributeName: ctx.state.fields.image,
                    recipientName: ctx.state.fields.name,
                    recipientEmail: ctx.state.fields.email,
                    message: ctx.state.fields.message,
                },
            };
            this.commit('cart/addProduct', product);
            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(product, 'cart')],
                },
            });
            this.$router.push(this.$paths.getPath('checkout'));
        }
    },
    close(ctx) {
        this.commit('modal/setOpen', false);
    },
};

export const getters = {
    ...storeForm.getters,
    getDialogId: state => state.dialogId,
    getValues: state => state.values,
};
