
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'EventFilters',
        props: {
            showTitle: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                filters: 'calendar/getFilters',
            }),
        },
        methods: {
            ...mapActions({
                toggleItem: 'calendar/toggleListItem',
            }),
            updateFilters(e, id) {
                this.toggleItem(id);
            },
        },
    };
