
    import { mapGetters } from 'vuex';
    import breakpoints from '~/mixins/breakpoints';

    export default {
        name: 'EventModal',
        mixins: [breakpoints],
        props: {
            name: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                event: 'calendar/getSelectedEvent',
            }),
            imageUrl() {
                if (this.event?.imageUrl?.length) {
                    return this.event.imageUrl;
                }

                return '';
            },
            duration() {
                if (this.hasDuration) {
                    return this.getDuration(this.event?.startTime, this.event?.endTime);
                }

                return 0;
            },
            hasEventVenue() {
                return this.event?.venue && 'name' in this.event.venue;
            },
            hasDuration() {
                return this.event?.startTime && this.event?.endTime;
            },
            options1() {
                return { year: 'numeric', month: 'long', day: 'numeric' };
            },
            options2() {
                return { hour: 'numeric', minute: 'numeric' };
            },
        },
        methods: {
            getDuration(start, end) {
                if (start > end) {
                    return 0;
                }

                let difference = Math.abs(end - start);
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                if (hours > 0) {
                    difference = Math.floor(difference - (1000 * 60 * 60) * hours);
                }

                const minutes = Math.floor((difference / (1000 * 60)) % 60);

                return { hours, minutes };
            },
            createAndSaveIcs() {
                this.$ics.removeAllEvents();
                try {
                    this.$ics.addEvent(
                        'fi-fi',
                        this.event?.name ?? '',
                        this.event?.longDescription ?? '',
                        this.event?.venue?.name ?? '',
                        this.event?.startTime,
                        this.event?.endTime,
                        '',
                        { name: 'vierumaki', email: '' },
                    );

                    this.$ics.download('vierumaki');
                } catch (error) {
                    this.dispatch('messages/setMessage', {
                        type: 'error',
                        message: this.app.i18n.t('event.message.failed'),
                    });
                }
            },
        },
    };
