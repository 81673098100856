
import { mapGetters, mapMutations } from 'vuex';
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'EducationPaymentPage',
    directives: {
        clickOutside,
    },
    data() {
        return {
            selectedPaymentCodes: [],
            paymentOptionsOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            avaibility: 'product/getAvailability',
            isAvaibilityLoading: 'product/getAvailabilityLoading',
            cartProducts: 'cart/getProducts',
            totalsLoading: 'cart/getTotalsLoading',
        }),
        productImage() {
            return this.product?.images?.[0];
        },
        paymentOptions() {
            return this.avaibility?.[0]?.parts;
        },
        paymentOptionsLabel() {
            if (!this.selectedPaymentCodes[0]) return;

            const option = this.paymentOptions.find(o => this.selectedPaymentCodes.indexOf(o.product.code) !== -1);

            return this.formatPaymentOptionLabel(option);
        },
        paymentCheckboxOptions() {
            if (!this.paymentOptions?.length) return [];

            return this.paymentOptions.map(option => ({
                value: option.product.code,
                label: this.formatPaymentOptionLabel(option),
            }));
        },
        selectedPaymentsPrice() {
            const options = this.selectedPaymentCodes.map(code => this.paymentOptions.find(o => o.product.code === code));

            return options.reduce((sum, option) => sum + option.price, 0);
        },
        disableAddToCart() {
            const wholeInCart = this.cartProducts.find(p => p.productCode === this.avaibility[0].product.code) !== undefined;

            let partInCart = false;
            for (const code of this.selectedPaymentCodes) {
                if (this.cartProducts.find(p => p.productCode === code)) {
                    partInCart = true;
                    break;
                }
            }

            return this.totalsLoading || wholeInCart || partInCart;
        },
    },
    watch: {
        paymentOptions(options) {
            if (options?.length) {
                this.selectedPaymentCodes = [options[0].product.code];
            }
        },
    },
    mounted() {
        if (this.paymentOptions?.length) {
            this.selectedPaymentCodes = [this.paymentOptions[0].product.code];
        }
    },
    methods: {
        ...mapMutations({
            addProduct: 'cart/addProduct',
        }),
        formatPaymentOptionLabel(option) {
            return `${option.product.attributes.marketing_name} ${this.$util.formatPriceHidingZeroCents(option.price)}`;
        },
        handleClickOutsidePaymentOptions() {
            this.paymentOptionsOpen = false;
        },
        updatePaymentOptions({ value }) {
            this.selectedPaymentCodes = value;
        },
        addWholeEducationToCart() {
            const [{ product, price, discountAmount, dateFrom, dateTo }] = this.avaibility;
            const pagePath = this.product.product_pagePath;

            this.addProduct({
                type: this.product.classification,
                productCode: product.code,
                image: this.productImage.url,
                title: this.product.marketing_name,
                price,
                discountAmount,
                from: new Date(dateFrom),
                to: new Date(dateTo),
                pagePath,
                classification: this.product.classification,
            });
            this.$gtm.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [this.$util.generateGtmProduct(this.avaibility[0], 'addToCart', pagePath)],
                },
            });
        },
        addSelectedOptionsToCart() {
            for (const code of this.selectedPaymentCodes) {
                const option = this.paymentOptions.find(o => o.product.code === code);
                const { product, price, discountAmount, dateFrom, dateTo } = option;
                const pagePath = this.product.product_pagePath;

                this.addProduct({
                    type: product.classification,
                    productCode: code,
                    image: this.productImage.url,
                    title: `${this.product.marketing_name} ${product.attributes.marketing_name}`,
                    price,
                    discountAmount,
                    from: new Date(dateFrom),
                    to: new Date(dateTo),
                    pagePath,
                    classification: product.classification,
                });
                this.$gtm.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        items: [this.$util.generateGtmProduct(option, 'addToCart', pagePath)],
                    },
                });
            }
        },
    },
};
