
const EDUCATION_SEARCH_PARAMS_KEY = 'educationSearch';
export default {
    name: 'EducationList',
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        listTitle: {
            type: String,
            required: false,
            default: '',
        },
        educations: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            filters: [
                {
                    name: 'Koulutuksen tyyppi',
                    field: 'degreeType',
                    id: this.$util.uuidv4(),
                    picked: null,
                    options: [
                        'Perustutkinto',
                        'Ammattitutkinto',
                        'Erikoisammattitutkinto',
                        'Muu koulutus',
                    ],
                },
                {
                    name: 'Koulutusala',
                    field: 'degreeFields',
                    fieldIsArray: true,
                    id: this.$util.uuidv4(),
                    picked: null,
                    options: [
                        'Hieronta',
                        'Lasten liikunta',
                        'Liikunnanohjaus- ja liikuntaneuvonta',
                        'Liikuntapaikka-ala',
                        'Luontoliikunta',
                        'Personal trainer',
                        'Seura- ja järjestötoiminta',
                        'Urheilujohtaminen',
                        'Urheilumanagerointi',
                        'Valmennus',
                    ],
                },
                {
                    name: 'Tutkintonimike',
                    field: 'degreeDesignation',
                    id: this.$util.uuidv4(),
                    picked: null,
                    options: [
                        'Liikuntaneuvoja',
                        'Liikuntaneuvoja (AT)',
                        'Liikuntapaikkamestari',
                        'Liikuntapaikkojen hoitaja',
                        'Valmentaja',
                    ],
                },
                {
                    name: 'Toteutusmuoto',
                    field: 'degreeImplementation',
                    id: this.$util.uuidv4(),
                    picked: null,
                    options: [
                        'Kokopäiväopiskelu',
                        'Lyhytkurssi',
                        'Monimuoto-opiskelu',
                        'Verkko-opiskelu',
                    ],
                },
            ],
        };
    },
    computed: {
        activeFilters() {
            return this.filters.filter(f => f.picked !== null);
        },
        filteredEducations() {
            return this.educations.filter(e => {
                for (const filter of this.activeFilters) {
                    if (filter.fieldIsArray) {
                        if (!e.fields[filter.field]?.includes(filter.picked)) {
                            return false;
                        }
                    } else if (e.fields[filter.field] !== filter.picked) {
                        return false;
                    }
                }

                return true;
            });
        },
    },
    watch: {
        activeFilters() {
            if (process.server) return;

            const filters = this.activeFilters.map(f => ({
                field: f.field,
                picked: f.picked,
            }));

            const url = new URL(window.location.href);
            if (filters.length) {
                url.searchParams.set(EDUCATION_SEARCH_PARAMS_KEY, JSON.stringify(filters));
            } else {
                url.searchParams.delete(EDUCATION_SEARCH_PARAMS_KEY);
            }
            window.history.replaceState(null, '', url);
        },
    },
    created() {
        const rawEducationSearch = this.$route.query[EDUCATION_SEARCH_PARAMS_KEY];
        if (!rawEducationSearch) return;

        try {
            const educationSearch = JSON.parse(rawEducationSearch);

            for (const filter of educationSearch) {
                if (!filter.field || !filter.picked) continue;

                const dataFilter = this.filters.find(f => f.field === filter.field);
                if (!dataFilter) continue;
                if (!dataFilter.options.includes(filter.picked)) continue;

                dataFilter.picked = filter.picked;
            }
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        getFilterRadioName(filter) {
            return `filter-${this.id}-${filter.id}`;
        },
        toggleFilterOption(filter, option) {
            filter.picked = filter.picked !== option ? option : null;
        },
        removeFilter(filter) {
            filter.picked = null;
        },
        removeAllFilters() {
            for (const filter of this.activeFilters) {
                filter.picked = null;
            }
        },
    },
};
