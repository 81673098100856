
    import { mapMutations, mapGetters, mapActions } from 'vuex';
    import breakpoints from '~/mixins/breakpoints';

    let components = {};
    if (process.browser) {
        const Calendar = require('~/components/blocks/EventCalendar/WeeklyCalendar').default;
        components = { Calendar };
    }

    export default {
        name: 'EventCalendarMobile',
        components,
        mixins: [breakpoints],
        props: [],
        data: () => ({
            active: 0,
            filtersOpen: false,
        }),
        computed: {
            ...mapGetters({
                storeSelectedDate: 'calendar/getSelectedDate',
                selectedDateHasEvents: 'calendar/selectedDateHasEvents',
                filters: 'calendar/getFilters',
            }),
            selectedDate: {
                get() {
                    return this.storeSelectedDate;
                },
                set(value) {
                    if (value instanceof Date) {
                        this.setSelectedDate(value.getTime());
                    }
                },
            },
            iconProperties() {
                if (this.atMostTablet) {
                    return {
                        size: '25xl',
                        icon: 'slider-arrow',
                    };
                }

                return {
                    size: '225xl',
                    icon: 'slider-arrow',
                };
            },
            selectedDateObj() {
                return new Date(this.selectedDate);
            },
            selectedMonth() {
                return this.selectedDateObj.getMonth();
            },
            daysInMonth() {
                return this.getDaysInMonth(this.selectedDateObj.getMonth(), this.selectedDateObj.getFullYear());
            },
        },
        created() {
            if (this.selectedDateObj) {
                this.active = this.selectedDateObj.getDate();
            } else {
                this.active = new Date().getDate() - 1;
            }
        },
        methods: {
            ...mapMutations({
                setSelectedDate: 'calendar/setSelectedDate',
            }),
            firstDayInPreviousMonth(date) {
                return new Date(date.getFullYear(), date.getMonth() - 1, 1);
            },
            firstDayInNextMonth(date) {
                return new Date(date.getFullYear(), date.getMonth() + 1, 1);
            },
            getDaysInMonth(month, year) {
                const date = new Date(year, month, 1);
                const days = [];

                while (date.getMonth() === (month)) {
                    days.push(new Date(date));
                    date.setDate(date.getDate() + 1);
                }

                return days;
            },
            goToNextMonth() {
                const time = this.firstDayInNextMonth(this.selectedDateObj);
                if (this.isThisMonth(time)) {
                    this.setSelectedDate(new Date().getTime());
                } else {
                    this.setSelectedDate(time.getTime());
                }
                this.focusSlider();
            },
            goToPrevMonth() {
                const time = this.firstDayInPreviousMonth(this.selectedDateObj);
                if (this.isThisMonth(time)) {
                    this.setSelectedDate(new Date().getTime());
                } else {
                    this.setSelectedDate(time.getTime());
                }
                this.focusSlider();
            },
            isThisMonth(date) {
                const today = new Date();

                return (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth());
            },
            focusSlider() {
                this.$refs.slider.glide.go(`=${this.selectedDateObj.getDate() - 1}`);
            },
        },
    };
