const colorMap = new Map();
colorMap.set('light-blue', '#569eff');
colorMap.set('light-orange', '#feac00');
colorMap.set('light-green', '#4fb278');
colorMap.set('light-brown', '#c7a084');
colorMap.set('dark-blue', '#184385');
colorMap.set('black', '#333333');
colorMap.set('dark-green', '#0a7751');
colorMap.set('dark-orange', '#ec8601');
colorMap.set('dark-brown', '#805c4a');

function checkTag(filterTag, itemTag) {
    if (!filterTag || !itemTag) {
        return false;
    }

    return itemTag.toLowerCase().includes(filterTag.toLowerCase());
}

function getEventColor(event, filters) {
    if (event.tags) {
        const found = filters.find(item => item.isChecked && checkTag(item?.tag, event.tags));

        if (found && 'color' in found && colorMap.get(found.color)) {
            return colorMap.get(found.color);
        }
    }

    if (event?.type?.length) {
        const found = filters.find(item => item.isChecked && checkTag(item?.type, event.type));

        if (found && 'color' in found && colorMap.get(found.color)) {
            return colorMap.get(found.color);
        }
    }

    return '#2863bf';
}

function isSameDay(dateToCheck, actualDate) {
    return (dateToCheck.getDate() === actualDate.getDate()
        && dateToCheck.getMonth() === actualDate.getMonth()
        && dateToCheck.getFullYear() === actualDate.getFullYear());
}

export const state = () => ({
    data: [],
    filters: [],
    selectedDate: Date.now(),
    selectedEvent: {},
    dataDict: [],
    filteredData: [],
    dayDict: [],
});

export const mutations = {
    setData(state, payload) {
        if (payload && payload.length) {
            let events = payload;

            events = events.map(item => Object.freeze(item));
            // Create dict of events by tags for faster filtering
            const eventDict = events.reduce((obj, it) => {
                if ('tags' in it && it.tags?.length) {
                    let tags = it.tags.split(',');
                    tags = tags.map(item => item.toLowerCase());

                    for (let tag of tags) {
                        tag = tag.toLowerCase();
                        if (!(tag in obj) || !obj[tag]) {
                            obj[tag] = [];
                        }
                        obj[tag] = [...obj[tag], Object.freeze(it)];
                    }
                }

                if ('type' in it && it.type?.length) {
                    const type = it.type.toLowerCase();
                    if (!(type in obj) || !obj[type]) {
                        obj[type] = [];
                    }
                    obj[type] = [...obj[type], Object.freeze(it)];
                }

                return obj;
            }, {});

            state.data = events;
            state.filteredData = events;
            state.dataDict = Object.freeze(eventDict);
        } else {
            state.data = [];
        }

        this.commit('calendar/setFilteredData');
    },
    setFilters(state, payload) {
        state.filters = payload;
    },
    setFilteredData(state) {
        if (!state.filters || state.filters.length === 0 || state.filters.every(item => item.isChecked)) {
            state.filteredData = state.data;

            return;
        }

        // Collect all tags and types applied to selected filters
        const appliable = state.filters.filter(item => item.isChecked).reduce((acc, item) => {
            if (item?.type?.length && item.tag?.length) {
                return [...acc, item.type.toLowerCase(), item.tag.toLowerCase()];
            } else if (item?.type?.length) {
                return [...acc, item.type.toLowerCase()];
            } else if (item?.tag?.length) {
                return [...acc, item.tag.toLowerCase()];
            }

            return acc;
        }, []);
        // Get keys from tags / types
        const keys = Object.keys(state.dataDict).filter(key => appliable.includes(key));
        // Get data from dataDict by each key
        let filteredData = keys.reduce((r, k) => r.concat(state.dataDict[k]), []);

        // Remove duplicates
        filteredData = filteredData.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

        state.filteredData = filteredData;
    },
    setSelectedDate(state, payload) {
        state.selectedDate = payload;
    },
    setSelectedEvent(state, payload) {
        if (payload.length) {
            state.selectedEvent = state.data.find(item => item.id === payload);
        }
    },
};

export const actions = {
    async toggleListItem({ commit, state }, id) {
        const selected = [...state.filters];
        const found = selected.find(item => item.id === id);
        const filter = { ...found };
        const index = selected.findIndex(item => item.id === id);
        if (filter && index || (index === 0)) {
            filter.isChecked = !filter.isChecked;
            selected[index] = filter;

            await this.commit('calendar/setFilters', selected);
            await this.commit('calendar/setFilteredData');
        }
    },
};

export const getters = {
    getData: state => state.data,
    getFilters: state => state.filters,
    getEventData: state => state.filteredData.length
        ? state.filteredData.map(item => (Object.freeze({
            id: item.id,
            title: item.name,
            start: item.startTime,
            end: item.endTime,
            color: getEventColor(item, state.filters),
            eventBorderColor: getEventColor(item, state.filters),
            interactive: true,
        })))
        : [],
    // Reduce datepickerdata to 3 events per day
    getDatePickerData: state => Object.values(state.filteredData.reduce((acc, curr) => {
        const customDate = new Date(curr?.startTime);
        customDate.setSeconds(0, 0);
        customDate.setHours(0, 0);

        const currObject = (Object.freeze({
            dot: Object.freeze({
                style: Object.freeze({
                    backgroundColor: getEventColor(curr, state.filters),
                    height: '4px',
                }),
            }),
            dates: [curr.startTime, curr.endTime],
        }));

        if (!(customDate.valueOf() in acc)) {
            acc[customDate.valueOf()] = [currObject];
        } else if (customDate.valueOf() in acc && acc[customDate.valueOf()]?.length < 3) {
            acc[customDate.valueOf()] = acc[customDate.valueOf()].concat(currObject);
        }

        return acc;
    }, {})).flat(),
    getSelectedDate: state => state.selectedDate,
    getFilteredData: state => state.filteredData,
    getEventById(state) {
        return id => state.data.find(item => item.id === id);
    },
    getSelectedEvent: state => state.selectedEvent,
    eventDict(state) {
        return state.data.reduce((obj, it) => ({ ...obj, [it.id]: it }), {});
    },
    getDataDict: state => state.dataDict,
    selectedDateHasEvents: state => !!state.filteredData.find(item => (isSameDay(new Date(state.selectedDate), new Date(item.startTime)))),
};
