
    export default {
        name: 'HeroSlider',
        props: {
            slides: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                active: 0,
            };
        },
        methods: {
            changeSlide(index) {
                this.$refs.slider.glide.go(`=${index}`);
            },
            isFocusable(index) {
                return this.active === index;
            },
        },
    };
