import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=a8846c58&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=a8846c58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8846c58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextEditor: require('/build/src/components/elements/TextEditor.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default,Button: require('/build/src/components/elements/Button.vue').default,Footer: require('/build/src/components/layout/Footer.vue').default})
