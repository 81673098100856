import { render, staticRenderFns } from "./VMForm.vue?vue&type=template&id=73efd7b0&scoped=true"
import script from "./VMForm.vue?vue&type=script&lang=js"
export * from "./VMForm.vue?vue&type=script&lang=js"
import style0 from "./VMForm.vue?vue&type=style&index=0&id=73efd7b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73efd7b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VMInput: require('/build/src/components/elements/Form/VMInput.vue').default,Textarea: require('/build/src/components/elements/Form/Textarea.vue').default,VMBoolean: require('/build/src/components/elements/Form/VMBoolean.vue').default,VMCheckbox: require('/build/src/components/elements/Form/VMCheckbox.vue').default,VMRadio: require('/build/src/components/elements/Form/VMRadio.vue').default,VMSelect: require('/build/src/components/elements/Form/VMSelect.vue').default,VMImageSelect: require('/build/src/components/elements/Form/VMImageSelect.vue').default})
