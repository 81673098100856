
    import fieldMixin from '~/mixins/widget';

    export default {
        name: 'QuickLinkCard',
        mixins: [fieldMixin],
        props: {
            image: {
                src: {
                    type: String,
                    required: true,
                },
                alt: {
                    type: String,
                    required: false,
                    default: 'Image',
                },
                type: Object,
                required: false,
                default: () => ({}),
            },
            backgroundColor: {
                type: String,
                required: false,
                default: '',
            },
            textColor: {
                type: String,
                required: false,
                default: '',
            },
            link: {
                title: {
                    type: String,
                    required: true,
                },
                url: {
                    type: String,
                    required: true,
                },
                appearance: {
                    type: String,
                    required: false,
                },
                type: [Object, Boolean],
                required: false,
                default: false,
            },
        },
        data() {
            return {};
        },
        computed: {
            hasBackgroundImage() {
                return !!this.image && 'src' in this.image;
            },
            bgImage() {
                if (this.hasBackgroundImage) {
                    return { 'background-image': `url('${this.image.src}')` };
                }

                return '';
            },
        },
    };
