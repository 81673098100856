import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=620a5d96&scoped=true"
import script from "./Tag.vue?vue&type=script&lang=js"
export * from "./Tag.vue?vue&type=script&lang=js"
import style0 from "./Tag.vue?vue&type=style&index=0&id=620a5d96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620a5d96",
  null
  
)

export default component.exports