
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
    name: 'ProductItems',
    data() {
        return {
            calendarDebounce: null,
        };
    },
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            filter: 'product/getFilter',
            fromDate: 'product/getFromDate',
            toDate: 'product/getToDate',
            persons: 'product/getPersons',
            availabilityLoading: 'product/getAvailabilityLoading',
        }),
        selectedDates: {
            get() {
                return [this.fromDate, this.toDate];
            },
            set(values) {
                this.setDates({ start: DateTime.fromJSDate(values[0]), end: DateTime.fromJSDate(values[1]) });
                this.loadAvailability();
            },
        },
        getCalendarLabels() {
            switch (this.product.classification) {
                case 'Course':
                    return {
                        from: this.$i18n.t('product.classification.course.calendar.from'),
                        to: this.$i18n.t('product.classification.course.calendar.to'),
                    };
                break;

                default:
                case 'Accomodation':
                    return {
                        from: this.$i18n.t('product.classification.accommodation.calendar.from'),
                        to: this.$i18n.t('product.classification.accommodation.calendar.to'),
                    };
                break;
            }
        },
    },
    methods: {
        ...mapActions({
            loadAvailability: 'product/loadAvailability',
        }),
        ...mapMutations({
            setFilter: 'product/setFilter',
            setDates: 'product/setDates',
            setPersons: 'product/setPersons',
        }),
        dateChange(value) {
            if (this.calendarDebounce) {
                window.clearTimeout(this.calendarDebounce);
            };
            this.calendarDebounce = window.setTimeout(() => {
                this.setDates(value);
                this.setSelectingFilters(true);
            }, 50);
        },
        getClassificationByline() {
            switch (this.product.classification) {
                case 'Activity':
                    return false;
                break;

                case 'Course':
                    return this.$i18n.t('product.classification.course.byline');
                break;

                default:
                case 'Accomodation':
                    return this.$i18n.t('product.classification.accommodation.byline');
                break;
            };
        },
        updateFilter(payload) {
            this.setFilter(payload);

            if (payload.scope.includes('backend')) {
                this.loadAvailability();
            }
        },
    },
};
