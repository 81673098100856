const fieldResolver = {
    computed: {
        classNames() {
            const names = {
                [`text-${this.textAlign}`]: this.textAlign,
                [`background-${this.background}`]: this.background,
                [`text-${this.textColor}`]: this.textColor,
                [`overlay-${this.overlay}`]: this.overlay,
                [`background-${this.backgroundColor}`]: this.backgroundColor,
            };
            // Remove undefined properties from object
            Object.keys(names).forEach(k => names[k] === undefined && delete names[k]);

            return names;
        },
        backgroundImage() {
            if (!!this.image && this.backgroundColor === 'image') {
                return { 'background-image': `url('${this.image.src}')` };
            }

            return '';
        },
        textBackgroundColorStyle() {
            if (this.textBackgroundColor) {
                return { [`background-${this.textBackgroundColor}`]: this.textBackgroundColor };
            }

            return '';
        },
    },
};

export default fieldResolver;
