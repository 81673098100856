import { render, staticRenderFns } from "./HeroBanner.vue?vue&type=template&id=d644d47c&scoped=true"
import script from "./HeroBanner.vue?vue&type=script&lang=js"
export * from "./HeroBanner.vue?vue&type=script&lang=js"
import style0 from "./HeroBanner.vue?vue&type=style&index=0&id=d644d47c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d644d47c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroContent: require('/build/src/components/blocks/Hero/HeroContent.vue').default,HeroSlider: require('/build/src/components/blocks/Hero/HeroSlider.vue').default,LinkElement: require('/build/src/components/elements/LinkElement.vue').default})
