
export default {
    name: 'ContentfulImage',
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        src: {
            type: String,
            required: false,
            default: null,
        },
        fill: {
            type: String,
            required: false,
            validator: v => ['cover', 'contain'].indexOf(v) !== -1,
            default: 'contain',
        },
        scaling: {
            type: String,
            required: false,
            validator: v => ['width', 'height'].indexOf(v) !== -1,
            default: 'width',
        },
        alt: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        el() {
            return this.$el;
        },
        computedSource() {
            if (!this.isMounted) {
                return false;
            }
            let width, height, srcset;

            if (this.$refs.container) {
                width = Math.round(this.$refs.container.getBoundingClientRect().width);
                height = Math.round(this.$refs.container.getBoundingClientRect().height);
            }

            if (!width && !height) {
                width = 300;
                height = 300;
            }

            if (this.src && (width || height)) {
                switch (this.scaling) {
                    case 'height':
                        srcset = [1, 1.5, 2].map(size => `${this.src}?h=${Math.round(height*size)}&fm=webp ${size}x`).join(', ');

                        return {
                            src: `${this.src}?h=${Math.round(height)}&fm=webp`,
                            srcset,
                        };
                    break;

                    default:
                    case 'width':
                        srcset = [1, 1.5, 2].map(size => `${this.src}?w=${Math.round(width*size)}&fm=webp ${size}x`).join(', ');

                        return {
                            src: `${this.src}?w=${Math.round(width)}&fm=webp`,
                            srcset,
                        };
                    break;
                }
            }

            return false;
        },
        isServer() {
            return process.server;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.isMounted = new Date().getTime();
        });
    },
};
