
import bathroom from '~/static/icons/bathroom.svg';
import bed from '~/static/icons/bed.svg';
import bookmarks from '~/static/icons/bookmarks.svg';
import cart from '~/static/icons/cart.svg';
import checkmark from '~/static/icons/checkmark.svg';
import checkmarkCircle from '~/static/icons/checkmark-circle.svg';
import checkmarkCircleEmpty from '~/static/icons/checkmark-circle-empty.svg';
import checkin from '~/static/icons/checkin.svg';
import checklist from '~/static/icons/checklist.svg';
import chevron from '~/static/icons/chevron.svg';
import clock from '~/static/icons/clock.svg';
import close from '~/static/icons/close.svg';
import closeCircle from '~/static/icons/close-circle.svg';
import cottage from '~/static/icons/cottage.svg';
import cross from '~/static/icons/cross.svg';
import email from '~/static/icons/email.svg';
import error from '~/static/icons/error.svg';
import euro from '~/static/icons/euro.svg';
import equipments from '~/static/icons/equipments.svg';
import family from '~/static/icons/family.svg';
import file from '~/static/icons/file.svg';
import filter from '~/static/icons/filter-triangle.svg';
import flagFi from '~/static/icons/flag-fi.svg';
import flagSv from '~/static/icons/flag-sv.svg';
import flagEn from '~/static/icons/flag-en.svg';
import globe from '~/static/icons/globe.svg';
import heart from '~/static/icons/heart.svg';
import info from '~/static/icons/info.svg';
import kitchen from '~/static/icons/kitchen.svg';
import list from '~/static/icons/list.svg';
import listAdd from '~/static/icons/list-add.svg';
import logout from '~/static/icons/logout.svg';
import meals from '~/static/icons/meals.svg';
import menu from '~/static/icons/menu.svg';
import noCircle from '~/static/icons/no-circle.svg';
import phone from '~/static/icons/phone.svg';
import remove from '~/static/icons/remove.svg';
import screen from '~/static/icons/screen.svg';
import search from '~/static/icons/search.svg';
import pen from '~/static/icons/pen.svg';
import plusSearch from '~/static/icons/plusSearch.svg';
import sliderArrow from '~/static/icons/slider-arrow.svg';
import facebook from '~/static/icons/facebook.svg';
import tennis from '~/static/icons/tennis.svg';
import terms from '~/static/icons/terms.svg';
import time from '~/static/icons/time.svg';
import trash from '~/static/icons/trash.svg';
import twitter from '~/static/icons/twitter.svg';
import vmFooter from '~/static/icons/vmfooter.svg';
import user from '~/static/icons/user.svg';
import userBadge from '~/static/icons/user-badge.svg';
import instagram from '~/static/icons/instagram.svg';
import yesCircle from '~/static/icons/yes-circle.svg';
import youtube from '~/static/icons/youtube.svg';
import pin from '~/static/icons/pin.svg';
import star from '~/static/icons/star.svg';
import share from '~/static/icons/share.svg';
import gift from '~/static/icons/gift.svg';

export default {
    name: 'VMIcon',
    props: {
        size: {
            type: String,
            required: false,
            validator(value) {
                return ['xs', 's', 'm', 'l', 'xl', 'xxl', '2xl', '225xl', '25xl', 'xxxl'].indexOf(value) !== -1;
            },
            default: 'm',
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: false,
            validator(value) {
                return [
                    'bathroom',
                    'bed',
                    'bookmarks',
                    'calendar',
                    'cart',
                    'checkin',
                    'checklist',
                    'checkmark',
                    'checkmark-circle',
                    'checkmark-circle-empty',
                    'chevron',
                    'chevron-up',
                    'chevron-down',
                    'chevron-right',
                    'chevron-left',
                    'clock',
                    'close',
                    'close-circle',
                    'cottage',
                    'cross',
                    'email',
                    'error',
                    'euro',
                    'equipments',
                    'facebook',
                    'family',
                    'file',
                    'filter',
                    'flag-fi',
                    'flag-sv',
                    'flag-en',
                    'gift',
                    'globe',
                    'heart',
                    'info',
                    'instagram',
                    'kitchen',
                    'list',
                    'list-add',
                    'logout',
                    'meals',
                    'menu',
                    'no',
                    'no-circle',
                    'pin',
                    'phone',
                    'remove',
                    'screen',
                    'search',
                    'pen',
                    'plusSearch',
                    'share',
                    'slider-arrow',
                    'slider-arrow-left',
                    'slider-arrow-right',
                    'star',
                    'tennis',
                    'time',
                    'terms',
                    'trash',
                    'twitter',
                    'user',
                    'user-badge',
                    'vmfooter',
                    'yes',
                    'yes-circle',
                    'youtube',
                ].indexOf(value) !== -1;
            },
            default: null,
        },
    },
    methods: {
        getIcon(icon) {
            switch (icon) {
                case 'bathroom':
                    return bathroom;
                break;

                case 'bed':
                    return bed;
                break;

                case 'bookmarks':
                    return bookmarks;
                break;

                case 'cart':
                    return cart;
                break;

                case 'checkin':
                    return checkin;
                break;

                case 'checklist':
                    return checklist;
                break;

                case 'checkmark':
                    return checkmark;
                break;

                case 'checkmark-circle':
                    return checkmarkCircle;
                break;

                case 'checkmark-circle-empty':
                    return checkmarkCircleEmpty;
                break;

                case 'chevron':
                case 'chevron-up':
                case 'chevron-down':
                case 'chevron-right':
                case 'chevron-left':
                    return chevron;
                break;

                case 'clock':
                    return clock;
                break;

                case 'close':
                    return close;
                break;

                case 'close-circle':
                    return closeCircle;
                break;

                case 'cottage':
                    return cottage;
                break;

                case 'cross':
                    return cross;
                break;

                case 'email':
                    return email;
                break;

                case 'error':
                    return error;
                break;

                case 'euro':
                    return euro;
                break;

                case 'equipments':
                    return equipments;
                break;

                case 'facebook':
                    return facebook;
                break;

                case 'file':
                    return file;

                case 'filter':
                    return filter;
                break;

                case 'family':
                    return family;
                break;

                case 'flag-fi':
                    return flagFi;
                break;

                case 'flag-sv':
                    return flagSv;
                break;

                case 'flag-en':
                    return flagEn;
                break;

                case 'gift':
                    return gift;
                break;

                case 'globe':
                    return globe;
                    break;

                case 'heart':
                    return heart;
                    break;

                case 'info':
                    return info;
                break;

                case 'instagram':
                    return instagram;
                break;

                case 'kitchen':
                    return kitchen;
                break;

                case 'list':
                    return list;
                break;

                case 'list-add':
                    return listAdd;
                break;

                case 'logout':
                    return logout;
                break;

                case 'meals':
                    return meals;
                break;

                case 'menu':
                    return menu;
                break;

                case 'no':
                case 'no-circle':
                    return noCircle;
                break;

                case 'pin':
                    return pin;
                break;

                case 'phone':
                    return phone;
                break;

                case 'remove':
                    return remove;
                break;

                case 'screen':
                    return screen;
                break;

                case 'search':
                    return search;
                break;

                case 'pen':
                    return pen;
                break;

                case 'plusSearch':
                    return plusSearch;
                break;

                case 'share':
                    return share;
                break;

                case 'star':
                    return star;
                break;

                case 'slider-arrow-left':
                case 'slider-arrow-right':
                case 'slider-arrow':
                    return sliderArrow;
                break;

                case 'time':
                    return time;
                break;

                case 'trash':
                    return trash;
                break;

                case 'tennis':
                    return tennis;
                break;

                case 'calendar':
                case 'terms':
                    return terms;
                break;

                case 'twitter':
                    return twitter;
                break;

                case 'user':
                    return user;
                break;

                case 'user-badge':
                    return userBadge;
                break;

                case 'vmfooter':
                    return vmFooter;
                break;

                case 'yes':
                case 'yes-circle':
                    return yesCircle;
                break;

                case 'youtube':
                    return youtube;
                break;
            }
        },
        getClass() {
            return `size-${this.size} icon-${this.icon}`;
        },
    },
};
