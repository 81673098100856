export const LayoutCMSHeader = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Layout/CMSHeader/CMSHeader.vue' /* webpackChunkName: "components/layout-c-m-s-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutContentRouter = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Layout/ContentRouter/ContentRouter.vue' /* webpackChunkName: "components/layout-content-router" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Layout/Footer/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutLanguage = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Layout/Language/Language.vue' /* webpackChunkName: "components/layout-language" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavigation = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Layout/Navigation/Navigation.vue' /* webpackChunkName: "components/layout-navigation" */).then(c => wrapFunctional(c.default || c))
export const BlocksArticlePage = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Articles/ArticlePage.vue' /* webpackChunkName: "components/blocks-article-page" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardList = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CardList.vue' /* webpackChunkName: "components/blocks-card-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksDefaultContent = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/DefaultContent/DefaultContent.vue' /* webpackChunkName: "components/blocks-default-content" */).then(c => wrapFunctional(c.default || c))
export const BlocksEmergencyModal = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/EmergencyModal/EmergencyModal.vue' /* webpackChunkName: "components/blocks-emergency-modal" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrder = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/GroupOrder.vue' /* webpackChunkName: "components/blocks-group-order" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderSummary = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/GroupOrderSummary.vue' /* webpackChunkName: "components/blocks-group-order-summary" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakka = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Munkkimellakka.vue' /* webpackChunkName: "components/blocks-munkkimellakka" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaSummary = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/MunkkimellakkaSummary.vue' /* webpackChunkName: "components/blocks-munkkimellakka-summary" */).then(c => wrapFunctional(c.default || c))
export const BlocksStations = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/Stations.vue' /* webpackChunkName: "components/blocks-stations" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLAccordion = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLAccordion/CLAccordion.vue' /* webpackChunkName: "components/blocks-c-l-accordion" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLArticleList = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLArticles/CLArticleList.vue' /* webpackChunkName: "components/blocks-c-l-article-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLBanner = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLBanner/CLBanner.vue' /* webpackChunkName: "components/blocks-c-l-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLCards = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLCards/CLCards.vue' /* webpackChunkName: "components/blocks-c-l-cards" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLDefault = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLDefault/CLDefault.vue' /* webpackChunkName: "components/blocks-c-l-default" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLEmbedded = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLEmbedded/CLEmbedded.vue' /* webpackChunkName: "components/blocks-c-l-embedded" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLFoodMenu = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLFoodMenu/CLFoodMenu.vue' /* webpackChunkName: "components/blocks-c-l-food-menu" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLHero = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLHero/CLHero.vue' /* webpackChunkName: "components/blocks-c-l-hero" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLLunchMenu = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLLunchMenu/CLLunchMenu.vue' /* webpackChunkName: "components/blocks-c-l-lunch-menu" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLQuickLinks = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLQuickLinks/CLQuickLinks.vue' /* webpackChunkName: "components/blocks-c-l-quick-links" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLSlider = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLSlider/CLSlider.vue' /* webpackChunkName: "components/blocks-c-l-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksCLText = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/CardList/CLText/CLText.vue' /* webpackChunkName: "components/blocks-c-l-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsCancel = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsCancel.vue' /* webpackChunkName: "components/blocks-group-order-steps-cancel" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep1 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep1.vue' /* webpackChunkName: "components/blocks-group-order-steps-step1" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep2 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep2.vue' /* webpackChunkName: "components/blocks-group-order-steps-step2" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep3 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep3.vue' /* webpackChunkName: "components/blocks-group-order-steps-step3" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep4 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep4.vue' /* webpackChunkName: "components/blocks-group-order-steps-step4" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep5 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep5.vue' /* webpackChunkName: "components/blocks-group-order-steps-step5" */).then(c => wrapFunctional(c.default || c))
export const BlocksGroupOrderStepsStep6 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/GroupOrder/Steps/GroupOrderStepsStep6.vue' /* webpackChunkName: "components/blocks-group-order-steps-step6" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsCancel = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsCancel.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-cancel" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsStep1 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsStep1.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-step1" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsStep2 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsStep2.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-step2" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsStep3 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsStep3.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-step3" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsStep4 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsStep4.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-step4" */).then(c => wrapFunctional(c.default || c))
export const BlocksMunkkimellakkaStepsStep5 = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Munkkimellakka/Steps/MunkkimellakkaStepsStep5.vue' /* webpackChunkName: "components/blocks-munkkimellakka-steps-step5" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctions = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctions.vue' /* webpackChunkName: "components/blocks-stations-functions" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctionsAutopesu = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctionsAutopesu.vue' /* webpackChunkName: "components/blocks-stations-functions-autopesu" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctionsFood = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctionsFood.vue' /* webpackChunkName: "components/blocks-stations-functions-food" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctionsFoodMenu = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctionsFoodMenu.vue' /* webpackChunkName: "components/blocks-stations-functions-food-menu" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctionsLunchList = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctionsLunchList.vue' /* webpackChunkName: "components/blocks-stations-functions-lunch-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsFunctionsServices = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsFunctions/StationsFunctionsServices.vue' /* webpackChunkName: "components/blocks-stations-functions-services" */).then(c => wrapFunctional(c.default || c))
export const BlocksOpeningHours = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsHeader/OpeningHours.vue' /* webpackChunkName: "components/blocks-opening-hours" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsHeader = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsHeader/StationsHeader.vue' /* webpackChunkName: "components/blocks-stations-header" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsHeaderInfo = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsHeader/StationsHeaderInfo.vue' /* webpackChunkName: "components/blocks-stations-header-info" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsList = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsList/StationsList.vue' /* webpackChunkName: "components/blocks-stations-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsLunch = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsLunch/StationsLunch.vue' /* webpackChunkName: "components/blocks-stations-lunch" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsPreorder = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsPreorder/StationsPreorder.vue' /* webpackChunkName: "components/blocks-stations-preorder" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsPreorderSelection = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsPreorder/StationsPreorderSelection.vue' /* webpackChunkName: "components/blocks-stations-preorder-selection" */).then(c => wrapFunctional(c.default || c))
export const BlocksStationsPreorderStationSelection = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Blocks/Stations/StationsPreorder/StationsPreorderStationSelection.vue' /* webpackChunkName: "components/blocks-stations-preorder-station-selection" */).then(c => wrapFunctional(c.default || c))
export const DSBreadcrumbs = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/d-s-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const DSCFText = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/CFText/CFText.vue' /* webpackChunkName: "components/d-s-c-f-text" */).then(c => wrapFunctional(c.default || c))
export const DSCombobox = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Combobox/Combobox.vue' /* webpackChunkName: "components/d-s-combobox" */).then(c => wrapFunctional(c.default || c))
export const DSContentfulImage = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/ContentfulImage/ContentfulImage.vue' /* webpackChunkName: "components/d-s-contentful-image" */).then(c => wrapFunctional(c.default || c))
export const DSConsent = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/CookieConsent/Consent.vue' /* webpackChunkName: "components/d-s-consent" */).then(c => wrapFunctional(c.default || c))
export const DSConsentItem = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/CookieConsent/ConsentItem.vue' /* webpackChunkName: "components/d-s-consent-item" */).then(c => wrapFunctional(c.default || c))
export const DSErrorPage = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/ErrorPage/ErrorPage.vue' /* webpackChunkName: "components/d-s-error-page" */).then(c => wrapFunctional(c.default || c))
export const DSFrontifyAsset = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/FrontifyAsset/FrontifyAsset.vue' /* webpackChunkName: "components/d-s-frontify-asset" */).then(c => wrapFunctional(c.default || c))
export const DSFrontifyImage = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/FrontifyAsset/FrontifyImage.vue' /* webpackChunkName: "components/d-s-frontify-image" */).then(c => wrapFunctional(c.default || c))
export const DSFrontifyVector = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/FrontifyAsset/FrontifyVector.vue' /* webpackChunkName: "components/d-s-frontify-vector" */).then(c => wrapFunctional(c.default || c))
export const DSFrontifyVideo = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/FrontifyAsset/FrontifyVideo.vue' /* webpackChunkName: "components/d-s-frontify-video" */).then(c => wrapFunctional(c.default || c))
export const DSHead = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Head/Head.vue' /* webpackChunkName: "components/d-s-head" */).then(c => wrapFunctional(c.default || c))
export const DSMetaData = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Head/MetaData.vue' /* webpackChunkName: "components/d-s-meta-data" */).then(c => wrapFunctional(c.default || c))
export const DSOpenGraph = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Head/OpenGraph.vue' /* webpackChunkName: "components/d-s-open-graph" */).then(c => wrapFunctional(c.default || c))
export const DSRobots = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Head/Robots.vue' /* webpackChunkName: "components/d-s-robots" */).then(c => wrapFunctional(c.default || c))
export const DSHeightTransition = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/HeightTransition/HeightTransition.vue' /* webpackChunkName: "components/d-s-height-transition" */).then(c => wrapFunctional(c.default || c))
export const DSIcon = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Icon/Icon.vue' /* webpackChunkName: "components/d-s-icon" */).then(c => wrapFunctional(c.default || c))
export const DSInput = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Input/Input.vue' /* webpackChunkName: "components/d-s-input" */).then(c => wrapFunctional(c.default || c))
export const DSLink = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Link/Link.vue' /* webpackChunkName: "components/d-s-link" */).then(c => wrapFunctional(c.default || c))
export const DSNavList = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/NavList/NavList.vue' /* webpackChunkName: "components/d-s-nav-list" */).then(c => wrapFunctional(c.default || c))
export const DSNotification = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Notification/Notification.vue' /* webpackChunkName: "components/d-s-notification" */).then(c => wrapFunctional(c.default || c))
export const DSNotifications = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Notifications/Notifications.vue' /* webpackChunkName: "components/d-s-notifications" */).then(c => wrapFunctional(c.default || c))
export const DSNumber = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Number/Number.vue' /* webpackChunkName: "components/d-s-number" */).then(c => wrapFunctional(c.default || c))
export const DSPreloader = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Preloader/Preloader.vue' /* webpackChunkName: "components/d-s-preloader" */).then(c => wrapFunctional(c.default || c))
export const DSProgressBar = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/ProgressBar/ProgressBar.vue' /* webpackChunkName: "components/d-s-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const DSSimplebar = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Simplebar/Simplebar.vue' /* webpackChunkName: "components/d-s-simplebar" */).then(c => wrapFunctional(c.default || c))
export const DSSlider = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Slider/Slider.vue' /* webpackChunkName: "components/d-s-slider" */).then(c => wrapFunctional(c.default || c))
export const DSSomeShare = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/SomeShare/SomeShare.vue' /* webpackChunkName: "components/d-s-some-share" */).then(c => wrapFunctional(c.default || c))
export const DSSwitches = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Switches/Switches.vue' /* webpackChunkName: "components/d-s-switches" */).then(c => wrapFunctional(c.default || c))
export const DSTabBar = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/TabBar/TabBar.vue' /* webpackChunkName: "components/d-s-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const DSTooltip = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/Tooltip/Tooltip.vue' /* webpackChunkName: "components/d-s-tooltip" */).then(c => wrapFunctional(c.default || c))
export const DSUsefulLinks = () => import('../../node_modules/@lamiaoy/st1-ds-components/src/components/Components/UsefulLinks/UsefulLinks.vue' /* webpackChunkName: "components/d-s-useful-links" */).then(c => wrapFunctional(c.default || c))
export const ContentRouter = () => import('../../src/components/ContentRouter.vue' /* webpackChunkName: "components/content-router" */).then(c => wrapFunctional(c.default || c))
export const EducationList = () => import('../../src/components/EducationList.vue' /* webpackChunkName: "components/education-list" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../src/components/blocks/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AlertBanner = () => import('../../src/components/blocks/AlertBanner.vue' /* webpackChunkName: "components/alert-banner" */).then(c => wrapFunctional(c.default || c))
export const ArticleList = () => import('../../src/components/blocks/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => wrapFunctional(c.default || c))
export const ArticlePage = () => import('../../src/components/blocks/ArticlePage.vue' /* webpackChunkName: "components/article-page" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../src/components/blocks/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilters = () => import('../../src/components/blocks/CategoryFilters.vue' /* webpackChunkName: "components/category-filters" */).then(c => wrapFunctional(c.default || c))
export const ContactCards = () => import('../../src/components/blocks/ContactCards.vue' /* webpackChunkName: "components/contact-cards" */).then(c => wrapFunctional(c.default || c))
export const ContentCards = () => import('../../src/components/blocks/ContentCards.vue' /* webpackChunkName: "components/content-cards" */).then(c => wrapFunctional(c.default || c))
export const ContentSlider = () => import('../../src/components/blocks/ContentSlider.vue' /* webpackChunkName: "components/content-slider" */).then(c => wrapFunctional(c.default || c))
export const ContentfulModal = () => import('../../src/components/blocks/ContentfulModal.vue' /* webpackChunkName: "components/contentful-modal" */).then(c => wrapFunctional(c.default || c))
export const CookieConsent = () => import('../../src/components/blocks/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const DefaultContent = () => import('../../src/components/blocks/DefaultContent.vue' /* webpackChunkName: "components/default-content" */).then(c => wrapFunctional(c.default || c))
export const EducationPage = () => import('../../src/components/blocks/EducationPage.vue' /* webpackChunkName: "components/education-page" */).then(c => wrapFunctional(c.default || c))
export const EducationPaymentPage = () => import('../../src/components/blocks/EducationPaymentPage.vue' /* webpackChunkName: "components/education-payment-page" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../src/components/blocks/Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const FlexViewMap = () => import('../../src/components/blocks/FlexViewMap.vue' /* webpackChunkName: "components/flex-view-map" */).then(c => wrapFunctional(c.default || c))
export const Forms = () => import('../../src/components/blocks/Forms.vue' /* webpackChunkName: "components/forms" */).then(c => wrapFunctional(c.default || c))
export const HalfContentGroup = () => import('../../src/components/blocks/HalfContentGroup.vue' /* webpackChunkName: "components/half-content-group" */).then(c => wrapFunctional(c.default || c))
export const ImageHalfTextHalf = () => import('../../src/components/blocks/ImageHalfTextHalf.vue' /* webpackChunkName: "components/image-half-text-half" */).then(c => wrapFunctional(c.default || c))
export const InfoBoxes = () => import('../../src/components/blocks/InfoBoxes.vue' /* webpackChunkName: "components/info-boxes" */).then(c => wrapFunctional(c.default || c))
export const Media = () => import('../../src/components/blocks/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c))
export const ProductData = () => import('../../src/components/blocks/ProductData.vue' /* webpackChunkName: "components/product-data" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../src/components/blocks/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductGiftcardOrder = () => import('../../src/components/blocks/ProductGiftcardOrder.vue' /* webpackChunkName: "components/product-giftcard-order" */).then(c => wrapFunctional(c.default || c))
export const ProductHeader = () => import('../../src/components/blocks/ProductHeader.vue' /* webpackChunkName: "components/product-header" */).then(c => wrapFunctional(c.default || c))
export const ProductIllustration = () => import('../../src/components/blocks/ProductIllustration.vue' /* webpackChunkName: "components/product-illustration" */).then(c => wrapFunctional(c.default || c))
export const ProductModal = () => import('../../src/components/blocks/ProductModal.vue' /* webpackChunkName: "components/product-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../src/components/blocks/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const ProductSearch = () => import('../../src/components/blocks/ProductSearch.vue' /* webpackChunkName: "components/product-search" */).then(c => wrapFunctional(c.default || c))
export const QuickLinks = () => import('../../src/components/blocks/QuickLinks.vue' /* webpackChunkName: "components/quick-links" */).then(c => wrapFunctional(c.default || c))
export const WysiwygBlock = () => import('../../src/components/blocks/WysiwygBlock.vue' /* webpackChunkName: "components/wysiwyg-block" */).then(c => wrapFunctional(c.default || c))
export const ActivityIndicator = () => import('../../src/components/elements/ActivityIndicator.vue' /* webpackChunkName: "components/activity-indicator" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../src/components/elements/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const BuyerInfo = () => import('../../src/components/elements/BuyerInfo.vue' /* webpackChunkName: "components/buyer-info" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../src/components/elements/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const ContentfulImage = () => import('../../src/components/elements/ContentfulImage.vue' /* webpackChunkName: "components/contentful-image" */).then(c => wrapFunctional(c.default || c))
export const DiscountCode = () => import('../../src/components/elements/DiscountCode.vue' /* webpackChunkName: "components/discount-code" */).then(c => wrapFunctional(c.default || c))
export const DiscountCodePopup = () => import('../../src/components/elements/DiscountCodePopup.vue' /* webpackChunkName: "components/discount-code-popup" */).then(c => wrapFunctional(c.default || c))
export const FrontifyMedia = () => import('../../src/components/elements/FrontifyMedia.vue' /* webpackChunkName: "components/frontify-media" */).then(c => wrapFunctional(c.default || c))
export const GiftCardSelect = () => import('../../src/components/elements/GiftCardSelect.vue' /* webpackChunkName: "components/gift-card-select" */).then(c => wrapFunctional(c.default || c))
export const LightBox = () => import('../../src/components/elements/LightBox.vue' /* webpackChunkName: "components/light-box" */).then(c => wrapFunctional(c.default || c))
export const LinkElement = () => import('../../src/components/elements/LinkElement.vue' /* webpackChunkName: "components/link-element" */).then(c => wrapFunctional(c.default || c))
export const ModalGiftcard = () => import('../../src/components/elements/ModalGiftcard.vue' /* webpackChunkName: "components/modal-giftcard" */).then(c => wrapFunctional(c.default || c))
export const ModalLogin = () => import('../../src/components/elements/ModalLogin.vue' /* webpackChunkName: "components/modal-login" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/elements/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PriceSlider = () => import('../../src/components/elements/PriceSlider.vue' /* webpackChunkName: "components/price-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductImageSlider = () => import('../../src/components/elements/ProductImageSlider.vue' /* webpackChunkName: "components/product-image-slider" */).then(c => wrapFunctional(c.default || c))
export const SearchHeader = () => import('../../src/components/elements/SearchHeader.vue' /* webpackChunkName: "components/search-header" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../src/components/elements/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../src/components/elements/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../src/components/elements/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const TextEditor = () => import('../../src/components/elements/TextEditor.vue' /* webpackChunkName: "components/text-editor" */).then(c => wrapFunctional(c.default || c))
export const Upsell = () => import('../../src/components/elements/Upsell.vue' /* webpackChunkName: "components/upsell" */).then(c => wrapFunctional(c.default || c))
export const UserDetails = () => import('../../src/components/elements/UserDetails.vue' /* webpackChunkName: "components/user-details" */).then(c => wrapFunctional(c.default || c))
export const UserNewsLetter = () => import('../../src/components/elements/UserNewsLetter.vue' /* webpackChunkName: "components/user-news-letter" */).then(c => wrapFunctional(c.default || c))
export const VMAccordion = () => import('../../src/components/elements/VMAccordion.vue' /* webpackChunkName: "components/v-m-accordion" */).then(c => wrapFunctional(c.default || c))
export const VMAccordionItem = () => import('../../src/components/elements/VMAccordionItem.vue' /* webpackChunkName: "components/v-m-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const VMForm = () => import('../../src/components/elements/VMForm.vue' /* webpackChunkName: "components/v-m-form" */).then(c => wrapFunctional(c.default || c))
export const VMIcon = () => import('../../src/components/elements/VMIcon.vue' /* webpackChunkName: "components/v-m-icon" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../src/components/layout/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../src/components/layout/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNav = () => import('../../src/components/layout/CheckoutNav.vue' /* webpackChunkName: "components/checkout-nav" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/components/layout/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../src/components/layout/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const MegaMenu = () => import('../../src/components/layout/MegaMenu.vue' /* webpackChunkName: "components/mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../src/components/layout/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../src/components/layout/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../src/components/layout/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../src/components/layout/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const DesktopCalendar = () => import('../../src/components/blocks/EventCalendar/DesktopCalendar.vue' /* webpackChunkName: "components/desktop-calendar" */).then(c => wrapFunctional(c.default || c))
export const EventCalendar = () => import('../../src/components/blocks/EventCalendar/EventCalendar.vue' /* webpackChunkName: "components/event-calendar" */).then(c => wrapFunctional(c.default || c))
export const EventFilters = () => import('../../src/components/blocks/EventCalendar/EventFilters.vue' /* webpackChunkName: "components/event-filters" */).then(c => wrapFunctional(c.default || c))
export const EventModal = () => import('../../src/components/blocks/EventCalendar/EventModal.vue' /* webpackChunkName: "components/event-modal" */).then(c => wrapFunctional(c.default || c))
export const MobileCalendar = () => import('../../src/components/blocks/EventCalendar/MobileCalendar.vue' /* webpackChunkName: "components/mobile-calendar" */).then(c => wrapFunctional(c.default || c))
export const WeeklyCalendar = () => import('../../src/components/blocks/EventCalendar/WeeklyCalendar.vue' /* webpackChunkName: "components/weekly-calendar" */).then(c => wrapFunctional(c.default || c))
export const AccessibilityFeedback = () => import('../../src/components/blocks/Forms/AccessibilityFeedback.vue' /* webpackChunkName: "components/accessibility-feedback" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../src/components/blocks/Forms/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const NewsletterForm = () => import('../../src/components/blocks/Forms/NewsletterForm.vue' /* webpackChunkName: "components/newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const RegisterForm = () => import('../../src/components/blocks/Forms/RegisterForm.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordForm = () => import('../../src/components/blocks/Forms/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../src/components/blocks/Hero/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HeroContent = () => import('../../src/components/blocks/Hero/HeroContent.vue' /* webpackChunkName: "components/hero-content" */).then(c => wrapFunctional(c.default || c))
export const HeroSlider = () => import('../../src/components/blocks/Hero/HeroSlider.vue' /* webpackChunkName: "components/hero-slider" */).then(c => wrapFunctional(c.default || c))
export const EducationDetails = () => import('../../src/components/blocks/ProductDetails/EducationDetails.vue' /* webpackChunkName: "components/education-details" */).then(c => wrapFunctional(c.default || c))
export const EducationExtra = () => import('../../src/components/blocks/ProductDetails/EducationExtra.vue' /* webpackChunkName: "components/education-extra" */).then(c => wrapFunctional(c.default || c))
export const GiftcardDetails = () => import('../../src/components/blocks/ProductDetails/GiftcardDetails.vue' /* webpackChunkName: "components/giftcard-details" */).then(c => wrapFunctional(c.default || c))
export const CategoryItems = () => import('../../src/components/blocks/ProductPage/CategoryItems.vue' /* webpackChunkName: "components/category-items" */).then(c => wrapFunctional(c.default || c))
export const ProductItems = () => import('../../src/components/blocks/ProductPage/ProductItems.vue' /* webpackChunkName: "components/product-items" */).then(c => wrapFunctional(c.default || c))
export const AccommodationProductCard = () => import('../../src/components/elements/Cards/AccommodationProductCard.vue' /* webpackChunkName: "components/accommodation-product-card" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../src/components/elements/Cards/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContentCard = () => import('../../src/components/elements/Cards/ContentCard.vue' /* webpackChunkName: "components/content-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../src/components/elements/Cards/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardContent = () => import('../../src/components/elements/Cards/ProductCardContent.vue' /* webpackChunkName: "components/product-card-content" */).then(c => wrapFunctional(c.default || c))
export const QuickLinkCard = () => import('../../src/components/elements/Cards/QuickLinkCard.vue' /* webpackChunkName: "components/quick-link-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRegistration = () => import('../../src/components/elements/Checkout/CheckoutRegistration.vue' /* webpackChunkName: "components/checkout-registration" */).then(c => wrapFunctional(c.default || c))
export const AmountFilter = () => import('../../src/components/elements/Form/AmountFilter.vue' /* webpackChunkName: "components/amount-filter" */).then(c => wrapFunctional(c.default || c))
export const CheckboxFilter = () => import('../../src/components/elements/Form/CheckboxFilter.vue' /* webpackChunkName: "components/checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const DateRange = () => import('../../src/components/elements/Form/DateRange.vue' /* webpackChunkName: "components/date-range" */).then(c => wrapFunctional(c.default || c))
export const DateSelect = () => import('../../src/components/elements/Form/DateSelect.vue' /* webpackChunkName: "components/date-select" */).then(c => wrapFunctional(c.default || c))
export const EquipmentSelect = () => import('../../src/components/elements/Form/EquipmentSelect.vue' /* webpackChunkName: "components/equipment-select" */).then(c => wrapFunctional(c.default || c))
export const PersonSelect = () => import('../../src/components/elements/Form/PersonSelect.vue' /* webpackChunkName: "components/person-select" */).then(c => wrapFunctional(c.default || c))
export const RadioFilter = () => import('../../src/components/elements/Form/RadioFilter.vue' /* webpackChunkName: "components/radio-filter" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../src/components/elements/Form/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const VMBoolean = () => import('../../src/components/elements/Form/VMBoolean.vue' /* webpackChunkName: "components/v-m-boolean" */).then(c => wrapFunctional(c.default || c))
export const VMCheckbox = () => import('../../src/components/elements/Form/VMCheckbox.vue' /* webpackChunkName: "components/v-m-checkbox" */).then(c => wrapFunctional(c.default || c))
export const VMFilterSelect = () => import('../../src/components/elements/Form/VMFilterSelect.vue' /* webpackChunkName: "components/v-m-filter-select" */).then(c => wrapFunctional(c.default || c))
export const VMImageSelect = () => import('../../src/components/elements/Form/VMImageSelect.vue' /* webpackChunkName: "components/v-m-image-select" */).then(c => wrapFunctional(c.default || c))
export const VMInput = () => import('../../src/components/elements/Form/VMInput.vue' /* webpackChunkName: "components/v-m-input" */).then(c => wrapFunctional(c.default || c))
export const VMNumber = () => import('../../src/components/elements/Form/VMNumber.vue' /* webpackChunkName: "components/v-m-number" */).then(c => wrapFunctional(c.default || c))
export const VMNumberSelect = () => import('../../src/components/elements/Form/VMNumberSelect.vue' /* webpackChunkName: "components/v-m-number-select" */).then(c => wrapFunctional(c.default || c))
export const VMRadio = () => import('../../src/components/elements/Form/VMRadio.vue' /* webpackChunkName: "components/v-m-radio" */).then(c => wrapFunctional(c.default || c))
export const VMSelect = () => import('../../src/components/elements/Form/VMSelect.vue' /* webpackChunkName: "components/v-m-select" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerBlockReservation = () => import('../../src/components/elements/HomeOwner/HomeOwnerBlockReservation.vue' /* webpackChunkName: "components/home-owner-block-reservation" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerCalendar = () => import('../../src/components/elements/HomeOwner/HomeOwnerCalendar.vue' /* webpackChunkName: "components/home-owner-calendar" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerDeleteBooking = () => import('../../src/components/elements/HomeOwner/HomeOwnerDeleteBooking.vue' /* webpackChunkName: "components/home-owner-delete-booking" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerFreeBlock = () => import('../../src/components/elements/HomeOwner/HomeOwnerFreeBlock.vue' /* webpackChunkName: "components/home-owner-free-block" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerItem = () => import('../../src/components/elements/HomeOwner/HomeOwnerItem.vue' /* webpackChunkName: "components/home-owner-item" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerLoading = () => import('../../src/components/elements/HomeOwner/HomeOwnerLoading.vue' /* webpackChunkName: "components/home-owner-loading" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerOwnReservation = () => import('../../src/components/elements/HomeOwner/HomeOwnerOwnReservation.vue' /* webpackChunkName: "components/home-owner-own-reservation" */).then(c => wrapFunctional(c.default || c))
export const HomeOwnerTable = () => import('../../src/components/elements/HomeOwner/HomeOwnerTable.vue' /* webpackChunkName: "components/home-owner-table" */).then(c => wrapFunctional(c.default || c))
export const CartDetails = () => import('../../src/components/layout/Cart/CartDetails.vue' /* webpackChunkName: "components/cart-details" */).then(c => wrapFunctional(c.default || c))
export const CategoryCards = () => import('../../src/components/blocks/ProductPage/ProductItems/CategoryCards.vue' /* webpackChunkName: "components/category-cards" */).then(c => wrapFunctional(c.default || c))
export const CategoryTable = () => import('../../src/components/blocks/ProductPage/ProductItems/CategoryTable.vue' /* webpackChunkName: "components/category-table" */).then(c => wrapFunctional(c.default || c))
export const FilterSelector = () => import('../../src/components/blocks/ProductPage/ProductItems/FilterSelector.vue' /* webpackChunkName: "components/filter-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductTable = () => import('../../src/components/blocks/ProductPage/ProductItems/ProductTable.vue' /* webpackChunkName: "components/product-table" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../src/components/blocks/ProductPage/ProductItems/CategoryTable/CategoryList.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryProduct = () => import('../../src/components/blocks/ProductPage/ProductItems/CategoryTable/CategoryProduct.vue' /* webpackChunkName: "components/category-product" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
