
import { mapGetters } from 'vuex';
import clickOutside from '@lamiaoy/st1-ds-components/src/directives/click-outside';

export default {
    name: 'PersonSelect',
    directives: {
        clickOutside,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {
            },
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        focusable: {
            type: Boolean,
            required: false,
            default: true,
        },
        backgroundWhite: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            timeout: null,
            open: false,
            internalValues: {
                numberOfAdults: 0,
                numberOfChildrenOlder: 0,
                numberOfChildrenYounger: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            persons: 'product/getPersons',
        }),
        getPersonTotal() {
            const total = this.persons.numberOfAdults + this.persons?.numberOfChildrenOlder + this.persons?.numberOfChildrenYounger;

            return total;
        },
        filters() {
            return [{
                id: 'numberOfAdults',
                title: this.$t('product.filters.adults'),
                byline: this.$t('product.filters.adultsByline'),
                value: this.internalValues.numberOfAdults,
            }, {
                id: 'numberOfChildrenOlder',
                title: this.$t('product.filters.children'),
                byline: this.$t('product.filters.childrenByline'),
                value: this.internalValues.numberOfChildrenOlder,
            }, {
                id: 'numberOfChildrenYounger',
                title: this.$t('product.filters.smallChildren'),
                byline: this.$t('product.filters.smallChildrenByline'),
                value: this.internalValues.numberOfChildrenYounger,
            },
                /*
                {
                    id: 'pets',
                    title: this.$t('product.filters.pets'),
                    byline: this.$t('product.filters.petsByline'),
                    value: this.value.pets,
                }
                */
            ];
        },
    },
    mounted() {
        this.internalValues = {
            ...Object(this.value),
        };
    },
    methods: {
        doOpen() {
            if (this.open === false) {
                this.internalValues = {
                    ...Object(this.value),
                };
            }
            this.open = !this.open;
        },
        valueChange(id, value) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            this.internalValues[id] = value;
            this.timeout = window.setTimeout(this.handleClickOutside, 1500);
        },
        handleClickOutside() {
            if (this.open) {
                this.$emit('update:value', this.internalValues);
                this.open = false;
            }
            ;
        },
        getPersonValue() {
            switch (this.getPersonTotal) {
                case 0:
                    return this.$t('product.filters.addPersons');
                    break;

                case 1:
                    return `${this.getPersonTotal} ${this.$t('product.filters.person')}`;
                    break;

                default:
                case 2:
                    return `${this.getPersonTotal} ${this.$t('product.filters.multiPersons')}`;
                    break;
            }
        },
    },
};
