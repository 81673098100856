import StoreForm from '~/util/storeFormExtend';
const storeForm = new StoreForm();

const defaultState = () => ({
    apartmentId: '',
    blockId: '',
    type: '',
    fields: {
        addblock: false,
    },
    loading: false,
    step: 1,
});

export default {
    state: () => defaultState(),
    mutations: {
        setPayload (state, payload) {
            Object.keys(payload).forEach((key, value) => {
                if (typeof(state[key]) === typeof(payload[key])) {
                    state[key] = payload[key];
                } else {
                    throw new Error('Could not set data on homeowner/delete');
                }
            });
        },
        reset (state) {
            Object.assign(state, defaultState());
        },
        ...storeForm.mutations,
    },
    actions: {
        async submit (ctx) {
            this.commit('homeowner/delete/loading', true);
            try {
                let endpoint;
                const { type, apartmentId, blockId } = ctx.state;
                const { addblock } = ctx.state.fields;
                let params = {};

                if (ctx.rootGetters['homeowner/apartment/getRole'](apartmentId) !== 'user') {
                    params = {
                        addblock,
                    };
                } else {
                    params = {
                        addblock: true,
                    };
                }

                if (type === 'bookable') {
                    endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/blocks/${blockId}`;
                } else if (type === 'booked') {
                    endpoint = `/proxy/vm-homeowner/v1/apartments/${apartmentId}/reservations/${blockId}`;
                }

                const response = await this.$axios.request({
                    method: 'delete',
                    url: endpoint,
                    params,
                });

                await this.dispatch('homeowner/apartment/load', apartmentId);
                this.commit('homeowner/delete/setPayload', {
                    step: 2,
                });
            } catch (e) {
                this.dispatch('messages/setMessage', {
                    type: 'error',
                    message: this.app.i18n.t('homeowner.message.deleteFailed'),
                });
                this.commit('homeowner/delete/loading', false);
            }
        },
    },
    getters: {
        getStep: state => state.step,
        getType: state => state.type,
        getBlockId: state => state.blockId,
        getApartmentId: state => state.apartmentId,
        ...storeForm.getters,
    },
};
