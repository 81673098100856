
import { mapGetters } from 'vuex';

export default {
    name: 'EducationDetails',
    computed: {
        ...mapGetters({
            product: 'product/getProduct',
        }),
        degreeTitles() {
            return Object.values(this.product.degree_title).join(', ');
        },
    },
};
